import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyInformation } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';


@Component({
    selector: 'app-companytable',
    templateUrl: './companytable.component.html',
    styleUrls: ['./companytable.component.scss']
})
export class CompanyTableComponent implements OnInit, OnDestroy {
    companydata: any;
    displayedColumns: string[] = ['JobCompanyName', 'IndustryType', 'CityName', "Edit", "View", "Delete"];
    page: number = 1;
    pagesize: number = 10;
    dataFilter: number[] = [];
    datapageInfo: any;
    totalItems: number = 0;

    datasource: MatTableDataSource<CompanyInformation[]>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>
    ) {
        this.dataFilter = [];
    }

    ngOnInit(): void {
        this.getCompany()
    }

    getCompany() {

        this.dataFilter.push(this.page - 1);
        this.dataFilter.push(this.pagesize);
        this.service.Post('Company/GetCompanyTableData', this.dataFilter).subscribe(data => {

            this.datasource = new MatTableDataSource(data[0]);
            this.datapageInfo = data[1];
            this.totalItems = this.datapageInfo[0].length;
            this.dataFilter = [];
        },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }

    pageChanged(value: any) {
        this.page = value;
        this.getCompany();
    }


    applyFilter(event: KeyboardEvent) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            this.datasource.filter  = ''
        }
        else
        {
            const filterValue = (event.target as HTMLInputElement).value;
            this.datasource.filter = filterValue.trim().toLowerCase();
        }
        
    }

    Edit(row: Number) {
        this.companydata = row;
        // this.global.setjobTypeId(this.jobData.Id);
        this.router.navigate(['editcompany', this.companydata.Id]);
    }

    View(row: Number) {
        this.companydata = row;
        // this.global.setjobTypeId(this.jobData.Id);
        this.router.navigate(['viewcompany', this.companydata.Id]);
    }

    Delete(row: any) {
        this.companydata = row.Id;
       
        this.service.Put('Company/CompanyDataDelete?Id=' + this.companydata,null).subscribe(
            (res) => {
                this.toastr.success(res);
                this.  getCompany() ;
            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
        
    }


    ngOnDestroy(): void {

    }

}
