import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { Jobpost, UplodJob } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalService } from 'src/app/global.service';
import { AuthService } from 'src/app/auth.service';
import { ExcelServicesService } from 'src/app/excel-services.service';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-clienttable',
  templateUrl: './clienttable.component.html',
  styleUrls: ['./clienttable.component.scss']
})

export class ClientComponent implements OnInit, OnDestroy {
  @ViewChild('myInput') myInputVariable: any;
  // "CompanyName",
  displayedColumns: string[] = ["ClientName",  "CityName", "FirstName", "MobileNo", "Edit", "View", "Delete"];
  clientData: any;
  dataSource: MatTableDataSource<Jobpost[]>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  data: any;
  ClientForm: FormGroup;
  totalpages: number;
  datapageInfo: any;
  totalItems: number = 0;
  // Search: string = '';
  pagesize: number = 10;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private service: SharedService<any>,
    public global: GlobalService,
    private auth: AuthService,
    private excelService: ExcelServicesService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.ClientForm = this.formBuilder.group({
      PageSearch: [null, Validators.nullValidator],

    });
    this.ClientForm.controls["PageSearch"].setValue(this.global.GoToPageForClient);

    this.getClient();
  }

  getClient() {
     this.service.Get('Client/GetClientTableData?pageno=' + this.global.GoToPageForClient + '&pagesize=' + this.pagesize + '&search=' + this.global.ClientSearch, null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data[0]);
      this.dataSource.paginator = this.paginator;
      this.datapageInfo = data[1];
      this.totalItems = this.datapageInfo[0].length;
      if (this.totalItems > 0) {
        this.totalpages = Math.ceil(this.totalItems / 10);
      }
      else {
        this.totalpages = 0;
      }

    },
      (error) => {
        this.toastr.error(error.error);
      });
  }

  GetId(row: Number) {
    this.clientData = row;
  }
  Edit(row: Number) {
    this.clientData = row;
    // this.global.setjobTypeId(this.jobData.Id);
    this.router.navigate(['editclient', this.clientData.Id]);
}

View(row: Number) {
  this.clientData = row;
  // this.global.setjobTypeId(this.jobData.Id);
  this.router.navigate(['viewclient', this.clientData.Id]);
}


Delete(row){
  this.clientData = row.Id;
this.service.Put('Client/ClientDataDelete?Id='+this.clientData,null).subscribe(
  (res) => {
    this.toastr.success(res);
    this.getClient();
},
(error) => {
    //if (error.status == 401) this.router.navigate(['/login']);
    this.toastr.error(error.error);
});
}

  applyFilter(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.global.ClientSearch = ''
      this.global.GoToPageForClient = 1
      this.ClientForm.controls["PageSearch"].setValue(this.global.GoToPageForClient);
      this.getClient();
    }
    else {

      this.global.ClientSearch = (event.target as HTMLInputElement).value;
      this.search();
    }
  }


  GoToPage(event: Event) {
   
    var value = (event.target as HTMLInputElement).value;
    if(+value > this.totalpages || +value < 1 ){
        this.toastr.success('Page no Should be between : 1 And ' + this.totalpages );
    }
    else{
      this.global.GoToPageForClient = +value;
    }
  }


  GOTO() {
    this.service.Get('Client/GetClientTableData?pageno=' + this.global.GoToPageForClient + '&pagesize=' + this.pagesize + '&search=' + this.global.ClientSearch, null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data[0]);
      this.dataSource.paginator = this.paginator;
    },
      (error) => {
        this.toastr.error(error.error);
      });
  }

  
  Previous() {
    
    if(this.global.GoToPageForClient > 1){
      this.global.GoToPageForClient = this.global.GoToPageForClient - 1
      this.ClientForm.controls["PageSearch"].setValue(this.global.GoToPageForClient);
      this.getClient();
    }
    else
    {

    }
   
  }

  Next() {
    if (this.totalpages  > this.global.GoToPageForClient ) {
      this.global.GoToPageForClient  = this.global.GoToPageForClient  + 1
      this.ClientForm.controls["PageSearch"].setValue(this.global.GoToPageForClient );
      this.getClient();
    }
  }

  search() {
    this.global.GoToPageForClient  = 1;
    this.ClientForm.controls["PageSearch"].setValue(this.global.GoToPageForClient );
    this.service.Get('Client/GetClientTableData?pageno=' + this.global.GoToPageForClient + '&pagesize=' + this.pagesize + '&search=' + this.global.ClientSearch, null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data[0]);
      this.datapageInfo = data[1];
      this.totalItems = this.datapageInfo[0].length;
      if (this.totalItems > 0) 
      {
        this.totalpages = Math.ceil(this.totalItems / 10);
      }
      else 
      {
        this.totalpages = 0;
      }
    },
      (error) => {
        this.toastr.error(error.error);
      });
  }

  clearsearch()
  {
    this.global.ClientSearch = ''
    this.global.GoToPageForClient  = 1
    this.ClientForm.controls["PageSearch"].setValue(this.global.GoToPageForClient) ;
    this.getClient();
  }

  ngOnDestroy(): void 
  {

  }

}
