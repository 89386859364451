import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { JobRole } from 'src/app/interface';
import { AbstractControl, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { map, startWith } from 'rxjs/operators';


export class WPSendMesg {
  MobileNo: string[];
  JobTitle: string;
  Qualification: string;
  Experience: string;
  JobType: string;
  JobDuration: string;
  JobLocation: string;
  Others: string;
  Payroll: string;
  Email: string;
  ContactNo: string;
  CuntryCode: string;
  IsSend: boolean;
  MessageId: number;

}

export class CandidateData {
  MobileNo: string;
  FirstName: string;
  LastName: string;

}

@Component({
  selector: 'app-newwhatsappsendmsg',
  templateUrl: './newwhatsappsendmsg.component.html',
  styleUrls: ['./newwhatsappsendmsg.component.scss']

})

export class NewWhatsappSendMsgComponent implements OnInit, OnDestroy {
    
  @ViewChild('select') select: MatSelect;

 
  allcandidate: Observable<CandidateData[]>;
  candidatelist: CandidateData[];
  submittedJob: boolean = false;
  jobPostForm: FormGroup;
  wpsSendMesg: WPSendMesg;
  messgaetext: string = '';
  mobilenos: string[] = [];

  allJobRole: JobRole[];
  filterJobRole?: Observable<JobRole[]>;
  formControlObj= new FormControl();
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private service: SharedService<any>
  ) { }

  ngOnInit(): void {
    this.jobPostForm = this.formBuilder.group({
      MobileNo: ['', Validators.required],
      JobTitle: ['', Validators.required],
      Qualification: ['', Validators.required],
      Experience: ['', Validators.required],
      JobType: ['', Validators.required],
      JobDuration: ['', Validators.required],
      JobLocation: ['', Validators.required],
      Others: ['', Validators.nullValidator],
      Payroll: ['', Validators.required],
      Email: ['', Validators.required],
      ContactNo: ['', Validators.required],
    })
    //this.allJobRole = this.service.Get('JobRole/GetJobRole?Id=0', null);

    this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        this.filterJobRole = this.formControlObj.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
        );
      }
    })
   
    // this.  GetAllEmployee();
    
    this.wpsSendMesg = new WPSendMesg();
  }

 
  // GetAllEmployee() {
  //   this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
  //     this.allJobRole = data;
     
  //     this.filterJobRole = this.formControlObj.valueChanges
  //       .pipe(
  //         startWith<string | any>(''),
  //         map(value => typeof value === 'string' ? value : value.JobRole),
  //         map(JobRole => JobRole ? this._filterEmp(JobRole) : this.allJobRole.slice()),
  //       );
  //   });
  // }

  // private _filterEmp(JobRole: string): any[] {
  //   const filterValue = JobRole.toLowerCase();
  //   return this.allJobRole.filter(
  //     option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  // displayFnForEmp(value: any) {
  //   return value ? value.JobRole : undefined;
  // }
  get fjob(): { [key: string]: AbstractControl } {
    return this.jobPostForm.controls;
  }
  private _filterRole(JobRole: string): JobRole[] {
    const filterValue = JobRole.toLowerCase();
    return this.allJobRole.filter(
      option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForRole(value: any) {
    return value ? value.JobRole : undefined;
  }
  onchangejobrole(event) {
    this.allcandidate = this.service.Get('CandidateInfo/GetCandidateWPSendMessage?jobroleId=' + event, null);
  }

  allSelected = false;

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getValues(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }) {
    if (event.isUserInput) {
      this.allSelected = false;
      if (event.source.selected === true) {
        this.mobilenos.push(event.source.value);
        //console.log(event.source.value)
      } else {
        var index = this.mobilenos.indexOf(event.source.value);
        if (index !== -1) {
          this.mobilenos.splice(index, 1);
       
        }
        // console.log(event.source.value)
      }
    }
  }


  sendData() {
    
    if (this.jobPostForm.invalid) {
      this.toastr.error("Enter the data");
    }
    else {
      this.allcandidate.subscribe((value: CandidateData[]) => {
        this.candidatelist = value;
        if (this.allSelected) {

          for (var index in this.candidatelist) {
            this.mobilenos.push(this.candidatelist[index].MobileNo);
          }
          this.wpsSendMesg.MobileNo = this.mobilenos;
          const user = this.jobPostForm.getRawValue();
          user.MobileNo = this.wpsSendMesg.MobileNo = this.mobilenos;
          this.wpsSendMesg.JobTitle = user.JobTitle;
          this.wpsSendMesg.Qualification = user.Qualification;
          this.wpsSendMesg.Experience = user.Experience;
          this.wpsSendMesg.JobType = user.JobType;
          this.wpsSendMesg.JobDuration = user.JobDuration;
          this.wpsSendMesg.JobLocation = user.JobLocation;
          this.wpsSendMesg.Payroll = user.Payroll;
          this.wpsSendMesg.Email = user.Email;
          this.wpsSendMesg.ContactNo = user.ContactNo;
          this.wpsSendMesg.Others = user.Others;
          this.service.Post('WpMessage/SendMEssageToCandidate', this.wpsSendMesg).subscribe(
            (res) => {
              this.toastr.success(res);
              this.jobPostForm.reset();
            },
            (error) => {
              this.toastr.error(error.error);
            });
           
          this.wpsSendMesg.MobileNo = [];
          this.wpsSendMesg.JobTitle = '';
          this.wpsSendMesg.Qualification = '';
          this.wpsSendMesg.Experience = '';
          this.wpsSendMesg.JobType = '';
          this.wpsSendMesg.JobDuration = '';
          this.wpsSendMesg.JobLocation = '';
          this.wpsSendMesg.Others = '';
          this.wpsSendMesg.Payroll = '';
          this.wpsSendMesg.Email = '';
          this.wpsSendMesg.ContactNo = '';
          

        }
        else
         {
          this.wpsSendMesg.MobileNo = this.mobilenos;
          const user = this.jobPostForm.getRawValue();
          this.wpsSendMesg.JobTitle = user.JobTitle;
          this.wpsSendMesg.Qualification = user.Qualification;
          this.wpsSendMesg.Experience = user.Experience;
          this.wpsSendMesg.JobType = user.JobType;
          this.wpsSendMesg.JobDuration = user.JobDuration;
          this.wpsSendMesg.JobLocation = user.JobLocation;
          this.wpsSendMesg.Payroll = user.Payroll;
          this.wpsSendMesg.Email = user.Email;
          this.wpsSendMesg.ContactNo = user.ContactNo;
          this.wpsSendMesg.Others = user.Others;
          this.service.Post('WpMessage/SendMEssageToCandidate', this.wpsSendMesg).subscribe(
            (res) => {
              this.toastr.success(res);
              this.jobPostForm.reset();
            },
            (error) => {
              this.toastr.error(error.error);
            });

            this.wpsSendMesg.MobileNo = [];
            this.wpsSendMesg.JobTitle = '';
            this.wpsSendMesg.Qualification = '';
            this.wpsSendMesg.Experience = '';
            this.wpsSendMesg.JobType = '';
            this.wpsSendMesg.JobDuration = '';
            this.wpsSendMesg.JobLocation = '';
            this.wpsSendMesg.Others = '';
            this.wpsSendMesg.Payroll = '';
            this.wpsSendMesg.Email = '';
            this.wpsSendMesg.ContactNo = '';
         
        }

      });
    }
  }
  ngOnDestroy(): void {

  }
}