<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Testimonials</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Testimonials</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Feedback -->
<section class="feedback-area two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-item">
                    <h3>Jubra Ward</h3>
                    <span>CEO at Alfa School</span>
                    <p>“I’m ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniaxn ullamco laboris nisi ut at voluptatem.</p>
                    <h4><i class="flaticon-left-quote"></i> Just Awesome</h4>
                    <img src="assets/img/home-one/feedback1.jpg" alt="Feedback">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="feedback-item">
                    <h3>Robin William</h3>
                    <span>Manager Support Act It</span>
                    <p>“I’m ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniaxn ullamco laboris nisi ut at voluptatem.</p>
                    <h4><i class="flaticon-left-quote"></i> Very Reliable</h4>
                    <img src="assets/img/home-one/feedback2.jpg" alt="Feedback">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="feedback-item">
                    <h3>Tom Henry</h3>
                    <span>Director at Alfa School</span>
                    <p>“I’m ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniaxn ullamco laboris nisi ut at voluptatem.</p>
                    <h4><i class="flaticon-left-quote"></i> Looking Nice</h4>
                    <img src="assets/img/home-one/feedback3.jpg" alt="Feedback">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="feedback-item">
                    <h3>Jac Jackson</h3>
                    <span>Engineer at Alfa School</span>
                    <p>“I’m ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniaxn ullamco laboris nisi ut at voluptatem.</p>
                    <h4><i class="flaticon-left-quote"></i> Awesome</h4>
                    <img src="assets/img/home-one/feedback4.jpg" alt="Feedback">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->