<!-- Navbar -->

<div class="navbar-area fixed-top">
  <div style="margin-left: 79%;margin-top: 39px;">
    <label class="login-left" style="color:white ;">
      <i class="flaticon-candidate"></i>
      {{userName}}
    </label>
  </div>

  <div class="mobile-nav">
    <a href="#" class="logo"><img src="assets/png/finallightthme.png" alt="Logo"></a>
  </div>


  <div class="main-nav three">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-md navbar-light">

        <a class="navbar-brand"><img src="assets/png/finallightthme.png" alt="Logo"></a>

        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
          <ul class="navbar-nav">

            <li class="nav-item" *ngIf="!myGlobals.isSubmitted  && !myGlobals.isUser">
              <a routerLink="" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>

            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle" *ngIf="!myGlobals.isSubmitted">Sign_Up<i
                  class='bx bx-chevron-down'></i></a>

              <ul class="dropdown-menu">

                <li class="nav-item"><a routerLink="/companysighup" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Company</a>
                </li>

                <li class="nav-item"><a routerLink="/employer/register" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">candidates</a></li>

                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Institue/University/College</a></li>

              </ul>
            </li>

            <!-- Owner -->
            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a routerLink="/dashboard"
                class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dashboard</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a routerLink="/ownerhome/profile"
                class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Profile</a></li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a href="javascript:void(0)"
                class="nav-link dropdown-toggle">Job<i class='bx bx-chevron-down'></i></a>
              <ul class="dropdown-menu">

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a routerLink="/post-a-job"
                    class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Job Post</a>
                </li>

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a routerLink="/managejobtable"
                    class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Manage Jobs
                  </a>
                </li>

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a routerLink="/overduejob"
                  class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Overdue Jobs
                </a>
              </li>
              </ul>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a href="javascript:void(0)"
                class="nav-link dropdown-toggle">Candidate<i class='bx bx-chevron-down'></i></a>
              <ul class="dropdown-menu">

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                  <a routerLink="/candidates" class="nav-link">Find Candidates</a>
                </li>

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                  <a routerLink="/candidatetable" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Candidates</a>
                </li>

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                  <a routerLink="/appliedjob" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Candidate Applied Job</a>
                </li>

                <li class="nav-item"><a routerLink="/candidatereport" class="nav-link" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Candidate Status</a></li>
              </ul>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
              <a routerLink="/company" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Company</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
              <a routerLink="/client" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Client</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a href="javascript:void(0)"
                class="nav-link dropdown-toggle">Recruiter<i class='bx bx-chevron-down'></i></a>
              <ul class="dropdown-menu">

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                  <a routerLink="/Recruiters" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Recruiters</a>
                </li>

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                  <a routerLink="/RecruitersPoints" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Recruiters Points</a>
                </li>

                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                  <a routerLink="/recruiterlastlogin" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Recruiters Login</a>
                </li>
              </ul>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted"><a href="javascript:void(0)"
                class="nav-link dropdown-toggle">WhatsApp<i class='bx bx-chevron-down'></i></a>
              <ul class="dropdown-menu">
  
                <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                  <a routerLink="/whatsappmsgandreceive" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Send And Receive Message</a>
                </li>

              </ul>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Masters<i
                  class='bx bx-chevron-down'></i></a>

              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/companies/create" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Create Company</a></li>
                    <li class="nav-item"><a routerLink="/client/create" class="nav-link" routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Create Client</a></li>
                <li class="nav-item"><a routerLink="/createcandidate" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Create Candidate</a></li>
                <li class="nav-item"><a routerLink="/users" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Create Recruiter</a></li>
                <li class="nav-item"><a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Create Jobs</a></li>
                <li class="nav-item"><a routerLink="/createcollege" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Create College</a></li>

              </ul>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle">Configuration<i
                  class='bx bx-chevron-down'></i></a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/country" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Country</a></li>
                <li class="nav-item"><a routerLink="/state" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">State</a></li>
                <li class="nav-item"><a routerLink="/city" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">City</a></li>
                <li class="nav-item"><a routerLink="/currency" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Currency</a></li>
                <li class="nav-item"><a routerLink="/industrytype" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Industry Types</a></li>
                    <li class="nav-item"><a routerLink="/function" class="nav-link" routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Function</a></li>
                <li class="nav-item"><a routerLink="/jobrole" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Job Role</a></li>
                <li class="nav-item"><a routerLink="/language" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Languages</a></li>
                <li class="nav-item"><a routerLink="/qualification" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Qualification</a></li>
                    <li class="nav-item"><a routerLink="/academicdiscipline" class="nav-link" routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Academic Discipline</a></li>
                    <li class="nav-item"><a routerLink="/specialization" class="nav-link" routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}">Specialization</a></li>
                <li class="nav-item"><a routerLink="/skill" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Skills</a></li>
              </ul>
            </li>

            <!-- User -->
            <li class="nav-item" *ngIf="myGlobals.isSubmitted && myGlobals.isUser">
              <a routerLink="/recruiterhome/profile" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Profile</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isSubmitted && myGlobals.isUser">
              <a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Job Post</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isSubmitted && myGlobals.isUser">
              <a routerLink="/managejobtable" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Manage Jobs</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isSubmitted && myGlobals.isUser">
              <a routerLink="/candidates" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Find Candidate</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isSubmitted && myGlobals.isUser">
              <a routerLink="/candidatetable" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Candidates</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isSubmitted && myGlobals.isUser">
              <a routerLink="/createcandidate" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Create Candidate</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isSubmitted && myGlobals.isUser"> <a routerLink="/candidatereport" class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Candidate Status</a></li>

            <li class="nav-item" *ngIf="myGlobals.isUser && myGlobals.isSubmitted"><a href="javascript:void(0)"
                class="nav-link dropdown-toggle">WhatsApp<i class='bx bx-chevron-down'></i></a>
              <ul class="dropdown-menu">

                <li class="nav-item" *ngIf="myGlobals.isUser && myGlobals.isSubmitted">
                  <a routerLink="/whatsappmsgandreceive" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Send And Receive Message</a>
                </li>
              </ul>
            </li>
          </ul>

          <div class="side-nav three">
            <a class="login-left" routerLink="/login" *ngIf="!myGlobals.isSubmitted" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">
              <i class="flaticon-enter"></i>
              Login
            </a>

            <a class="login-left" *ngIf="myGlobals.isSubmitted" (click)="onLogout($event)"><i
                class="flaticon-enter"></i> Logout</a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<!-- End Navbar -->