import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { AppliedJob } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';


@Component({
    selector: 'app-applyjob',
    templateUrl: './applyjob.component.html',
    styleUrls: ['./applyjob.component.scss']
})
export class ApplyJobComponent implements OnInit, OnDestroy {

   
  displayedColumns: string[] = ['ProfileName','JobNumber',  'JobRole', 'JobCompanyName','AppliedDate'];
  dataSource: MatTableDataSource<AppliedJob>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(
        private toastr: ToastrService,
        private service: SharedService<any>
    ) { }

    ngOnInit(): void {
        this.getApplyjob()
    }

    getApplyjob() {
       
        this.service.Get('AppliedJob/GetAppliedJob?Id=0', null).subscribe(data => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }
      
    ngOnDestroy(): void {

    }
   
}
