import { Component, Inject, Injectable, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-genral-dialog',
  templateUrl: './genral-dialog.component.html',
  styleUrls: ['./genral-dialog.component.scss']
})
export class GenralDialogComponent implements OnInit {


  message: string;
  title = 'appBootstrap';
  genralForm: FormGroup;
  closeResult: string = '';

  constructor(private modalService: NgbModal,
    private dialogRef: MatDialogRef<GenralDialogComponent>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,

  ) {
    // Update view with given values
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.genralForm = this.formBuilder.group({
      Message: [null, Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.genralForm.controls;
}

onCancel()
{
  this.dialogRef.close();
}

  onConfirm(): void {
    if (this.genralForm.invalid) {
      this.toastr.error("This data is not valid");
    }
    else {
      this.dialogRef.close(this.genralForm.value);
    }



  }

  onDismiss(): void {
    // Close the dialog, return false

  }
}

// export class InputDialogModel {

//   constructor(public title: string,
//     public message: string) {
//   }

// }


