import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { City } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../config-dialog/confing-dialog.component';



@Component({
    selector: 'app-city',
    templateUrl: './city.component.html',
    styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit, OnDestroy {

    jobPostsInfo = [];

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
        private dialog: MatDialog
    ) { }

    
    displayedColumns = [ "CityName", "StateName","IsActive","Edit"];
    message: string = '';
    result: string = '';
    dataSource = new MatTableDataSource<City>();
    CityData: any;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    ngOnInit(): void {
       this.refreshList()
    }

    refreshList() {
        this.service.Get('/City/GetCity?Id=0', null).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // this.Country.emit();
        })
      }
    
      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }
    
    
    
      GetId(row: Number) {
        this.CityData = row;
      }
    
     
    Addcity() {        
      this.router.navigate(['add-city', 0])
    }

    Editcity(row: any) {
        this.router.navigate(['add-city', row.Id]);
    }
    
      IsActive(row: any) {
        this.confirmDialog(row)
      }
    
      IsActiveContry(row: any) {
        this.service.Put('City/UpdateActiveStatus', row).subscribe({
          next: (res) => {
            if (row.IsActive == true) {
              // let override: Partial<IndividualConfig> = {
              //   positionClass : 'toast-center-center'
              // };
              // ,'TITLE',override
              
              this.toastr.success('City inactive sucessfully');
            }
            else {
              this.toastr.success('City active sucessfully');
            }
            this.refreshList();
          },
    
        })
      }
    
      confirmDialog(row: any) {
        
          if (row.IsActive == true) {
            this.message = 'Are you sure want to inactive city ' + row.CityName + ' ?';
          }
          else {
            this.message = 'Are you sure want to active city ' + row.CityName + ' ?';
          }
    
          const dialogData = new ConfirmDialogModel("Confirm Action", this.message);
    
          const dialogRef = this.dialog.open(ConfigDialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });
    
          dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
              this.IsActiveContry(row);
            
          }
      
        })
      }
    
      showIcon(index: boolean): string {
        if (index === true) {
          return 'bx-toggle-left';
        } else {
          return 'bx-toggle-right';
        }
      }

    ngOnDestroy(): void {

    }
   
}
