<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Create Recruiter</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                            <!-- <a routerLink="/post-a-job">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Create Recruiter</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="userForm">
                <div class="profile-content-inner">
                    <!-- <h2>Create Recruiter</h2> -->
                    <div class="row">

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>First Name <label class="star">*</label></label>
                                <input class="form-control" formControlName="FirstName" placeholder="Enter First Name"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.FirstName.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.FirstName.errors">
                                    <div *ngIf="fcomp.FirstName.errors.required">
                                        First Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Last Name <label class="star">*</label></label>
                                <input class="form-control" formControlName="LastName" placeholder="Enter Last Name"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.LastName.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.LastName.errors">
                                    <div *ngIf="fcomp.LastName.errors.required">
                                        Last Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label class="chk">Use Existing Email</label>
                                <input type="checkbox"  (change)="onCheckboxChange($event)">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.Emailscmd">
                            <div class="form-group">
                                <label>Recruiter Email <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="Email" 
                                 [ngClass]="{ 'is-invalid': submittedComp && fcomp.Email.errors }">
                                 <option value="" disabled selected>Choose any one</option>
                                 <option *ngFor="let recruiter of (allrecruitermails | async)" [value]="recruiter.Email">
                                 {{recruiter.Email}} 
                               </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Email.errors">
                                    <div *ngIf="fcomp.Email.errors.required">
                                        Email is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.Emails">
                            <div class="form-group">
                                <label >Email <label class="star">*</label></label>
                                <input class="form-control" formControlName="Email" placeholder="Enter Email" maxlength="150"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Email.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Email.errors">
                                    <div *ngIf="fcomp.Email.errors.required">
                                        Email is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>ISD Code <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="CountryISDCode"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryISDCode.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let countries of allCountryISD " [value]="countries.ISDCode">
                                        {{countries.ISDCode}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CountryISDCode.errors">
                                    <div *ngIf="fcomp.CountryISDCode.errors.required">
                                        ISD Codeis required
                                    </div>
                                </div>
                            </div>
                            </div>
    
                            <div class="col-lg-2 col-md-12">
                                <div class="form-group">
                                    <label>Mobile Number <label class="star">*</label></label>
                                    <input type="text" class="form-control" formControlName="MobileNo"
                                        placeholder="1234567890" maxlength="10" (keypress)="keyPressAlphaNumeric($event)"
                                        [ngClass]="{ 'is-invalid': submittedComp && fcomp.MobileNo.errors }">
                                    <div class="invalid-feedback" *ngIf="submittedComp && fcomp.MobileNo.errors">
                                        <div *ngIf="fcomp.MobileNo.errors.required">
                                            Mobile Number is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        <button (click)="userinfForm()" class="btn dashboard-btn" *ngIf="this.Emails">
                            Save Your Information
                        </button>

                        <button (click)="reassignrecruiterForm()" class="btn dashboard-btn" *ngIf="this.Emailscmd">
                            Save Your Information
                        </button>

                    </div>
                </div>


            </form>
        </div>
    </div>
</div>

<!-- End Post A Job -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->