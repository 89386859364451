import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { City, JobRole, Qualificationa } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  allJobRole: Observable<JobRole[]>;
  allcities: Observable<City[]>;
  allQualification: Observable<Qualificationa[]>;
  jobType: number = 0;
  city: number = 0;
  Education: number = 0;
  submitted: boolean = false;
  jobPostsInfo = [];
  obs: any;
  dataFilter1: string[] = [];
  data:any;
  citycontrol = new FormControl();
  EducationControl = new FormControl();
  jobRoleControl = new FormControl();
  info : any;

  constructor(private Service: SharedService<any>,
    public router:Router,private Global: GlobalService,
    private toastr: ToastrService,
    private service: SharedService<any>) 
    { 
     
  }

  ngOnInit(): void {
   
    this.getJobRole();
    this.getCity();
     this.getQualification();
    this.GetCount();

  }


  getJobRole(){
    this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
      this.allJobRole = jobrole;
    });
  }

  getCity(){
    this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
      this.allcities = city;
    });
  }
 

  getQualification(){
    this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(Qualificationa => {
      this.allQualification = Qualificationa;
    })
  }



  search(){
    const queryParams: any = {};
    const data: string[] = [];
    data.push('searchString')
    data.push(this.jobType.toString());
    data.push(this.city.toString());
    data.push(this.Education.toString());
    data.push('0');
    data.push('0');
    data.push('0');
    data.push('0');
    data.push('0');
    data.push('0');
   

    queryParams.myArray = data;
    const navigationExtras: NavigationExtras = {
      queryParams
    };

    this.router.navigate(['searchjobs'],navigationExtras);
  }

  onchangeJobType(event: any) {
    this.submitted = true;
    this.jobType = event;    
  }
  GetByEducation(edu: any) {
    this.Education = edu;
  }
  onchangeCity(event: any) {
    this.city = event;
    this.Global.setCity(event);
  }
  WorkFromHome(jobtype:number){
    this.Global.setjobTypeId(jobtype);
    this.router.navigate(['workfromhomejobs']);
  }

  step1(){
    this.router.navigate(['login']);
  }

  GetCount() {
    this.service.Get('Dashboard/GetDashboardData', null).subscribe(data => {

      this.info = data;
    
    })
  }
  
}
