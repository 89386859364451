import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';


@Component({
    selector: 'app-add-city',
    templateUrl: './add-city.component.html',
    styleUrls: ['./add-city.component.scss']
})
export class AddCityComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    cityForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: number = 0;
    public dataSource: any;
    allState?: any;

    constructor(private formBuilder: FormBuilder,
        private service: SharedService<any>,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.cityForm = this.formBuilder.group({
            Id: ['', null],
            CityName: [null, Validators.required],
            StateId: [null, Validators.required],
        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                this.id = Number(params.get('id'));
            }
        );

        if (this.id != 0) {
            this.boolIdVisible = false;
         this.GetCityDataById(this.id);
            this.ActionBtn = "Update";
            this.allState = this.service.Get('State/GetActiveState', null);
      

        }
        else {
            this.boolIdVisible = true;
            this.ActionBtn = "Save";
            this.allState = this.service.Get('State/GetActiveState', null);
      
        }
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.cityForm.controls;
    }

    countryinfForm() {
        this.submittedComp = true;
        if (this.cityForm.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            if (this.id == 0) {
                const city = this.cityForm.getRawValue();
                this.service.Post('City/InsertCity', city).subscribe(
                    (res) => {
                        this.toastr.success(res);
                        this.cityForm.reset();
                        this.router.navigate(['./city'])
                    },
                    (error) => {
                        this.toastr.error(error.error);
                    });
                this.submittedComp = false;
            }
            else {
                this.UpdateCountry();
            }
        }
    }

    Close(){
        this.router.navigate(['./city'])
    }

    UpdateCountry() {
        const city = this.cityForm.getRawValue();
        this.service.Put('City/UpdateCity', city).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['./city'])
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    GetCityDataById(Id: number) {
        this.service.Get('City/GetCity?Id=' + Id, null).subscribe(data => {
          this.dataSource = data;
          this.cityForm.controls["Id"].setValue(this.dataSource[0].Id);
          this.cityForm.controls["CityName"].setValue(this.dataSource[0].CityName);
          this.cityForm.controls["StateId"].setValue(this.dataSource[0].StateId);
        })
      }


    ngOnDestroy(): void {

    }

    
}
