import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { MatTableDataSource } from '@angular/material/table';
import { BuildResume, EmployeeMaster, JobRole, UserType } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { map, startWith } from 'rxjs/operators';


@Component({
    selector: 'app-viewresume',
    templateUrl: './viewresume.component.html',
    styleUrls: ['./viewresume.component.scss']
})
export class ViewResumeComponent implements OnInit, OnDestroy {
    submittedComp: boolean = false;
    candidateInfo = [];
    userId: number;
    name: string;
    designation: string;
    getresumeData: BuildResume;
    datasourceExp:any;
    displayedColumns: string[] = ['JobRole', 'CandidateStatus', 'CompanyName'];
    allJobRole: Observable<JobRole[]>;
    allJobNumber: Observable<any[]>;
    // allCompanyName: Observable<any>;
    allrecruiter : Observable<any>;
    companyId: number;
    recruiteId:number;
    JobRole: number;
    PostJobId: number;
    EmpId: number = 0;
    Id:number;
    datasourceEdu:any;
    userForm: FormGroup;
    datasourceAchiv:any;
    datasourceCerty:any;
    datasourceSkill:any;
    datasourceLocation:any;
    datasourceLanguages:any;
    dataSource: MatTableDataSource<EmployeeMaster>;
    dataFilter: number[] = [];
    public placeholder: string = '';
    src: any;
    safeUrl: SafeResourceUrl;
    filepath: string = '';
    allCompanyName: any[];
    filtercmp?: Observable<any[]>;
    formControlObj = new FormControl();

    constructor(
    private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        public Global: GlobalService,
        private route: ActivatedRoute,
        private sanitized: DomSanitizer
        
    ) { }

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ngOnInit(): void {

        this.userForm = this.formBuilder.group({
            CompanyId: [null, Validators.nullValidator],
            JobRoleId: ["", Validators.required],
            JobNumber: [null, Validators.required],
            RecruiterId: [null, Validators.nullValidator],
        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                
                this.Id = Number(params.get('id'));
            }
        );
        this.userId = this.Id
        this.getUser();
        // this.allCompanyName = this.Service.Get('Company/GetCompanyDataSelection',null);
       // this.allCompanyName = this.Service.Get('Client/GetClientData?Id=0', null);

       this.Service.Get('Client/GetClientData?Id=0', null).subscribe(data => {
        if (data != null) {
          this.allCompanyName = data;
          this.filtercmp = this.formControlObj.valueChanges.pipe(
            startWith<string | any>(''),
            map(value => typeof value === 'string' ? value : value.ClientName),
            map(ClientName => ClientName ? this._filtercmp(ClientName) : this.allCompanyName.slice()),
          );
        }
      })

        this.allrecruiter = this.Service.Get('Recruiter/GetActiveRecruiterData',null);
    }
    get fcomp(): { [key: string]: AbstractControl } {
        return this.userForm.controls;
    }

    private _filtercmp(ClientName: string): any[] {
        const filterValue = ClientName.toLowerCase();
        return this.allCompanyName.filter(
          option => option.ClientName?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForcmp(value: any) {
        return value ? value.ClientName : undefined;
      }
    getAllEducations(empId: number) {
        this.Service.Get('CandidateEducationInfo/GetCandidateEducationInfoByEmpId?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceEdu = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //Experience
    getAllExperience(empId : number) {
        this.Service.Get('CandidateExperienceInfo/GetCandidateExperienceInfoByEmpId?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceExp = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }
    //Basic Info
    getUser() {
        
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.Id, null).subscribe(
            (res) => {
        
                this.getresumeData = res;
                this.Global.setCandidateId(this.getresumeData.Id);
                this.getAllEducations(this.getresumeData.Id);
                this.getAllExperience(this.getresumeData.Id);
                this.getAllAchievements(this.getresumeData.Id);
                this.getAllCertification(this.getresumeData.Id);
                this.getAllSkills(this.getresumeData.Id);
                this.getAllCity(this.getresumeData.Id);
                this.getAllLanguages(this.getresumeData.Id);
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

      refreshListofCandidateStatus() {
        this.Service.Get('CandidateInfo/GetCandidateInfoByCandidateStatus?CandidateId=' + this.Global.getCandidateId(), null).subscribe(data => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            
            this.getResumeFilePath();
        })
    }


    //Achievements
    getAllAchievements(empId : number) {
        this.Service.Get('CandidateAchievements/GetCandidateAchievementsByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceAchiv = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    // Certification
    getAllCertification(empId : number) {
        this.Service.Get('CandidateCertificatios/GetCandidateCertificatiosByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceCerty = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //Skills
    getAllSkills(empId : number) {
        this.Service.Get('CandidateSkills/GetCandidateSkills?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceSkill = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //languages
    getAllLanguages(empId:number) {
        this.Service.Get('CandidateLanguages/GetCandidateLanguages?EmpId=' +empId, null).subscribe(
            (res) => {
                this.datasourceLanguages = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    getAllCity(empId:number) {
        this.Service.Get('CandidtaePreferredLocations/GetCandidatePreferredLocations?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceLocation = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }


      onchangeCompany(event) {
      
        this.companyId = event;
        this.allJobRole = this.Service.Get('ShortListCandidate/GetJobRoleWithPostedJobIdForShortlistedCandidate?companyId=' + this.companyId, null);
      }

      onchangeRecruiter(event){
        this.recruiteId = event;
      }

      shortListCandidate() {
        this.submittedComp = true;
        if (this.userForm.invalid) {
            this.toastr.error("this data is not valid");
        }
        else {
        this.dataFilter = [];
        
        this.dataFilter.push(this.PostJobId);
        this.dataFilter.push(this.Global.getCandidateId());
        this.dataFilter.push(this.Global.getuserId());
        if(this.Global.GetUserType() == UserType.Recruiter){
         this.dataFilter.push(this.Global.getRecruiterId());
        }
        else
        {
            this.dataFilter.push(this.recruiteId);
        }
        this.Service.Post('ShortListCandidate/CandidateShortlist', this.dataFilter).subscribe(
            (res) => {
                this.toastr.success(res);  
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
      }
    }

      onchangeJobRole(event) {
        this.JobRole = event 
        this.allJobNumber = this.Service.Get('ShortListCandidate/GetJobNumberForShortlistedCandidate?roleId=' + this.JobRole + '&companyId=' + this.companyId, null);
      }

      
      onchangeJobNumber(event) {
        this.PostJobId = event 
      }
      
      getResumeFilePath() {
        this.Service.Get('CandidateInfo/GetResumefile?candidateId=' + this.Global.getCandidateId(), null).subscribe(
            (res) => {
                this.src = res;
                this.filepath = res;
              this.safeUrl = this.sanitized.bypassSecurityTrustResourceUrl(this.src + '#toolbar=0&navpanes=0');         
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    
    

    ngOnDestroy(): void {

    }
}
