<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Applied Jobs
                    </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Applied Job
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div >
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul class="job-list-item align-items-center">
                    <li>
                        <form>
                            <div class="form-group">
                            </div>
                        </form>
                    </li>
                </ul>
                <input class="form-control search" (keyup)="applyFilter($event)" placeholder="Enter Text To Search" >
                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                      <ng-container matColumnDef="ProfileName">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Candidate Name </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.ProfileName}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="JobNumber">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Job Number </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobNumber}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="JobCompanyName">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">Company Name </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobCompanyName}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="JobRole">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">Job Role </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobRole}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="AppliedDate">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Applied Date </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.AppliedDate | date:'dd-MM-yyyy'}}
                        </td>
                      </ng-container>
                
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                
                    <mat-paginator [length]="100" color="primary" [pageSize]="10" [pageSizeOptions]="[8, 10, 25, 100]"
                      aria-label="Select page">
                    </mat-paginator>
                
                  </div>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->