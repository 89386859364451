<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>View Candidate </h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/employer/dashboard/:id/companies/:id">Companies list</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>View Candidate </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Create Company -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">
            <form >
                <div class="profile-content-inner">
                    <!-- <h2>Company</h2> -->
                    <div class="row">

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].FirstName}}">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].LastName}}">
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>CountryISD Code</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].CountryISDCode}}">
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>Mobile Number</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].MobileNo}}">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Email Id</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].UserName}}">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Qualification</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].Qualification}}">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Academic</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].AcademicDescription}}">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Specialization</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].SpecializationDescription}}">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>JobRole</label>
                                <input type="text" class="form-control" disabled value="{{datasource[0].JobRole}}">
                            </div>
                        </div>

                </div>
                <button (click)="Close()" class="btn dashboard-btn">
                    Close
                </button>
           
        
    </div>
</form>
</div>

<!-- End create company -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->