import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Currency, FunctionMaster } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../config-dialog/confing-dialog.component';

@Component({
    selector: 'app-function',
    templateUrl: './function.component.html',
    styleUrls: ['./function.component.scss']
})
export class FunctionComponent implements OnInit, OnDestroy {

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
        private dialog: MatDialog,
    ) { }


    displayedColumns = ["FunctionName", "IsActive", "Edit"];
    message: string = '';
    result: string = '';
    dataSource = new MatTableDataSource<FunctionMaster>();
    FunctionData: any;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    ngOnInit(): void {
        this.refreshList();
    }

    refreshList() {
        this.service.Get('Function/GetFunction?Id=0', null).subscribe(data => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        })
    }

    AddFunction() {
        this.router.navigate(['addfunction', 0])
    }

    EditFunction(row: any) {
        this.router.navigate(['addfunction', row.Id]);
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }


    IsActive(row: any) {
        this.confirmDialog(row)
    }

    IsActiveFunction(row: any) {
        this.service.Put('Function/UpdateActiveStatus', row).subscribe({
            next: (res) => {
                if (row.IsActive == true) {
                    this.toastr.success('Function inactive sucessfully');
                }
                else {
                    this.toastr.success('Function active sucessfully');
                }
                this.refreshList();
            },

        })
    }

    confirmDialog(row: any) {

        if (row.IsActive == true) {
            this.message = 'Are you sure want to inactive Function ' + row.FunctionName + ' ?';
        }
        else {
            this.message = 'Are you sure want to active Function ' + row.FunctionName + ' ?';
        }

        const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

        const dialogRef = this.dialog.open(ConfigDialogComponent, {
            maxWidth: "400px",
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
                this.IsActiveFunction(row);

            }

        })
    }

    showIcon(index: boolean): string {
        if (index === true) {
            return 'toggle_on';
        } else {
            return 'toggle_off';
        }
    }

    ngOnDestroy(): void {

    }

}
