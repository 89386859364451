
<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Send Message</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Send Message
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="frame">
	<div id="sidepanel" *ngIf="activeUser">
		<div id="profile" [class.expanded]="expanded">
			<div class="wrap">
				<img id="profile-img"(click)="expandStatuses = !expandStatuses"[class]="activeUser.status"
          [src]="activeUser.img"
          alt=""
		  *ngIf="activeUser.img"/>
		<img id="profile-img"(click)="expandStatuses = !expandStatuses"[class]="activeUser.status" src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 0"/>
		<img id="profile-img"(click)="expandStatuses = !expandStatuses"[class]="activeUser.status" src="assets/candidateimg/Female_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 1"/>
		<img id="profile-img"(click)="expandStatuses = !expandStatuses"[class]="activeUser.status" src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender != 0 && activeUser.gender != 1"/>

		
				<p>{{activeUser.name}}</p>
				<i
          class="fa fa-chevron-down expand-button"
          (click)="expanded = !expanded"
          aria-hidden="true"
        ></i>
				<div id="status-options" [class.active]="expandStatuses">
					<ul>
						<li *ngFor="let item of (statuses | keyvalue)" [attr.id]="'status-'+item.value"
							(click)="activeUser.status = item.value; expandStatuses=false"
							[class.active]="item.value === activeUser.status" [class]="item.value">
							<span class="status-circle"></span>
							<p style="text-transform: capitalize;">{{item.value}}</p>
						</li>
					</ul>
				</div>
				<div id="expanded">
					<ng-container *ngFor="let item of (activeUser?.social | json | keyvalue)">
						<label for="twitter"
              ><i
                class="fa fa-fw"
                [class]="'fa-'+item.key"
                aria-hidden="true"
              ></i
            ></label>
						<input [name]="item.key" type="text" [value]="item.value" />
          </ng-container>
				</div>
			</div>
		</div>
		<div id="search">
			<label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
			<input type="text" (keyup)="applyFilter($event)" placeholder="Search contacts..." />
    </div>
			<div id="contacts" [class.expanded]="expanded">
				<ul>
					<li class="contact" *ngFor="let user of users" (click)="setUserActive(user)"
						[class.active]="activeUser.id === user.id">
						<div class="wrap">
							<span class="contact-status" [class]="user.status"></span>
							<img [src]="user.img" alt="" *ngIf="user.img != ''"/>
							<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="user.img == '' && user.gender == 0"/>
							<img src="assets/candidateimg/Female_Colors.jpg" alt=""  *ngIf="user.img == '' && user.gender == 1"/>
							<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="user.img == '' && user.gender != 0 && user.gender != 1"/>
							<div class="meta">
								<p class="name">{{user.name}}</p>
								<p class="preview">
									<!-- {{user.messages[user.messages?.length - 1]?.message || ''}} -->
									{{user.mobileno}}
								</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- <div id="bottom-bar">
				<button id="addcontact">
        <i class="fa fa-user-plus fa-fw" aria-hidden="true"></i>
        <span>Add contact</span>
      </button>
				<button id="settings">
        <i class="fa fa-cog fa-fw" aria-hidden="true"></i> <span>Settings</span>
      </button>
			</div> -->
		</div>

		<div class="content" *ngIf="activeUser">
			<div class="contact-profile">
				<img [src]="activeUser.img" alt="" *ngIf="activeUser.img != ''"/>
				<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 0"/>
							<img src="assets/candidateimg/Female_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 1"/>
							<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender != 0 && activeUser.gender != 1"/>
				<p>{{activeUser.name}}</p>
				<div class="social-media">
					<i class="fa fa-facebook" aria-hidden="true"></i>
					<i class="fa fa-twitter" aria-hidden="true"></i>
					<i class="fa fa-instagram" aria-hidden="true"></i>
				</div>
			</div>
			<div class="messages" #scrollMe>
				<ul  *ngFor="let msg of activeUser?.Message">
					<li [class]="msg.type">
						<img [src]="msg.type === 'replies' ? activeUser.img : messageReceivedFrom.img" alt="" />
						<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 0"/>
						<img src="assets/candidateimg/Female_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 1"/>
						<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender != 0 && activeUser.gender != 1"/>
						<p>{{msg?.message || ''}}
							<br />
							
						</p>
						<br />
						
						<!-- <label style="text-align: center;" *ngIf="msg.type === 'sent' ">{{msg?.TimeStamp || ''}}</label> -->
						<!-- <label style="">{{msg?.TimeStamp || ''}}</label> -->
					
					</li>
					<h6 *ngIf="msg.type === 'sent'" style="font-size: 11px; width: 153px;">{{msg?.TimeStamp || ''}}</h6>
						<h6 *ngIf="msg.type === 'replies'" style="    text-align: right;font-size: 11px;">{{msg?.TimeStamp || ''}}</h6>
				</ul>
			</div>
            <!-- <div class="message-input">
				<div class="wrap">
					<input
          type="text"
          #msgInput
          placeholder="Write your message..."
          (keyup.enter)="addNewMessage(msgInput)"
        />
					<i class="fa fa-paperclip attachment" aria-hidden="true"></i>
					<button class="submit" (click)="addNewMessage(msgInput)">
          <i class="bx bx-send" aria-hidden="true"></i>
          
        </button>
				</div>
			</div> -->
	</div>