import {  Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';
import {STATUSES, Message1} from './models'
import { USERS } from 'src/app/components/data/data';

//import {USERS, RANDOM_MSGS, getRandom} from './data'
@Component({
  selector: 'app-wprecivemsg',
  templateUrl: './wprecivemsg.component.html',
  styleUrls: ['./wprecivemsg.component.scss']
})
export class WPReciveComponent implements OnInit, OnDestroy {

  statuses = STATUSES;
  activeUser;
  users: any;
  // Search: string = '';
  Search: string = ''
  expandStatuses = false;
  expanded = false;
  messageReceivedFrom = {
    img: 'https://cdn.livechat-files.com/api/file/lc/img/12385611/371bd45053f1a25d780d4908bde6b6ef',
    name: 'Media bot'
  }
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(
    private Service: SharedService<any>,
    public Global: GlobalService,
  ) {
   
  }

  ngOnInit(): void {
    this.users = USERS;
    this.refreshList();
    //this.setUserActive(this.users[0])
    this.scrollToBottom();
}
    ngAfterViewChecked() {        
    this.scrollToBottom();  
  }

 
  refreshList(): void {
    this.Service?.Get('WtsAppData/GetWtsAppData?search=' + this. Search, null).subscribe({
      next: (data) => {
      this.activeUser = data;
      this.users= data
  
      this.setUserActive(this.users[0])
     
      // this.previewImage = this.dataSource[0].imgstring;
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase();
    this.Search = this.users.filter;
    this.refreshList()
    // this.Service?.Get('WtsAppData/GetWtsAppData?search='+  this.Search, null).subscribe({
    //   next: (data) => {
    //     this.activeUser = data;
    //     this.users= data
    
    //     this.setUserActive(this.users[0])
     
    //   // this.previewImage = this.dataSource[0].imgstring;
    //   }
    // })
    
  }

  addNewMessage(inputField) {
    const val = inputField.value?.trim()
    if (val.length) {
      this.activeUser.messages.push({type: 'sent', message: val})
      this.activeUser.ws.send(
        JSON.stringify({id: this.activeUser.id, message: val})
        );
    }
    inputField.value = '';
  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

setUserActive(user) {
  this.activeUser = user;
  // this.connectToWS();
}

onWSEvent(event, status: STATUSES) {
  this.users.forEach(u => u.ws === event.target ? u.status = status : null)
}

// connectToWS() {
//   if (this.activeUser.ws && this.activeUser.ws.readyState !== 1) {
//     this.activeUser.ws = null;
//     this.activeUser.status = STATUSES.OFFLINE;
//   }
//   if (this.activeUser.ws) {
//     return;
//   }
//   const ws = new WebSocket('wss://compute.hotelway.ai:4443/?token=TESTTOKEN');
//   this.activeUser.ws = ws;
//   ws.onopen = (event) => this.onWSEvent(event, STATUSES.ONLINE);
//   ws.onclose = (event) => this.onWSEvent(event, STATUSES.OFFLINE);
//   ws.onerror = (event) => this.onWSEvent(event, STATUSES.OFFLINE);
//   ws.onmessage = (result: any) => {
//     const data = JSON.parse(result?.data || {});
//     const userFound = this.users.find(u => u.id === data.id);
//     if (userFound) {
//       userFound.messages.push(
//          new Message1('replies', data.message)
//       )
//     }
//   };
// }


  ngOnDestroy(): void {

  }
}

function heartbeat() {
  clearTimeout(this.pingTimeout);

  // Use `WebSocket#terminate()`, which immediately destroys the connection,
  // instead of `WebSocket#close()`, which waits for the close timer.
  // Delay should be equal to the interval at which your server
  // sends out pings plus a conservative assumption of the latency.
  this.pingTimeout = setTimeout(() => {
    this.terminate();
  }, 30000 + 1000);
}
