import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-addlanguages',
    templateUrl: './addlanguages.component.html',
    styleUrls: ['./addlanguages.component.scss']
})
export class AddLanguagesComponent implements OnInit, OnDestroy {


    submittedComp: boolean = false;
    languagesform: FormGroup;
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: number = 0;
    public dataSource: any;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private service: SharedService<any>,
    ) { }

    ngOnInit(): void {
        this.languagesform = this.formBuilder.group({
            Id: ['', null],
            Language: ['', [Validators.required]],
        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                this.id = Number(params.get('id'));
            }
        );

        if (this.id != 0) {
            this.boolIdVisible = false;
            this.GetLanguagesDataById(this.id);
            this.ActionBtn = "Update";

        }
        else {
            this.boolIdVisible = true;
            this.ActionBtn = "Save";
        }
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.languagesform.controls;
    }

    languageForm() {
        this.submittedComp = true;
        if (this.languagesform.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            if (this.id == 0) {
                const language = this.languagesform.getRawValue();
                this.service.Post('Languages/InsertLanguages', language).subscribe(
                    (res) => {

                        this.toastr.success(res);
                        this.router.navigate(['./language'])
                    },
                    (error) => {
                        this.toastr.error(error.error);
                    });
                this.submittedComp = false;
            }
            else {
                this.UpdateLanguages();
            }
        }
    }

    Close(){
        this.router.navigate(['./language'])
    }

    UpdateLanguages() {
        const language = this.languagesform.getRawValue();
        this.service.Put('Languages/UpdateLanguages', language).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['./language'])
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    GetLanguagesDataById(Id: number) {
        this.service.Get('Languages/GetLanguages?Id=' + Id, null).subscribe(data => {
            this.dataSource = data;
            this.languagesform.controls["Id"].setValue(this.dataSource[0].Id);
            this.languagesform.controls["Language"].setValue(this.dataSource[0].Language);

        })
    }

    ngOnDestroy(): void {

    }



}
