<div class="dashboard-area ptb-50">
  <div class="container">

    <div class="profile-content-inner">
      <h4>Enter Text </h4>
    </div>
    <form [formGroup]="genralForm">
      <div class="row">
        <div class="form-group">
          <textarea class="form-control" formControlName="Message" placeholder="Enter Message"
          [ngClass]="{ 'is-invalid': f.Message.errors }" ></textarea>
         <div *ngIf="f.Message.errors" class="invalid-feedback">
          <div *ngIf="f.Message.errors.required">Message is required</div>
      </div>
        </div>

        <button class="Actionbutton btn" (click)="onConfirm()">
          Send Message
        </button>

        <button class="Cancelbutton btn" (click)="onCancel()">
          Cancel
        </button>
        
      </div>
    </form>
  </div>
</div>
