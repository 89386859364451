<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>My Resume</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>My Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Resume -->
<div class="job-filter-area pt-50">
    <h4 style="text-align:center ;">ShortList Candidate</h4>
    <div class="container" style="height:61px;">
        <form [formGroup]="userForm">
            <div class="row">
                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Job For : </label>
                        <input type="text"  class="form-control form-select" matInput [formControl]="formControlObj" 
                        [matAutocomplete]="autodec">
                       <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnForcmp"
                       (optionSelected)="onchangeCompany($event.option.value.Id)">
                         <mat-option *ngFor="let comp of filtercmp | async" [value]="comp">
                            {{comp.ClientName}}
                         </mat-option>
                       </mat-autocomplete>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>JobRole : </label>
                        <select class="form-control form-select" (change)="onchangeJobRole($event.target.value)"
                            formControlName="JobRoleId"
                            [ngClass]="{ 'is-invalid': submittedComp && fcomp.JobRoleId.errors }">
                            <option *ngFor="let JobRole of (allJobRole | async)" [value]="JobRole.JobRoleId">
                                {{JobRole.JobRole}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submittedComp && fcomp.JobRoleId.errors">
                            <div *ngIf="fcomp.JobRoleId.errors.required">
                                JobRole is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2">
                    <div class="form-group">
                        <label>Job Reference : </label>
                        <select class="form-control form-select" (change)="onchangeJobNumber($event.target.value)"
                            formControlName="JobNumber"
                            [ngClass]="{ 'is-invalid': submittedComp && fcomp.JobNumber.errors }">
                            <option value="" disabled selected>Choose any one Job Reference</option>
                            <option *ngFor="let JobNumber of (allJobNumber | async)" [value]="JobNumber.Id">
                                {{JobNumber.JobNumber}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submittedComp && fcomp.JobNumber.errors">
                            <div *ngIf="fcomp.JobNumber.errors.required">
                                Job Reference is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2" *ngIf="Global.isOwner">
                    <div class="form-group">
                        <label>Recruiter : </label>
                        <select class="form-control form-select" (change)="onchangeRecruiter($event.target.value)"
                            formControlName="RecruiterId"
                            [ngClass]="{ 'is-invalid': submittedComp && fcomp.RecruiterId.errors }">
                            <option value="" disabled selected>Choose any one Recruiter</option>
                            <option *ngFor="let recruiter of (allrecruiter | async)" [value]="recruiter.Id">
                                {{recruiter.FirstName}} {{recruiter.LastName}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submittedComp && fcomp.RecruiterId.errors">
                            <div *ngIf="fcomp.RecruiterId.errors.required">
                                Recruiter is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2">
                   
                    <button type="submit" class="cmn-btn" (click)="shortListCandidate()">ShortList <i
                            class='bx bx-plus'></i></button>
                </div>

            </div>
        </form>
    </div>
</div>


<mat-tab-group (selectedTabChange)="refreshListofCandidateStatus()">
    <mat-tab label="Summary">
        <div class="person-details-area resume-area ptb-100">
            <div class="container">
                <div class="row">
                    <div id="noScreen" class=" col-lg-4 col-md-12">
                        <div class="widget-area">
                            <div class="resume-profile" *ngIf="getresumeData">
                                <!-- <img [src]="getresumeData.Photo" alt="Dashboard" /> -->
                                <h2>{{getresumeData.FirstName}} {{getresumeData.LastName}}</h2>
                                <span>{{getresumeData.JobRole}}</span>
                            </div>
                            <div class="information widget-item" *ngIf="getresumeData">
                                <h3>Overview</h3>
                                <ul>

                                    <li *ngIf="Global.isOwner || Global.isUser">
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Contact:</h4>
                                        <span>{{getresumeData.MobileNo}}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Gender:</h4>
                                        <span>{{ getresumeData.GenderDesc }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Date Of Birth:</h4>
                                        <span>{{getresumeData.DateOfBirth | date:'dd-MM-yyyy'}}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Marital Status:</h4>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Nationality:</h4>
                                        <span>{{ getresumeData.Nationality }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Differently Abled:</h4>
                                        <span>{{getresumeData.DifferentlyAbled ? 'Yes' : 'No'}}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Location:</h4>
                                        <span>{{getresumeData.Address1}} ,{{getresumeData.Address2}}
                                            {{getresumeData.CityNameOfEmployeeInfo}},
                                            {{getresumeData.StateName}}, {{getresumeData.CountryName}} -
                                            {{getresumeData.PinCode}}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details"/>
                                        <h4>Country:</h4>
                                        <span>{{ getresumeData.CountryName }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details"/>
                                        <h4>State:</h4>
                                        <span>{{ getresumeData.StateName }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details"/>
                                        <h4>City:</h4>
                                        <span>{{ getresumeData.CityNameOfEmployeeInfo }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details"/>
                                        <h4>Expected Salary:</h4>
                                        <span>{{ getresumeData.ExpectedSalary }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12">

                        <div class="details-item">

                            <h3>Experience </h3>
                            <div class="work bottom-item" *ngFor="let getExperienceData of datasourceExp ">
                                <div class="row">


                                    <div class="col-11" *ngIf="getExperienceData">

                                        <ul>
                                            <li>
                                                {{ getExperienceData.JobRole }}

                                            </li>
                                        </ul>

                                        <li>
                                            <strong>Company Name:</strong>
                                            {{ getExperienceData.CompanyName }}

                                        </li>

                                        <li>
                                            <strong>Employment Type:</strong>
                                            {{ getExperienceData.EmployeementTypeName }}

                                        </li>

                                        <li>
                                            <strong>Work Location:</strong>
                                            {{ getExperienceData.WorkLocation }}

                                        </li>

                                        <li>
                                            <strong>Current Job:</strong>
                                            {{getExperienceData.IsCurrentJob ? 'Yes' : 'No'}}

                                        </li>

                                        <li>
                                            <strong>Start Date :</strong>
                                            {{getExperienceData.FromDate | date:'dd-MM-yyyy' }}
                                        </li>

                                        <li>
                                            <strong>End Date :</strong>
                                            {{getExperienceData.ToDate | date:'dd-MM-yyyy' }}
                                        </li>

                                        <li>
                                            <strong>Shift Job :</strong>
                                            {{getExperienceData.IsShiftJob ? 'Yes' : 'No'}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">

                            <h3>Education </h3>
                            <div class="work bottom-item" *ngFor="let getEducationData of datasourceEdu ">
                                <div class="row">


                                    <div class="col-11" *ngIf="getEducationData">

                                        <ul>
                                            <li>
                                                {{ getEducationData.Qualification }}
                                            </li>
                                        </ul>

                                        <li>
                                            <strong>School Or University:</strong>
                                            {{ getEducationData.SchoolOrUniversity }}

                                        </li>

                                        <li>
                                            <strong>Specialization:</strong>
                                            {{ getEducationData.SpecializationDescription }}
                                        </li>

                                        <li>
                                            <strong>Grade Or Percentage:</strong>
                                            {{ getEducationData.GradeOrPercentage }}
                                        </li>

                                        <li>
                                            <strong>Education Type:</strong>
                                            {{ getEducationData.EducationTypeName }}
                                        </li>

                                        <li>
                                            <strong>Passout Date:</strong>
                                            {{getEducationData.FromDateOfEducation | date:'dd-MM-yyyy'}}
                                        </li>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Achievements</h3>
                            <div class="work bottom-item" *ngFor="let getAchievementsData of datasourceAchiv ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getAchievementsData">
                                        <li>
                                            <strong>Description:</strong>
                                            {{ getAchievementsData.DescriptionOfAchievments}}

                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">

                            <h3>Certification</h3>
                            <div class="work bottom-item" *ngFor="let getCertificateData of datasourceCerty">
                                <div class="row">


                                    <div class="col-11" *ngIf="getCertificateData">

                                        <ul>
                                            <li>
                                                {{ getCertificateData.CertificationName }}

                                            </li>
                                        </ul>

                                        <li>
                                            <strong>Description:</strong>
                                            {{ getCertificateData.Description }}

                                        </li>

                                        <li>
                                            <strong>Date Of Issue:</strong>
                                            {{getCertificateData.DateOfIssue | date:'dd-MM-yyyy'}}

                                        </li>

                                        <li>
                                            <strong> Date Of Validity:</strong>
                                            {{getCertificateData.DateOfValidity | date:'dd-MM-yyyy'}}
                                        </li>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">

                            <h3>Skills</h3>
                            <div class="work bottom-item" *ngFor="let getSkillData of datasourceSkill ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getSkillData">
                                        <li>
                                            {{getSkillData.Skills}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Languages</h3>
                            <div class="work bottom-item" *ngFor="let getLanguagesData of datasourceLanguages  ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getLanguagesData">
                                        <li>
                                            {{getLanguagesData.Language}}
                                        </li>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Preferred Location</h3>
                            <div class="work bottom-item" *ngFor="let getLocationsData of datasourceLocation ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getLocationsData">
                                        <li>
                                            {{getLocationsData.CityName}}
                                        </li>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Jobs">
        <div>
        </div>

        <div class="card mat-elevation-z6 m-3 w-auto">
            <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                <!-- Position Column -->
                <ng-container matColumnDef="JobRole">
                    <th mat-header-cell *matHeaderCellDef class="M1"> JobRole </th>
                    <td mat-cell *matCellDef="let element"> {{element.JobRole}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="CandidateStatus">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Candidate Status </th>
                    <td mat-cell *matCellDef="let element" (click)="resume(element)"> {{element.CandidateStatusName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="CompanyName">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Company Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.JobCompanyName}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 25, 100]"
                aria-label="Select page" class="paginator">
            </mat-paginator>

        </div>
    </mat-tab>
    <mat-tab label="Resume">
        <div id="outerContainer">
            <object [data]="safeUrl" type="application/pdf" width="100%" height="600px"></object>
            <!-- <iframe [src]="safeUrl" width="100%" height="600px">  </iframe> -->     
          </div>  
    </mat-tab>

</mat-tab-group>