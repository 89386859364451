import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared.service';
import { AuthService } from 'src/app/auth.service';
import { GlobalService } from 'src/app/global.service';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { JobRole, UserType } from 'src/app/interface';
import { InputDialogComponent } from '../input -dialog/input-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { JoiningDateComponent } from './JoiningDate/add-joiningdate.component';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';


export class ctcinfo {
  candidateId: number;
  ctc: number;
  currencyId: number;
  PayRoll: number;
  PostedJobId: number;
  JoiningDate: string
}


@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})

export class JobDetailsComponent implements OnInit, OnDestroy {
  allCandidateStatus: Observable<any>;
  datasource1: MatTableDataSource<any>;
  obs: Observable<any>;
  public openMenu: boolean = false;
  isOver = false;
  cms: number[] = [];
  jobPostId: number = 0;
  cardCandidateStatus: number = 0;
  CandidateId: number = 0;
  shortlistRecruiterId: number = 0;
  usertype: number;
  userForm: FormGroup;
 
  candidateId: number;
  recruiteId: number;
  
  submittedComp: boolean = false;
  dataFilter: number[] = [];
  allCandidate: any[];
  filtercandidate?: Observable<any[]>;
  formControlObj = new FormControl();

  allrecruiter: any[];
  filterrecruiter: Observable<any[]>;
  formControlrecruiter = new FormControl();

  statusbtn : boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private Service: SharedService<any>,
    private toastr: ToastrService,
    private auth: AuthService,
    public Global: GlobalService,
    public formatter: NgbDateParserFormatter,
    private dialog: MatDialog,
    private router: Router) {
  }

  jobrole: any
  jobpostId: number
  datasource: any;
  shortlistbtn: boolean;
  SearchJobRole: string ;
  SearchLocation: string ;
  Orderby : string

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      CandidateId: [null, Validators.nullValidator],
      RecruiterId: [null, Validators.nullValidator],
    });

    this.Service.Get('GetListValue?Id=12', null).subscribe(data => {
      this.allCandidateStatus = data;
    });
    this.getjobdetails();
    // this.allrecruiter = this.Service.Get('Recruiter/GetActiveRecruiterData', null);
    this.Service.Get('Recruiter/GetActiveRecruiterData', null).subscribe(data => {
      if (data != null) {
        this.allrecruiter = data;
        this.filterrecruiter = this.formControlrecruiter.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.FirstName),
          map(FirstName => FirstName ? this._filterrecruiter(FirstName) : this.allrecruiter.slice()),
        );
      }
    })

    this.usertype = this.Global.GetUserType();
    if (this.Global.GetUserType() == UserType.Recruiter) 
    {
      this.shortlistbtn = true
    }
    else 
    {
      this.shortlistbtn = false;
    }
  }

  get fcomp(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  getjobdetails() {
    this.Service.Get('Job/GetJobPostData?Id=' + this.auth.getJobPostId(), null).subscribe(data => {
      this.datasource = data;
      this.jobrole = this.datasource[0].JobRoleId;
      this.jobpostId = this.datasource[0].Id;
      this.GetCandidate();

      if(this.datasource[0]. Jobstatus == 2)
      {
        this.statusbtn  = true
        this.shortlistbtn = true;
      }
      else
      {
        this.statusbtn = false;
      }
    })
  }

  ApplyNowClick(jobpost: any) {
    this.Service.Post('AppliedJob/InsertAppliedJob?jobpostId=' + jobpost, null).subscribe(
      (res) => {
        this.toastr.success(res);
      })
  }

  GetCandidate() {
    // this.allCandidate = this.Service.Get('Job/GetCandidateDataByJobPostRole?jobroleId=' + this.jobrole, null);
    this.Service.Get('Job/GetCandidateDataByJobPostRole?jobroleId=' + this.jobrole, null).subscribe(data => {
      if (data != null) {
        this.allCandidate = data;
        this.filtercandidate = this.formControlObj.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.CandidateName),
          map(CandidateName => CandidateName ? this._filtercandidate(CandidateName) : this.allCandidate.slice()),
        );
      }
    })
  }

  private _filtercandidate(CandidateName: string): any[] {
    const filterValue = CandidateName.toLowerCase();
    return this.allCandidate.filter(
      option => option.CandidateName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForcandidate(value: any) {
    return value ? value.CandidateName : undefined;
  }


  private _filterrecruiter(FirstName: string): any[] {
    const filterValue = FirstName.toLowerCase();
    return this.allrecruiter.filter(
      option => option.FirstName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForrecruiter(value: any) {
    return value ? value.FirstName : undefined;
  }
  getShortListedCandidatebyId() {
    this.datasource1 = null;
    this.Service.Get('ShortListCandidate/GetShortListCandidatebypostedjob?jobRoleId=' + this.Global.getJobroleId() + '&jobpostId=' + this.auth.getJobPostId(), null).subscribe(data => {
      this.datasource1 = data;
    })
  }

  ClickCard(data: any) {
    this.jobPostId = data.JobPostId;
    this.cardCandidateStatus = data.CandidateStatus;
    this.CandidateId = data.CandidateId;
    this.shortlistRecruiterId = data.RecruiterId;
    
  }

  clickMenu() {
    this.openMenu = !this.openMenu;
  }

  viewResume(row: any) {
     this.router.navigate(['viewresume', row.UserId]);
  }

  candidatestatuschg(statusid: any) {
    this.cms = [];
    this.cms.push(this.jobPostId);
    this.cms.push(statusid);
    if (statusid == 7) {
      var ctc = new ctcinfo();

      ctc.candidateId = this.CandidateId;
      ctc.PostedJobId = this.jobPostId;
      const dialogRef = this.dialog.open(InputDialogComponent, {
        maxWidth: "450px",
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == undefined) {
          this.toastr.error('Enter the data then status change');
        }
        else if (dialogResult.CTC > 0) {
          ctc.ctc = dialogResult.CTC;
          ctc.currencyId = dialogResult.CurrencyId;
          ctc.PayRoll = dialogResult.PayRoll;

          this.Service.Put('ShortListCandidate/Updatectc', ctc).subscribe(
            (res) => {
              this.toastr.success(res);
              this.cms.push(this.CandidateId);
              if(this.shortlistRecruiterId == null){
                this.cms.push(0);
              }
              else{
              this.cms.push(this.shortlistRecruiterId);
              }
              this.Service.Put('ShortListCandidate/UpdateCandidateStatus', this.cms).subscribe(data => {
                this.getShortListedCandidatebyId();
                this.cms = [];
                this.clickMenu();
                this.jobPostId = 0;
                this.cardCandidateStatus = 0;
                this.CandidateId = 0;
              });
            },
            (error) => {
              this.toastr.error(error.error);
            });
        }
      })
    }
    else if (statusid == 6) {

      var joindate = new ctcinfo();
      joindate.candidateId = this.CandidateId;
      joindate.PostedJobId = this.jobPostId;
      const dialogRef = this.dialog.open(JoiningDateComponent, {
        width: "400px",
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == undefined) {
          this.toastr.error('Enter the data then status change');
        }
        else if (dialogResult.JoiningDate) {
          joindate.JoiningDate = dialogResult.JoiningDate;

          this.Service.Put('ShortListCandidate/UpdateCandidateJoinDate?joingdate=' + joindate.JoiningDate + '&postjobId=' + joindate.PostedJobId + '&candidateId=' + joindate.candidateId, null).subscribe(
            (res) => {
              this.cms.push(this.CandidateId);
              if(this.shortlistRecruiterId == null){
                this.cms.push(0);
              }
              else{
              this.cms.push(this.shortlistRecruiterId);
              }
              this.toastr.success(res); this.Service.Put('ShortListCandidate/UpdateCandidateStatus', this.cms).subscribe(data => {
                this.getShortListedCandidatebyId();
                this.cms = [];
                this.clickMenu();
                this.jobPostId = 0;
                this.cardCandidateStatus = 0;
                this.CandidateId = 0;
              })
            },
            (error) => {
              this.toastr.error(error.error);
            });
        }

      })
    }
    else {
      this.cms.push(this.CandidateId);
      if(this.shortlistRecruiterId == null){
        this.cms.push(0);
      }
      else{
      this.cms.push(this.shortlistRecruiterId);
      }
      this.Service.Put('ShortListCandidate/UpdateCandidateStatus', this.cms).subscribe(data => {
        this.getShortListedCandidatebyId();
        this.cms = [];
        this.clickMenu();
        this.jobPostId = 0;
        this.cardCandidateStatus = 0;
        this.CandidateId = 0;
      })
    }
       this.getjobdetails();
  }


  onchangeCandidate(CandidateId) {
    this.candidateId = CandidateId
  }

  onchangeRecruiter(RecruiterId) {
    this.recruiteId = RecruiterId;
  }

  shortListCandidate() {
    this.submittedComp = true;
    if (this.userForm.invalid) {
      this.toastr.error("this data is not valid");
    }
    else {
      this.dataFilter = [];

      this.dataFilter.push(this.jobpostId);
      this.dataFilter.push(this.candidateId);
      this.dataFilter.push(this.Global.getuserId());
      if (this.Global.GetUserType() == UserType.Recruiter) {
        this.dataFilter.push(this.Global.getRecruiterId());
      }
      else {
        this.dataFilter.push(this.recruiteId);
      }
      //this.dataSource = null;
      this.Service.Post('ShortListCandidate/CandidateShortlist', this.dataFilter).subscribe(
        (res) => {
          this.toastr.success(res);
          this.userForm.reset();
          this.getShortListedCandidatebyId();
        },
        (error) => {
          this.toastr.error(error.error);
          this.userForm.reset();
        }
      );
    }
  }

  ngOnDestroy(): void {

  }

}