import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";

import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-ViewCandidate',
    templateUrl: './ViewCandidate.component.html',
    styleUrls: ['./ViewCandidate.component.scss']
})
export class ViewCandidateComponent implements OnInit, OnDestroy {

    candidateInfo = [];
    userId: number ;
    name: string;
    designation: string;
   
    datasource:any;
    Id:number;
    public placeholder: string = '';

    constructor(
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        
     }

    ngOnInit(): void {
        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                
                this.Id = Number(params.get('id'));
            }
        );
     
        this.getUser();
    }

    getUser() {
        this.Service.Get('CandidateInfo/GetCandidateData?Id=' + this.Id, null).subscribe(
            (res) => {
                this.datasource = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            });
    }


    Close() {
        this.router.navigate(['/candidatetable']);
    }

    ngOnDestroy(): void {

    }
}
