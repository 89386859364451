<!-- Register -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <!-- <div class="col-lg-4 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/png/pinklogo.png" alt="User">
                </div>
            </div> -->

            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="user-img">
                        <img src="assets/png/bluelogo.png" alt="User">
                    </div>
                    <div class="top">
                        <h2>Company Sign Up</h2>

                        <form [formGroup]="companyForm">

                            <div class="form-group">
                                <label>Company Name:</label>
                                <input type="text" class="form-control" formControlName="CompanyName"
                                    placeholder="e.g. Acme Corporation."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CompanyName.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CompanyName.errors">
                                    <div *ngIf="fcomp.CompanyName.errors.required">
                                        Company Name is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Email:</label>
                                <input type="text" class="form-control" formControlName="CompanyEmail"
                                    placeholder="xyz@gmail.com"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CompanyEmail.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CompanyEmail.errors">
                                    <div *ngIf="fcomp.CompanyEmail.errors.required">
                                        Email is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Company Description:</label>
                                <textarea type="text" class="form-control" formControlName="Description"
                                    placeholder="Enter Description"></textarea>
                            </div>

                            <div class="form-group">
                                <label>Industry:</label>
                                <select class="form-control form-select" formControlName="IndustryTypeId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.IndustryTypeId.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Industry of (allIndustry | async)" [value]="Industry.Id">
                                        {{Industry.IndustryType}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.IndustryTypeId.errors">
                                    <div *ngIf="fcomp.IndustryTypeId.errors.required">
                                        industry is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Address1:</label>
                                <textarea type="text" class="form-control" formControlName="Address1"
                                    placeholder="Company Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Address1.errors }">
                                    </textarea>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Address1.errors">
                                    <div *ngIf="fcomp.Address1.errors.required">
                                        Company Address is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Address2:</label>
                                <textarea type="text" class="form-control" formControlName="Address2"
                                    placeholder="Company Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Address2.errors }">
                                    </textarea>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Address2.errors">
                                    <div *ngIf="fcomp.Address2.errors.required">
                                        Company Address is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Country:</label>
                                <select class="form-control form-select" formControlName="CountryId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryId.errors }"
                                    (change)="onchangeCountry($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Countries of (allCountries | async)" [value]="Countries.Id">
                                        {{Countries.CountryName}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CountryId.errors">
                                    <div *ngIf="fcomp.CountryId.errors.required">
                                        Country is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>State:</label>
                                <select class="form-control form-select" formControlName="StateId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.StateId.errors }"
                                    (change)="onchangeCity($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let states of (allStates | async)" [value]="states.Id">
                                        {{states.StateName}}
                                    </option>

                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.StateId.errors">
                                    <div *ngIf="fcomp.StateId.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>City:</label>
                                <select class="form-control form-select" formControlName="CityId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CityId.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let cities of (allCities | async)" [value]="cities.Id">
                                        {{cities.CityName}}
                                    </option>

                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CityId.errors">
                                    <div *ngIf="fcomp.CityId.errors.required">
                                        City is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Pincode:</label>
                                <input class="form-control" placeholder="Enter Pincode" formControlName="PinCode" maxlength="8"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.PinCode.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.PinCode.errors">
                                    <div *ngIf="fcomp.PinCode.errors.required">Pincode is required</div>
                                    <div *ngIf="fcomp.PinCode.errors.PinCode">Pincode is invalid</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Website:</label>
                                <input class="form-control" placeholder="Enter website" formControlName="WebSite" maxlength="100">
                            </div>

                            <div class="form-group">
                                <label>Linkedin Page:</label>
                                <input class="form-control" placeholder="Enter LinkedInPage" formControlName="LinkedInPage">
                            </div>

                            <div class="form-group" style="text-align:center ;"> Contect Person </div>

                          <div class="form-group">
                            <label>First Name:</label>
                            <input type="email" class="form-control" placeholder="Enter First Name"
                                formControlName="firstname"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.firstname.errors }">
                            <div *ngIf="submittedComp && fcomp.firstname.errors" class="invalid-feedback">
                                <div *ngIf="fcomp.firstname.errors.required">Email is required</div>
                                <div *ngIf="fcomp.firstname.errors.email">Email is invalid</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Last Name:</label>
                            <input type="text" class="form-control" formControlName="lastname"
                                placeholder="Enter Last Name"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.lastname.errors }">
                            <div class="invalid-feedback" *ngIf="submittedComp && fcomp.lastname.errors">
                                <div *ngIf="fcomp.lastname.errors.required">
                                    Contact Person is required
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>ISD Code</label>
                                <select class="form-control form-select" formControlName="CountryISDCode" 
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryISDCode.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let countries of (allCountry | async)" [value]="countries.ISDCode" 
                                     >
                                        {{countries.ISDCode}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CountryISDCode.errors">
                                    <div *ngIf="fcomp.CountryISDCode.errors.required">
                                        ISD Codeis required
                                    </div>
                                </div>
                            </div>
                          
                            </div>
                            <div class="col-lg-9 col-md-12">
                                <div class="form-group">
                                    <label>Mobile Number:</label>
                                    <input type="text" class="form-control" formControlName="MobileNo"
                                        placeholder="Enter Mobile Number" maxlength="15"
                                        [ngClass]="{ 'is-invalid': submittedComp && fcomp.MobileNo.errors }">
                                    <div class="invalid-feedback" *ngIf="submittedComp && fcomp.MobileNo.errors">
                                        <div *ngIf="fcomp.MobileNo.errors.required">
                                            Company URL is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div class="form-group">
                                <label>Logo</label>
                                <input (change)="Uploadcompanylogo($event)" type="file" class="input">
                            </div>

                            <button (click)="companyInfoForm()" type="submit" class="btn">Sign Up Here</button>
                        </form>
                        <div class="create-account">
                            <span class="account-direction">Already have an account? <a
                                    style="font-weight: 600; color: #fe4a55;" routerLink="/login">Log
                                    in</a></span>
                        </div>
                    </div>

                    <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-google-plus'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a><i class="bx bx-home-alt" routerLink="/login"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

           

            <!-- <div class="col-lg-4 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Register -->