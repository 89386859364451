import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { CollegeInformation } from 'src/app/interface';
import userToken from 'src/app/components/config/userToken';

@Component({
    selector: 'app-ownerhome',
    templateUrl: './ownerhome.component.html',
    styleUrls: ['./ownerhome.component.scss']
})

export class OwnerHomeComponent implements OnInit, OnDestroy {
    

    getcompanyData: CollegeInformation;
    userId: number = userToken.id;
    constructor(
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService
    ) { }

    ngOnInit(): void {
        this.getUser();
    }

    getUser() {

        this.userId = this.Global.getuserId();
        this.Service.Get('Company/GetCompanyData?Id=' + this.userId , null).subscribe(
            (res) => {
                this.getcompanyData = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    ngOnDestroy(): void {

    }
}
