<div class="dashboard-area ptb-50">
    <div class="container">

        <div class="profile-content-inner">
            <h4>Add Joing Date</h4>
        </div>
        <form [formGroup]="inputForm">

            <div class="row">
                <div class="col-lg-1 col-md-12"></div>
                <div class="col-lg-10 col-md-12">
                    <div class="form-group">
                        <label>Joining date</label>
                        <input type="date" class="form-control" placeholder="01/01/1995" formControlName="JoiningDate"
                            [ngClass]="{ 'is-invalid': submittedJob && fjob.JoiningDate.errors }">
                        <div *ngIf="submittedJob && fjob.JoiningDate.errors" class="invalid-feedback">
                            <div *ngIf="fjob.JoiningDate.errors.required">Joining Date is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <button class="Actionbutton btn" (click)="onConfirm()">
            {{btnMessage}}
        </button>

        <button class="Cancelbutton btn" (click)="onCancel()">
          Cancel
        </button>
    </div>

</div>