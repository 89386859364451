import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';

import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';



@Component({
    selector: 'app-addcurrency',
    templateUrl: './addcurrency.component.html',
    styleUrls: ['./addcurrency.component.scss']
})
export class AddCurrencyComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    currencyform: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: number = 0;
    public dataSource: any;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private service: SharedService<any>,
    ) { }

    ngOnInit(): void {
        this.currencyform = this.formBuilder.group({
            Id: ['', null],
            CurrencyCode: [null, Validators.required],
            CurrencyName: ["", Validators.required],
            CurrencySymbol: ["", null],
        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                this.id = Number(params.get('id'));
            }
        );

        if (this.id != 0) {
            this.boolIdVisible = false;
            
            this.GetCurrencyDataById(this.id);
            this.ActionBtn = "Update";
           
        }
        else {
            this.boolIdVisible = true;
            this.ActionBtn = "Save";  
        }
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.currencyform.controls;
    }


    CurrencyForm() {
        this.submittedComp = true;
        if (this.currencyform.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            if (this.id == 0) {
                const currency = this.currencyform.getRawValue();
                this.service.Post('Currency/InsertCurrency', currency).subscribe(
                    (res) => {

                        this.toastr.success(res);
                        this.router.navigate(['./currency'])
                    },
                    (error) => {
                        this.toastr.error(error.error);
                    });
                this.submittedComp = false;
            }
            else {
                this.UpdateCurrency();
            }
        }
    }

    Close(){
        this.router.navigate(['./currency'])
    }

    UpdateCurrency() {
        const country = this.currencyform.getRawValue();
        this.service.Put('Currency/UpdateCurrency', country).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['./currency'])
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    GetCurrencyDataById(Id: number) {
        this.service.Get('Currency/GetCurrency?Id=' + Id, null).subscribe(data => {
            this.dataSource = data;
            this.currencyform.controls["Id"].setValue(this.dataSource[0].Id);
            this.currencyform.controls["CurrencyCode"].setValue(this.dataSource[0].CurrencyCode);
            this.currencyform.controls["CurrencyName"].setValue(this.dataSource[0].CurrencyName);
            this.currencyform.controls["CurrencySymbol"].setValue(this.dataSource[0].CurrencySymbol);
        })
      }


    ngOnDestroy(): void {

    }

    
}
