<div *ngIf="Blacklist">
<h1 mat-dialog-title class="h5">
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p class="h6 m">{{message}}</p>
  </div>


  
  <div mat-dialog-actions>
      <button mat-raised-button  (click)="onConfirm()" class="Actionbutton">Yes</button>
      
    <button mat-button  (click)="onDismiss()" class="Actionbutton">No</button>
  </div>
</div>

  <div class="dashboard-area ptb-50" *ngIf="remark">
    <div class="container">
  
      <div class="profile-content-inner">
        <h4>Enter Remark </h4>
      </div>
      <form [formGroup]="BlacklistForm">
        <div class="row">
          <div class="form-group">
            <textarea class="form-control" formControlName="Remarks" placeholder="Enter Remark"
            [ngClass]="{ 'is-invalid': f.Remarks.errors }" ></textarea>
           <div *ngIf="f.Remarks.errors" class="invalid-feedback">
            <div *ngIf="f.Remarks.errors.required">Remark is required</div>
        </div>
          </div>
  
          <button class="Actionbutton btn" (click)="Save()" style="margin-left: 178px; margin-top: 20px;">
           Save
          </button>
  
         
        </div>
      </form>
    </div>
  </div>
  