<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Create Candidate</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                            <!-- <a routerLink="/post-a-job">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Candidate</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  -->
<div class="dashboard-area">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="candidateform">
                <div class="profile-content-inner">
                    <!-- <h2>Candidate</h2> -->
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>First Name <label class="star">*</label></label>
                                <input type="text" id="FirstName" (keypress)="keyPressAlpha($event)" class="form-control" placeholder="First Name"
                                    formControlName="FirstName" maxlength="50"
                                    [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                                <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                    <div *ngIf="f.FirstName.errors.required">First Name is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Last Name <label class="star">*</label></label>
                                <input type="text" id="LastName" (keypress)="keyPressAlpha($event)" class="form-control" placeholder="Last Name"
                                    formControlName="LastName"  maxlength="50"
                                    [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
                                <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                    <div *ngIf="f.LastName.errors.required">Last Name is required</div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-12">

                            <div class="form-group">
                                <label>Email <label class="star">*</label></label>
                                <input type="email" id="Email" class="form-control" placeholder="xyz@gmail.com"
                                    formControlName="Email" [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">
                                <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                                    <div *ngIf="f.Email.errors.required">Email is required</div>
                                    <div *ngIf="f.Email.errors.email">Email is invalid</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>ISD Code <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="CountryISDCode"
                                    [ngClass]="{ 'is-invalid': submitted && f.CountryISDCode.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let countries of allCountries" [value]="countries.ISDCode">
                                        {{countries.ISDCode}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submitted && f.CountryISDCode.errors">
                                    <div *ngIf="f.CountryISDCode.errors.required">
                                        ISD Code is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>Mobile Number <label class="star">*</label></label>
                                <input type="text" class="form-control" placeholder="XXXXX-XXXXX "
                                    formControlName="MobileNo" maxlength="10" (keypress)="keyPressAlphaNumeric($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }">
                                <div *ngIf="submitted && f.MobileNo.errors" class="invalid-feedback">
                                    <div *ngIf="f.MobileNo.errors.required">Mobile number is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label> Qualification <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formControlQuli"
                                    [matAutocomplete]="autoQuali"
                                    [ngClass]="{ 'is-invalid': submitted && f.QualificationId.errors }">
                                <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForQuli"
                                    (optionSelected)="onchangeQualification($event.option.value.Id)">
                                    <mat-option *ngFor="let Quli of filterQuli | async" [value]="Quli">
                                        {{Quli.Qualification}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.QualificationId.errors">
                                    <div *ngIf="f.QualificationId.errors.required">
                                        Qualification is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Academic Discipline <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formControldiscipline"
                                    [matAutocomplete]="autodec"
                                    [ngClass]="{ 'is-invalid': submitted && f.AcademicDisciplineId.errors }">
                                <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnFordiscipline"
                                    (optionSelected)="onchangeAcademicDiscipline($event.option.value.Id)">
                                    <mat-option *ngFor="let discipline of filterdiscipline | async"
                                        [value]="discipline">
                                        {{discipline.AcademicDescription}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.AcademicDisciplineId.errors">
                                    <div *ngIf="f.AcademicDisciplineId.errors.required">
                                        Academic Discipline is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Specialization <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formControlspecialization"
                                    [matAutocomplete]="autospec"
                                    [ngClass]="{ 'is-invalid': submitted && f.SpecializationId.errors }">
                                <mat-autocomplete #autospec="matAutocomplete" [displayWith]="displayFnForSpecialization"
                                    (optionSelected)="onchangeSpecialization($event.option.value.Id)">
                                    <mat-option *ngFor="let specialization of filterspecialization | async"
                                        [value]="specialization">
                                        {{specialization.SpecializationDescription}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.SpecializationId.errors">
                                    <div *ngIf="f.SpecializationId.errors.required">
                                        Specialization is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Job Role </label>
                                <input type="text" class="form-control form-select" [formControl]="formControlObj"
                                    [matAutocomplete]="auto" placeholder="Select Job Role">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                                    (optionSelected)="onchangejobrole($event.option.value.Id)" panelWidth="auto">
                                    <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                        {{jobrole.JobRole}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.JobRoleId.errors">
                                    <div *ngIf="f.JobRoleId.errors.required">
                                        Job Role is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Passout Year <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formControlYear"
                                    [matAutocomplete]="autoyear" placeholder="Select Job Role"
                                    [ngClass]="{ 'is-invalid': submitted && f.PassOutYear.errors }">
                                <mat-autocomplete #autoyear="matAutocomplete" [displayWith]="displayFnForYear"
                                    (optionSelected)="onchangeyear($event.option.value)">
                                    <mat-option *ngFor="let year of filterYear | async" [value]="year">
                                        {{year}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Available For Internship(Graduate Engineer Trainee) <label
                                        class="star">*</label></label>
                                <input class="form-check-input" type="radio" formControlName="IsGET"
                                    name="inlineRadioOptions" id="inlineRadio1" value="true" style="margin-left:2%"
                                    (click)="toggleTag()" [ngClass]="{ 'is-invalid': submitted && f.IsGET.errors }">
                                <label class="form-check-label" for="inlineRadio1">Yes</label>
                                <input class="form-check-input" type="radio" formControlName="IsGET"
                                    name="inlineRadioOptions" id="inlineRadio2" value="false"
                                    style="margin-left: 2%; margin-right:2%" (click)="toggleTag1()"
                                    [ngClass]="{ 'is-invalid': submitted && f.IsGET.errors }">
                                <label class="form-check-label" for="inlineRadio2">No</label>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.displayMe">
                            <div class="form-group" *ngIf="this.displayMe">
                                <label>Internship Start Date</label>
                                <input type="date" class="form-control" placeholder="01/01/1995"
                                    formControlName="InternShipStartDate"
                                    [ngClass]="{ 'is-invalid': submitted && f.InternShipStartDate.errors }">
                                <div *ngIf="submitted && f.InternShipStartDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.InternShipStartDate.errors.required">Date of birtd is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.displayMe">
                            <div class="form-group">
                                <label>Internship End Date</label>
                                <input type="date" class="form-control" placeholder="01/01/1995"
                                    formControlName="InternShipEndDate"
                                    [ngClass]="{ 'is-invalid': submitted && f.InternShipEndDate.errors }">
                                <div *ngIf="submitted && f.InternShipEndDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.InternShipEndDate.errors.required">Date of birtd is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>Profile Picture</label>
                                <input (change)="Uploadcompanylogo($event)" type="file" class="input">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12" *ngIf="viewimg"> <img [src]="url" height="230"
                                style="width: 200px; margin-bottom: 10px;margin-left: 10px;"></div>

                        <button (click)="CandidateForm()" class="btn dashboard-btn">
                            Save Your Information
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- End Post A Job -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->