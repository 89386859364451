import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-edit-company',
    templateUrl: './edit-company.component.html',
    styleUrls: ['./edit-company.component.scss']
})
export class CompanyEditComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    companyForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$";
    fileType: string = '';
    base64textString: string[] = [];
    allCountry: Observable<any>;
    DataSource?: any;
Id : number
    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service:SharedService<any>,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.companyForm = this.formBuilder.group({
            Id: ["",null],
            CompanyName: [null, Validators.required],
            CompanyEmail: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            IndustryTypeId: ["", Validators.required],
            Address1: ["", [Validators.required, Validators.maxLength(150)]],
            Address2: ["", null],
            Description: ["", Validators.required],
            CountryId: ["", Validators.required],
            StateId: ["", Validators.required],
            CityId: ["", Validators.required],
            PinCode: ["", Validators.required],
            WebSite: ["", null],
            LinkedInPage: ["", null],
            firstname: [null, Validators.required],
            lastname: ["", Validators.required],
            MobileNo: ["", Validators.required],
            CompanyProfileName: ["", null],
            Logo:['',[Validators.nullValidator]],
            UserTypeId: [2],
            CountryISDCode: ['', Validators.nullValidator],

        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                
                this.Id = Number(params.get('id'));
            }
        );

        this.GetEmployeeDataById(this.Id);
        this.allIndustry = this.Service.Get('IndustryTypes/GetIndustryTypes?Id=0', null);
        this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
        this.allCountry = this.Service.Get('Country/GetCountryISDCode?Id=0', null);
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.companyForm.controls;
    }
    onchangeCountry(event) {
        this.allStates = this.Service.Get('State/GetStateByCountry?countryId=' + event, null);
    }
    onchangeCity(event) {
        this.allCities = this.Service.Get('City/GetCityDataByState?StateId=' + event, null);
    }

    GetEmployeeDataById(Id: any) {
        
        this.Service.Get('Company/GetCompanyDataforOwner?Id='+this.Id, null).subscribe(data => {
            this.DataSource = data;
            this.companyForm.controls["Id"].setValue(this.DataSource[0].Id);
            this.companyForm.controls["CompanyName"].setValue(this.DataSource[0].CompanyName);
            this.companyForm.controls["CompanyEmail"].setValue(this.DataSource[0].CompanyEmail);
            this.companyForm.controls["IndustryTypeId"].setValue(this.DataSource[0].IndustryTypeId);
            this.companyForm.controls["Address1"].setValue(this.DataSource[0].Address1);
            this.companyForm.controls["Address2"].setValue(this.DataSource[0].Address2);
            this.companyForm.controls["Description"].setValue(this.DataSource[0].Description);
            this.companyForm.controls["CountryId"].setValue(this.DataSource[0].CountryId);
            this.companyForm.controls["StateId"].setValue(this.DataSource[0].StateId);
            this.companyForm.controls["CityId"].setValue(this.DataSource[0].CityId);
            this.companyForm.controls["PinCode"].setValue(this.DataSource[0].PinCode);
            this.companyForm.controls["WebSite"].setValue(this.DataSource[0].WebSite);
            this.companyForm.controls["LinkedInPage"].setValue(this.DataSource[0].LinkedInPage);
            this.companyForm.controls["firstname"].setValue(this.DataSource[0].FirstName);

            this.companyForm.controls["lastname"].setValue(this.DataSource[0].LastName);
            this.companyForm.controls["MobileNo"].setValue(this.DataSource[0].MobileNo);
            // this.companyForm.controls["CompanyProfileName"].setValue(this.DataSource[0].CompanyProfileName);
            this.companyForm.controls["CountryISDCode"].setValue(this.DataSource[0].CountryISDCode);
            this.companyForm.controls["Logo"].setValue(this.DataSource[0].Logo);
          

            this.allIndustry = this.Service.Get('IndustryTypes/GetIndustryTypes?Id=0', null);
            this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
            this.allCountry = this.Service.Get('Country/GetCountryISDCode?Id=0', null);
            this.allStates = this.Service.Get('State/GetStateData?Id=0', null);
            this.allCities = this.Service.Get('City/GetCity?Id=0', null);
        })
    }




    companyInfoForm() {

        this.submittedComp = true;
        if (this.companyForm.invalid) {
            this.toastr.error("this data is not valid");
        }
        else {
            const company = this.companyForm.getRawValue();
            company.Logo = this.base64textString[0];
            //var UserName = this.companyForm.value.CompanyEmail;
            //this.companyForm.value.CompanyProfileName = this.companyForm.value.CompanyName;
           // company.CompanyProfileName = this.companyForm.value.CompanyName;
            this.Service.Put('Company/UpdateCompanyData', company).subscribe(
                (res) => {
                    this.toastr.success(res);
                 
                    // this.Service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
                    //     (res) => {
                    //         this.toastr.success(res);
                    //     },
                    //   )
                      this.router.navigate(['/company']);
                },
                (error) => {
                    //if (error.status == 401) this.router.navigate(['/login']);
                    this.toastr.error(error.error);
                });
            this.submittedComp = false;
        }
    }

    Uploadcompanylogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }

    ngOnDestroy(): void {
    }


}
