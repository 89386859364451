<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <!-- <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div> -->

            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Specialization</h2>

                        <form [formGroup]="Specializationform">

                            <div class="form-group">
                                <label>Qualification:</label>
                                <select class="form-control form-select" formControlName="QualificationId"
                                    (change)="onchangeQualification($event.target.value)"
                                    >
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Quli of (allQualification | async)" [value]="Quli.Id">
                                        {{Quli.Qualification}}
                                    </option>
                                </select>
                                
                            </div>



                            <div class="form-group">
                                <label>AcademicDiscipline</label>
                                <select class="form-control form-select" formControlName="AcademicDisplineId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.AcademicDisplineId.errors }">
                                    <option value="" disabled selected>Choose any one state</option>
                                    <option *ngFor="let ad of (allAD | async)" [value]="ad.Id">
                                        {{ad.AcademicDescription}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.AcademicDisplineId.errors">
                                    <div *ngIf="fcomp.AcademicDisplineId.errors.required">
                                        Academic Description is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Specialization Description</label>
                                <input class="form-control" formControlName="SpecializationDescription"
                                placeholder="Enter Academic Description"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.SpecializationDescription.errors }">
                            <div class="invalid-feedback" *ngIf="submittedComp && fcomp.SpecializationDescription.errors">
                                <div *ngIf="fcomp.SpecializationDescription.errors.required">
                                    Academic Description is required
                                </div>
                                </div>
                            </div>
                            <button (click)="SpecializationForm()" type="submit" class="btn Actionbutton">{{ActionBtn}}</button>
                            <button  (click)="Close()" type="close" class="btn Cancelbutton"> Close</button>
                        </form>
                        <!-- <div class="create-account">
                            <span class="account-direction">Create an account? <a style="font-weight: 600; color: #fe4a55;" routerLink="/register">Sign Up</a></span>
                        </div> -->

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->