import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-userhome',
    templateUrl: './userhome.component.html',
    styleUrls: ['./userhome.component.scss']
})

export class UserHomeComponent implements OnInit, OnDestroy{
    
    ngOnInit(): void {
        
    }

    ngOnDestroy(): void {
        
    }
}
