import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { JobRole, ShortlistedEmployee } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-shortlistedcandidateforowner',
    templateUrl: './shortlistedcandidateforowner.component.html',
    styleUrls: ['./shortlistedcandidateforowner.component.scss']
})
export class ShortListedCandidateForOwnerComponent implements OnInit, OnDestroy {


    constructor(@Inject(MAT_DIALOG_DATA) public editData: any,
    private service: SharedService<any>,
    private myGlobal: GlobalService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ShortListedCandidateForOwnerComponent>,
    private toastr: ToastrService,) { }

  addEditForm!: FormGroup;
  allJobRole: Observable<JobRole[]>;
  allCompanyName: Observable<any>;
  EmpId: number = 0;
  shortlistcandidate: ShortlistedEmployee;
  dataFilter: string[] = [];
  JobRole: number;
  datasource: any;
  companyId: number;
  submitted: boolean = false;


  ngOnInit(): void {
    this.addEditForm = this.formBuilder.group({
      JobRoleId: ['', [Validators.nullValidator]],
      JobRole: ['', [Validators.nullValidator]],
      JobCompanyNameId:['', [Validators.nullValidator]],
      JobCompanyName:['', [Validators.nullValidator]],
    })
    this.EmpId = this.editData;
    // this.allJobRole = this.service.Get('JobRole/GetJobRoleByUser?UserId=' + this.myGlobal.getuserId(), null);
    this.allCompanyName = this.service.Get('Company/GetCompanyUsingUsertype?UsertypeId=2', null);
    this.refreshListFilter();

  }

  
  get fjob(): { [key: string]: AbstractControl } {
    return this.addEditForm.controls;
}

  refreshListFilter() {
    this.datasource = null;
    this.service.Post('JobRole/GetFilterDataOfCandidate', this.dataFilter).subscribe(data => {
      this.datasource = new MatTableDataSource(data);
      this.toastr.success("Candidate Shortlisted Successfully");
    })
  }

  search() {
    this.dataFilter = [];
    this.dataFilter.push(this.JobRole.toString());
    this.dataFilter.push(this.myGlobal.getCandidateId().toString());
    this.dataFilter.push(this.myGlobal.getuserId().toString());
    this.dataFilter.push(this.companyId.toString());
    this.addEditForm.reset();
    this.dialogRef.close()
    this.refreshListFilter();
  }

  onchangeJobRole(event) {
    this.JobRole = event; 
  }
  company: string;
  onchangeCompany(event) { 
    this.companyId = event;
    this.allJobRole = this.service.Get('JobRole/GetJobRoleByUser?UserId=' + this.myGlobal.getuserId() + '&companyId=' + this.companyId, null);
  }
    

ngOnDestroy(): void {
    
}
}
