<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>IndustryType
                    </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>IndustryType
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-100">
    <div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <!-- <ul class="job-list-item align-items-center">
                    <li>
                        <a href="#">Results Found <span>(17)</span></a>
                        <a class="rss" routerLink="/"><i class='bx bx-rss'></i>RSS Feed</a>
                    </li>
                    <li>
                        <span class="sort">Sort By:</span>
                        <form>
                            <div class="form-group">
                                <select>
                                    <option>Title</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select>
                            </div>
                        </form>
                    </li>
                </ul> -->

                <div (click)="AddIndustrtype()"  style="margin-left: 21px; margin-top: -38px;  width: 115px;">
                    <a media="print" class="cmn-btn"> Add <i
                            class="bx bx-plus bx-sm bx-tada-hover"></i></a>
                </div>
                <input class="form-control search" (keyup)="applyFilter($event)" placeholder="Enter Text To Search" >

                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                
                      <ng-container matColumnDef="IndustryType">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                            Industry Type</th>
                        <td mat-cell *matCellDef="let element"> {{element.IndustryType}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="Description">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                            Description</th>
                        <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
                      </ng-container>

                      <ng-container matColumnDef="IsActive">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                            IsActive
                        </th>
                        <td mat-cell *matCellDef="let row">
                          <button mat-icon-button (click)="confirmDialog(row)" class="material-icons">
                            <i class="bx bx-toggle-left bx-tada-hover" [ngStyle]="{'color':row.IsActive== '1' ? 'DarkGreen':'red'}"></i>
                          </button>
                        </td>
                      </ng-container>
                
                        <!-- Edit -->
                        <ng-container matColumnDef="Edit">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                                Edit</th>
                                <td mat-cell  *matCellDef="let element" class="text-center"><i class="bx bx-edit bx-tada-hover" (click)="EditIndustrtype(element)"></i>
                                </td>
                          </ng-container>
                
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    </table>
                    <mat-paginator [length]="100" color="primary" [pageSize]="10" [pageSizeOptions]="[8, 10, 15,25]"
                    aria-label="Select page">
                </mat-paginator>
                  </div>
        </div>
    </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->