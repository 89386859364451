<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Create Client </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/employer/dashboard/:id/companies/:id">Companies list</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Create Client </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Create Company -->
<div class="dashboard-area">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="clientForm">
                <div class="profile-content-inner">
                    <!-- <h2>Company</h2> -->
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Client Name <label class="star">*</label></label>
                                <input type="text" class="form-control" formControlName="ClientName"
                                    placeholder="e.g. Acme Corporation."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.ClientName.errors }" maxlength="250">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.ClientName.errors">
                                    <div *ngIf="fcomp.ClientName.errors.required">
                                        Client Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-12">
                            <div class="form-group">
                                <label>Client Description <label class="star">*</label></label>
                                <textarea type="text" class="form-control" formControlName="Description"
                                    placeholder="Enter Description"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Description.errors }"></textarea>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Description.errors">
                                    <div *ngIf="fcomp.Description.errors.required">
                                        Description is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-4 col-md-12"></div> -->
                        
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address1 <label class="star">*</label></label>
                                <textarea type="text" class="form-control" formControlName="Address1"
                                    placeholder="Client Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Address1.errors }" maxlength="100">
                                    </textarea>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Address1.errors">
                                    <div *ngIf="fcomp.Address1.errors.required">
                                        Client Address is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address2</label>
                                <textarea type="text" class="form-control" formControlName="Address2"
                                    placeholder="Client Address." maxlength="100">
                                    </textarea>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Country<label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formCountries"
                                [matAutocomplete]="autocou" placeholder="Select Country" 
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryId.errors }">
                            <mat-autocomplete #autocou="matAutocomplete" [displayWith]="displayFnForCountry"
                                (optionSelected)="onchangeCountry($event.option.value.Id)">
                                <mat-option *ngFor="let Countries of filterCountries | async" [value]="Countries">
                                    {{Countries.CountryName}}
                                </mat-option>
                            </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CountryId.errors">
                                    <div *ngIf="fcomp.CountryId.errors.required">
                                        Country is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>State <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formStates"
                                [matAutocomplete]="autostate" placeholder="Select State"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.StateId.errors }">
                            <mat-autocomplete #autostate="matAutocomplete" [displayWith]="displayFnForState"
                                (optionSelected)="onchangeState($event.option.value.Id)">
                                <mat-option *ngFor="let states of filterStates | async" [value]="states">
                                    {{states.StateName}}
                                </mat-option>
                            </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.StateId.errors">
                                    <div *ngIf="fcomp.StateId.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>City <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formCities"
                                [matAutocomplete]="autocity" placeholder="Select State"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.CityId.errors }">
                            <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity"
                                (optionSelected)="onchangeCity($event.option.value.Id)">
                                <mat-option *ngFor="let cities of filterCities | async" [value]="cities">
                                    {{cities.CityName}}
                                </mat-option>
                            </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CityId.errors">
                                    <div *ngIf="fcomp.CityId.errors.required">
                                        City is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Pincode <label class="star">*</label></label>
                                <input class="form-control" placeholder="Enter Pincode" formControlName="PinCode"
                                    maxlength="8" [ngClass]="{ 'is-invalid': submittedComp && fcomp.PinCode.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.PinCode.errors">
                                    <div *ngIf="fcomp.PinCode.errors.required">Pincode is required</div>
                                    <div *ngIf="fcomp.PinCode.errors.PinCode">Pincode is invalid</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Website</label>
                                <input class="form-control" placeholder="Enter website" formControlName="WebSite"
                                    maxlength="100">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Linkedin Page</label>
                                <input class="form-control" placeholder="Enter LinkedInPage"
                                    formControlName="LinkedInPage" maxlength="200">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                               
                                <label style="margin-right: 14px;">Logo</label>
                                <input (change)="UploadClientlogo($event)" type="file" class="input">
                                <!-- <button class="btn" mat-raised-button color="primary" (click)="uploadFile()">Upload</button> -->
                            </div>
                        </div>

                        <div class="form-group" style="text-align:center ;"> Contact Person </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>First Name <label class="star">*</label></label>
                                <input type="email" class="form-control" placeholder="Enter First Name"
                                    formControlName="firstname" maxlength="50"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.firstname.errors }">
                                <div *ngIf="submittedComp && fcomp.firstname.errors" class="invalid-feedback">
                                    <div *ngIf="fcomp.firstname.errors.required">First Name is required</div>
                                    <div *ngIf="fcomp.firstname.errors.email">First Name is invalid</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Last Name <label class="star">*</label></label>
                                <input type="text" class="form-control" formControlName="lastname"
                                    placeholder="Enter Last Name" maxlength="50"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.lastname.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.lastname.errors">
                                    <div *ngIf="fcomp.lastname.errors.required">
                                       Last Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>ISD Code <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="CountryISDCode"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryISDCode.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let countries of allCountry" [value]="countries.ISDCode">
                                        {{countries.ISDCode}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CountryISDCode.errors">
                                    <div *ngIf="fcomp.CountryISDCode.errors.required">
                                        ISD Code is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>Mobile Number <label class="star">*</label></label>
                                <input type="text" class="form-control" formControlName="MobileNo"
                                    placeholder="XXXXX-XXXXX" maxlength="10"  (keypress)="keyPressAlphaNumeric($event)"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.MobileNo.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.MobileNo.errors">
                                    <div *ngIf="fcomp.MobileNo.errors.required">
                                        Mobile number is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button (click)="companyInfoForm()" class="btn dashboard-btn">
                        Save Your Information
                    </button>
                </div>
                
            </form>
        </div>
    </div>
</div>

<!-- End create company -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->