<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Candidates</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                        </li>
                        <li>
                            Candidates
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<!-- <div class="row">
    <div class="col-lg-10"></div>
    <div class="col-lg-2" style="padding-top: 11px;display: flex;flex-direction: row;gap: 6.02px;">
        <mat-label class="label" style="font-size: 16px;margin-top: -6px;font-weight: 600;" *ngIf="!_showFilter">Show Filters
        </mat-label>
        <mat-label class="label" style="font-size: 16px;margin-top: -6px; font-weight: 600;" *ngIf="_showFilter">Hide Filters
        </mat-label>
        <i class='bx bx-toggle-right' style="font-size: 40px; margin-top: -13px;color:#ff5b65;"
            (click)="togglItemGroupForm()" *ngIf="_showFilter"></i>
        <i class='bx bx-toggle-left' style="font-size: 40px; margin-top: -13px; color: rgb(192, 188, 188);"
            (click)="togglItemGroupForm()" *ngIf="!_showFilter"></i>
    </div>
</div> -->

<div class="job-filter-area pt-17">
    <div class="container">
        <form [formGroup]="candidateForm">

            <div class="row">
                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>JobRole : </label>
                        <input type="text" class="form-control form-select" matInput [formControl]="formControlObj"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                            (optionSelected)="onchangeJobRole($event.option.value.Id)" panelWidth="auto">
                            <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                {{jobrole.JobRole}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>City : </label>
                        <input type="text" class="form-control form-select" matInput [formControl]="formControlcity"
                            [matAutocomplete]="autocity">
                        <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity"
                            (optionSelected)="onchangeCity($event.option.value.Id)">
                            <mat-option *ngFor="let cities of filtercity | async" [value]="cities">
                                {{cities.CityName}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Qualification : </label>
                        <input type="text" class="form-control form-select" matInput [formControl]="formControlQuli"
                            [matAutocomplete]="autoQuali">
                        <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForQuli"
                            (optionSelected)="onchangeQualification($event.option.value.Id)">
                            <mat-option *ngFor="let Quli of filterQuli | async" [value]="Quli">
                                {{Quli.Qualification}}
                            </mat-option>
                        </mat-autocomplete>

                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Academic Decipline : </label>
                        <input type="text" class="form-control form-select" matInput
                            [formControl]="formControldiscipline" [matAutocomplete]="autodec">
                        <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnFordiscipline"
                            (optionSelected)="onchangeAcademicDiscipline($event.option.value.Id)">
                            <mat-option *ngFor="let discipline of filterdiscipline | async" [value]="discipline">
                                {{discipline.AcademicDescription}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Specialization : </label>
                        <input type="text" class="form-control form-select" matInput
                            [formControl]="formControlspecialization" [matAutocomplete]="autospec">
                        <mat-autocomplete #autospec="matAutocomplete" [displayWith]="displayFnForSpecialization"
                            (optionSelected)="onchangeSpecialization($event.option.value.Id)">
                            <mat-option *ngFor="let specialization of filterspecialization | async"
                                [value]="specialization">
                                {{specialization.SpecializationDescription}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Function : </label>
                        <input type="text" class="form-control form-select" matInput [formControl]="formControlfunction"
                            [matAutocomplete]="autofunction">
                        <mat-autocomplete #autofunction="matAutocomplete" [displayWith]="displayFnForFunction"
                            (optionSelected)="onchangeFunction($event.option.value.Id)">
                            <mat-option *ngFor="let function of filterfunction | async" [value]="function">
                                {{function.FunctionName}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Industry Type : </label>
                        <input type="text" class="form-control form-select" matInput [formControl]="formControlsection"
                            [matAutocomplete]="autosection">
                        <mat-autocomplete #autosection="matAutocomplete" [displayWith]="displayFnForSection"
                            (optionSelected)="onchangeSection($event.option.value.Id)">
                            <mat-option *ngFor="let section of filtersection | async" [value]="section">
                                {{section.IndustryType}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Email : </label>
                        <input type="text" (keyup)="SearchEmail($event)" formControlName="SearchEmail"
                            class="form-control" placeholder="Search Email Id" matInput>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Mobile Number : </label>
                        <input type="text" (keyup)="SearchMobileNumber($event)" formControlName="SearchMobileNo"
                            class="form-control" placeholder="Search Mobile Number" matInput>
                    </div>
                </div>

                <div class="col-sm-2 col-lg-3">
                    <div class="row">
                        <button type="submit" class="filterbtn" style="margin-left:14px;" (click)="search()">Filter<i
                                class='bx bx-search'></i></button>
                        <button type="submit" class="filterbtn" (click)="removefilter()">Show All<i
                                class='bx bx-search'></i></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="row">
    <div class="col-lg-2 col-md-12">

        <form [formGroup]="candidateForm">
            <input class="form-control gototxt" (keyup)="GoToPage($event)" placeholder="Goto Page"
                formControlName="PageSearch">
        </form>
    </div>
    <div class="col-lg-2 col-md-12">
        <button type="submit" (click)="GOTO()" class="gotobtn">Goto Page<i class='bx bx-search'></i></button>
    </div>
    <div class="col-lg-2 col-md-12">
        <button type="submit" (click)="Previous()" class="Previous">Previous<i class='bx bx-skip-previous-circle'
                style="margin-left: 10px;"></i></button>
    </div>
    <div class="col-lg-2 col-md-12">
        <button type="submit" (click)="Next()" class="Next">Next<i class='bx bx-skip-next-circle'
                style="margin-left: 10px;"></i></button>
    </div>
    <div class="col-lg-2 col-md-12">
        <label class="totalRecords">Total Records : {{totalItems}}</label>
    </div>
    <div class="col-lg-2 col-md-12">
        <label>Total Pages : {{totalpages}}</label>
    </div>
</div>
<!-- End Filter -->

<!-- Candidate -->
<div class="candidate-area pb-100" style="margin-top: 15px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12" *ngFor="let BuildResume of candidatsInfo">
                <div class="candidate-item two">
                    <div class="left">
                        <h3><a>{{BuildResume.FirstName}} {{BuildResume.LastName}} </a>
                        </h3>

                        <div><span>{{BuildResume.CurrentRoleWithCompanyName}}</span>
                        </div>
                        <ul class="experience">
                            <li>Email: <span>{{BuildResume.EmailId}}</span>
                            <li>Education: <span>{{BuildResume.HighestEducation}}</span>
                            <li>Experience: <span>{{BuildResume.Experience}} Years</span></li>
                            <li>City: <span>{{BuildResume.CityName}}</span></li>
                            <li>Pref.Location: <span>{{BuildResume.PreferredLocation}}</span></li>
                            <li>EmployementType: <span>{{BuildResume.Employeement}}</span></li>
                            <li> Skills: <span>{{BuildResume.Skills}}</span></li>

                        </ul>
                        <div class="cmn-link">
                            <a (click)="viewResume(BuildResume)"><i class="flaticon-right-arrow one"></i> View Resume
                                <i class="flaticon-right-arrow two"></i></a>
                        </div>
                    </div>
                    <ng-container *ngIf="(BuildResume.Photo); else noImage">
                        <img class="circle-54" [src]="BuildResume.Photo">
                    </ng-container>

                    <ng-template #noImage>
                        <ng-container *ngIf="BuildResume.Gender == 0">
                            <img class="circle-54" src="assets/candidateimg/Male_Colors.jpg">
                        </ng-container>

                        <ng-container *ngIf="BuildResume.Gender == 1">
                            <img class="circle-54" src="assets/candidateimg/Female_Colors.jpg">

                        </ng-container>
                        <ng-container *ngIf="BuildResume.Gender != 1 && BuildResume.Gender != 0">
                            <img class="circle-54" src="assets/candidateimg/Male_Colors.jpg">

                        </ng-container>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Candidate -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->