import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-footer-style-two',
  templateUrl: './footer-style-two.component.html',
  styleUrls: ['./footer-style-two.component.scss']
})
export class FooterStyleTwoComponent implements OnInit {

  constructor(private Global:GlobalService,
    private router: Router) { }

  ngOnInit(): void {
  }
  WorkFromHome(jobtype:number){
    this.Global.setjobTypeId(jobtype);
    this.router.navigate(['workfromhomejobs']);
  }
}
