import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { RecruiterInfomation } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-usertable',
    templateUrl: './usertable.component.html',
    styleUrls: ['./usertable.component.scss']
})

export class UserTableComponent implements OnInit, OnDestroy {

    displayedColumns: string[] = ['FirstName', 'LastName', 'Email', 'MobileNo', 'UserTypeName'];
    dataSource: MatTableDataSource<RecruiterInfomation>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    page: number = 1;
    pagesize: number = 10;
    dataFilter: number[] = [];
    datapageInfo: any;
    totalItems: number = 0;

    constructor(
        private toastr: ToastrService,
        private service: SharedService<any>
    ) {
        this.dataFilter = [];
    }

    ngOnInit(): void {
        this.getUser()
    }

    getUser() {
        this.dataFilter.push(this.page - 1);
        this.dataFilter.push(this.pagesize);
        this.service.Post('Recruiter/GetRecruiterTableData', this.dataFilter).subscribe(data => {
            if (data != null && data.length > 0) {
                if (data[0] != null && data[0].length > 0) {
                    this.dataSource = new MatTableDataSource(data[0]);
                    if (data[0] != null && data[0].length > 0) {
                        this.datapageInfo = data[1];
                        this.totalItems = this.datapageInfo[0].length;
                        this.dataFilter = [];
                    }

                }
            }
        },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    pageChanged(value: any) {
        this.page = value;
        this.getUser();
    }


    applyFilter(event: KeyboardEvent) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            this.dataSource.filter = '';
        }
        else {
            const filterValue = (event.target as HTMLInputElement).value;
            this.dataSource.filter = filterValue.trim().toLowerCase();
        }
    }

    ngOnDestroy(): void {

    }

}
