<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Recruiter</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                            <!-- <a routerLink="/post-a-job">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Recruiter</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                 
                    <a class="nav-link" id="v-pills-basicInfo-tab" data-bs-toggle="pill" role="tab"
                    aria-controls="v-pills-basicInfo" aria-selected="true" [routerLink]="['/recruiterhome/profile']"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                    <i class='bx bx-user'></i>
                    Recruite Information
                </a>

                <a class="nav-link" id="v-pills-password-tab" data-bs-toggle="pill" routerLink="recruiterchangepassword"
                        role="tab" aria-controls="v-pills-password" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-message-dots"></i>
                            Change Password
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
