import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

  constructor(private Global:GlobalService,
    private router: Router) { }

  ngOnInit(): void {
  }
  WorkFromHome(jobtype:number){
    this.Global.setjobTypeId(jobtype);
    this.router.navigate(['workfromhomejobs']);
  }

}
