<!-- Coming Soon -->
<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="soon-item">
                    <h1>Coming soon...</h1>
                    <span>within these days</span>

                    <div class="row">
                        <div class="col-6 col-sm-6 col-lg-3">
                            <div class="coming-inner">
                                <h3 id="days">{{days}}</h3>
                                <p>Days</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-lg-3">
                            <div class="coming-inner">
                                <h3 id="hours">{{hours}}</h3>
                                <p>Hours</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-lg-3">
                            <div class="coming-inner">
                                <h3 id="minutes">{{minutes}}</h3>
                                <p>Minutes</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-lg-3">
                            <div class="coming-inner">
                                <h3 id="seconds">{{seconds}}</h3>
                                <p>Seconds</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Coming Soon -->