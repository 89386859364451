import { Component, Inject, Injectable, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Currency, ListValuesMap } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';



@Component({
  selector: 'app-input-dialogg',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  [x: string]: any;

  message: string;
  title: string;
  btnMessage: string = "Confirm";
  inputForm: FormGroup;
  submitted: boolean = false;
  allCurrency?: Observable<Currency[]>;
  allPayRoll?: Observable<any[]>;
  closeResult: string = '';

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private service: SharedService<any>,
    private dialogRef: MatDialogRef<InputDialogComponent>,
  ) {

    // Update view with given values
  }
  get fcomp(): { [key: string]: AbstractControl } {
    return this.inputForm.controls;
  }


  ngOnInit(): void {

    this.inputForm = this.formBuilder.group({
      CurrencyId: ['', Validators.required],
      CTC: ['', Validators.required],
      PayRoll: ['', Validators.required]
    })

    this.allCurrency = this.service.Get('Currency/GetCurrency?Id=0', null);
    this.allPayRoll = this.service.Get('GetListValue?Id=' + ListValuesMap.PayRoll, null);
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.submitted = true;

    if (this.inputForm.invalid) {
      this.toastr.error("This data is not valid");
    }
    else {
      this.dialogRef.close(this.inputForm.value);
    }
  }
  onDismiss(): void {
    // Close the dialog, return false

  }
  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    });
  }

  onCancel()
  {
    this.dialogRef.close();
  }
}

export class InputDialogModel {

  constructor(public title: string,
    public message: string) {
  }

}


