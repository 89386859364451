<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>View Company </h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/employer/dashboard/:id/companies/:id">Companies list</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>View Company </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Create Company -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="companyForm">
                <div class="profile-content-inner">
                    <!-- <h2>Company</h2> -->
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Company Name</label>
                                <input type="text" class="form-control" formControlName="CompanyName" disabled
                                    placeholder="e.g. Acme Corporation."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CompanyName.errors }">

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="text" class="form-control" formControlName="CompanyEmail" disabled
                                    placeholder="xyz@gmail.com"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CompanyEmail.errors }">

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Company Description</label>
                                <textarea type="text" class="form-control" formControlName="Description" disabled
                                    placeholder="Enter Description"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Description.errors }"></textarea>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Industry</label>
                                <select class="form-control form-select" formControlName="IndustryTypeId" disabled
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.IndustryTypeId.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Industry of (allIndustry | async)" [value]="Industry.Id">
                                        {{Industry.IndustryType}}
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address1</label>
                                <textarea type="text" class="form-control" formControlName="Address1" disabled
                                    placeholder="Company Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Address1.errors }">
                                    </textarea>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address2</label>
                                <textarea type="text" class="form-control" formControlName="Address2" disabled
                                    placeholder="Company Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Address2.errors }">
                                    </textarea>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Country</label>
                                <select class="form-control form-select" formControlName="CountryId" disabled
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryId.errors }"
                                    (change)="onchangeCountry($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Countries of (allCountries | async)" [value]="Countries.Id">
                                        {{Countries.CountryName}}
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>State</label>
                                <select class="form-control form-select" formControlName="StateId" disabled
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.StateId.errors }"
                                    (change)="onchangeCity($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let states of (allStates | async)" [value]="states.Id">
                                        {{states.StateName}}
                                    </option>

                                </select>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>City</label>
                                <select class="form-control form-select" formControlName="CityId" disabled
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CityId.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let cities of (allCities | async)" [value]="cities.Id">
                                        {{cities.CityName}}
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Pincode</label>
                                <input class="form-control" placeholder="Enter Pincode" formControlName="PinCode"
                                    disabled maxlength="8"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.PinCode.errors }">

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Website</label>
                                <input class="form-control" placeholder="Enter website" formControlName="WebSite"
                                    disabled maxlength="100">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Linkedin Page</label>
                                <input class="form-control" placeholder="Enter LinkedInPage" disabled
                                    formControlName="LinkedInPage">
                            </div>
                        </div>


                        <div class="form-group" style="text-align:center ;"> Contact Person </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="email" class="form-control" placeholder="Enter First Name" disabled
                                    formControlName="firstname" maxlength="50"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.firstname.errors }">

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" class="form-control" formControlName="lastname" disabled
                                    placeholder="Enter Last Name" maxlength="50"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.lastname.errors }">

                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>ISD Code</label>
                                <select class="form-control form-select" formControlName="CountryISDCode" disabled
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryISDCode.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let countries of (allCountry | async)" [value]="countries.ISDCode">
                                        {{countries.ISDCode}}
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>Mobile Number</label>
                                <input type="text" class="form-control" formControlName="MobileNo" disabled
                                    placeholder="XXXXX-XXXXX" maxlength="15"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.MobileNo.errors }">

                            </div>
                        </div>
                    </div>
                </div>
                <button (click)="Close()" class="btn dashboard-btn">
                    Close
                </button>
            </form>
        </div>
    </div>
</div>

<!-- End create company -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->