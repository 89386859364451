<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Send Message</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Send Message
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<div class="dashboard-area ">
    <div class="">
        <div class="profile-content">
            <form [formGroup]="jobPostForm">
                <div class="profile-content-inner">
                    <!-- <h2>Post a Job</h2> -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Job Role </label>
                                <input type="text" class="form-control" [formControl]="formControlObj"
                                    [matAutocomplete]="auto" placeholder="Select Job Role">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                                    (optionSelected)="onchangejobrole($event.option.value.Id)" panelWidth="auto">
                                    <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                        {{jobrole.JobRole}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Candidate</label>
                                <mat-select #select multiple formControlName="MobileNo" class="form-control" placeholder="Select Candidate">
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection()">
                                            Select All</mat-checkbox>
                                    </div>
                                    <mat-option (onSelectionChange)="getValues($event)"
                                        *ngFor="let Candidate of (allcandidate | async)" [value]="Candidate.MobileNo">
                                        {{Candidate.FirstName}} {{Candidate.LastName}} </mat-option>
                                </mat-select>

                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Job Title</label>
                                <input class="form-control" formControlName="JobTitle" placeholder="Enter Job Title"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobTitle.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobTitle.errors">
                                    <div *ngIf="fjob.JobTitle.errors.required">
                                        Job Title is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Qualification</label>
                                <input class="form-control textarea" formControlName="Qualification"
                                    placeholder="Enter Qualification"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Qualification.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Qualification.errors">
                                    <div *ngIf="fjob.Qualification.errors.required">
                                        Qualification is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Experience</label>
                                <input class="form-control textarea" formControlName="Experience"
                                    placeholder="Enter Experience"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Experience.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Experience.errors">
                                    <div *ngIf="fjob.Experience.errors.required">
                                        Experience is required
                                    </div>
                                </div>
                            </div>
                        </div>

                    



                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Job Type</label>
                                <input class="form-control textarea" formControlName="JobType"
                                    placeholder="Enter Job Type"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobType.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobType.errors">
                                    <div *ngIf="fjob.JobType.errors.required">
                                        JobType is required
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Job Duration</label>
                                <input class="form-control textarea" formControlName="JobDuration"
                                    placeholder="Enter Job Duration"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobDuration.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobDuration.errors">
                                    <div *ngIf="fjob.JobDuration.errors.required">
                                        Job Duration is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Job Location</label>
                                <input class="form-control textarea" formControlName="JobLocation"
                                    placeholder="Enter Job Location"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobLocation.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobLocation.errors">
                                    <div *ngIf="fjob.JobLocation.errors.required">
                                        Job Location is required
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Payroll</label>
                                <input class="form-control textarea" formControlName="Payroll"
                                    placeholder="Enter Payroll"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Payroll.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Payroll.errors">
                                    <div *ngIf="fjob.Payroll.errors.required">
                                        Payroll is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Recruiter Email</label>
                                <input class="form-control textarea" formControlName="Email"
                                    placeholder="Enter Recruiter Email"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Email.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Email.errors">
                                    <div *ngIf="fjob.Email.errors.required">
                                        Email is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Recruiter Mobile Number</label>
                                <input 
                                maxlength="10"
                                (keypress)="keyPressAlphaNumeric($event)"
                                class="form-control textarea" 
                                formControlName="ContactNo"
                                    placeholder="Enter Recruiter Mobile Number"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.ContactNo.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.ContactNo.errors">
                                    <div *ngIf="fjob.ContactNo.errors.required">
                                        ContactNo is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Other</label>
                                <textarea class="form-control textarea" formControlName="Others"
                                    placeholder="Enter Other Description" style="height: 90px;"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Other.errors }"></textarea>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Other.errors">
                                    <div *ngIf="fjob.Other.errors.required">
                                        Other is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12"></div>
                        <button class="filterbtn" (click)="sendData()">
                            Send
                        </button>

                    </div>
                </div>
            </form>


        </div>
    </div>
</div>



<div id="frame">
	<div id="sidepanel" *ngIf="activeUser">
		<div id="profile" [class.expanded]="expanded">
			<div class="wrap">
				<img id="profile-img"(click)="expandStatuses = !expandStatuses"[class]="activeUser.status"
          [src]="activeUser.img"
          alt=""
		  *ngIf="activeUser.img"/>
		<img id="profile-img"(click)="expandStatuses = !expandStatuses"[class]="activeUser.status" src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 0"/>
		<img id="profile-img"(click)="expandStatuses = !expandStatuses"[class]="activeUser.status" src="assets/candidateimg/Female_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 1"/>
		<img id="profile-img"(click)="expandStatuses = !expandStatuses"[class]="activeUser.status" src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender != 0 && activeUser.gender != 1"/>

		
				<p>{{activeUser.name}}</p>
				<i
          class="fa fa-chevron-down expand-button"
          (click)="expanded = !expanded"
          aria-hidden="true"
        ></i>
				<div id="status-options" [class.active]="expandStatuses">
					<ul>
						<li *ngFor="let item of (statuses | keyvalue)" [attr.id]="'status-'+item.value"
							(click)="activeUser.status = item.value; expandStatuses=false"
							[class.active]="item.value === activeUser.status" [class]="item.value">
							<span class="status-circle"></span>
							<p style="text-transform: capitalize;">{{item.value}}</p>
						</li>
					</ul>
				</div>
				<div id="expanded">
					<ng-container *ngFor="let item of (activeUser?.social | json | keyvalue)">
						<label for="twitter"
              ><i
                class="fa fa-fw"
                [class]="'fa-'+item.key"
                aria-hidden="true"
              ></i
            ></label>
						<input [name]="item.key" type="text" [value]="item.value" />
          </ng-container>
				</div>
			</div>
		</div>
		<div id="search">
			<label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
			<input type="text" (keyup)="applyFilter($event)" placeholder="Search contacts..." />
    </div>
			<div id="contacts" [class.expanded]="expanded">
				<ul>
					<li class="contact" *ngFor="let user of users" (click)="setUserActive(user)"
						[class.active]="activeUser.id === user.id">
						<div class="wrap">
							<span class="contact-status" [class]="user.status"></span>
							<img [src]="user.img" alt="" *ngIf="user.img != ''"/>
							<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="user.img == '' && user.gender == 0"/>
							<img src="assets/candidateimg/Female_Colors.jpg" alt=""  *ngIf="user.img == '' && user.gender == 1"/>
							<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="user.img == '' && user.gender != 0 && user.gender != 1"/>
							<div class="meta">
								<p class="name">{{user.name}}</p>
								<p class="preview">
									<!-- {{user.messages[user.messages?.length - 1]?.message || ''}} -->
									{{user.mobileno}}
								</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- <div id="bottom-bar">
				<button id="addcontact">
        <i class="fa fa-user-plus fa-fw" aria-hidden="true"></i>
        <span>Add contact</span>
      </button>
				<button id="settings">
        <i class="fa fa-cog fa-fw" aria-hidden="true"></i> <span>Settings</span>
      </button>
			</div> -->
		</div>

		<div class="content" *ngIf="activeUser">
			<div class="contact-profile">
				<img [src]="activeUser.img" alt="" *ngIf="activeUser.img != ''"/>
				<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 0"/>
							<img src="assets/candidateimg/Female_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 1"/>
							<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender != 0 && activeUser.gender != 1"/>
				<p>{{activeUser.name}}</p>
				<div class="social-media">
					<i class="fa fa-facebook" aria-hidden="true"></i>
					<i class="fa fa-twitter" aria-hidden="true"></i>
					<i class="fa fa-instagram" aria-hidden="true"></i>
				</div>
			</div>
			<div class="messages" #scrollMe>
				<ul  *ngFor="let msg of activeUser?.Message">
					<li [class]="msg.type">
						<img [src]="msg.type === 'sent' ? activeUser.img : messageReceivedFrom.img" alt="" />
						<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 0"/>
						<img src="assets/candidateimg/Female_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender == 1"/>
						<img src="assets/candidateimg/Male_Colors.jpg" alt=""  *ngIf="activeUser.img == '' && activeUser.gender != 0 && activeUser.gender != 1"/>
						<div (click)="newwindow(msg)"><p >{{msg?.message || ''}}
							<br />
							
						</p>
                    </div>
                        
						<br />
						
						<!-- <label style="text-align: center;" *ngIf="msg.type === 'sent' ">{{msg?.TimeStamp || ''}}</label> -->
						<!-- <label style="">{{msg?.TimeStamp || ''}}</label> -->
					
					</li>
					<h6 *ngIf="msg.type === 'sent'" style="font-size: 11px; width: 153px;">{{msg?.TimeStamp || ''}}</h6>
						<h6 *ngIf="msg.type === 'replies'" style=" cursor: pointer;   text-align: right;font-size: 11px;" >{{msg?.TimeStamp || ''}}</h6>
				</ul>
			</div>
            <!-- <div class="message-input">
				<div class="wrap">
					<input
          type="text"
          #msgInput
          placeholder="Write your message..."
          (keyup.enter)="addNewMessage(msgInput)"
        />
					<i class="fa fa-paperclip attachment" aria-hidden="true"></i>
					<button class="submit" (click)="addNewMessage(msgInput)">
          <i class="bx bx-send" aria-hidden="true"></i>
          
        </button>
				</div>
			</div> -->
	</div>

    