import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-companysignup',
    templateUrl: './companysignup.component.html',
    styleUrls: ['./companysignup.component.scss']
})
export class CompanySignupComponent implements OnInit, OnDestroy {
    submittedComp: boolean = false;
    companyForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allCountry: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$";
    fileType: string = '';
    base64textString: string[] = [];

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service:SharedService<any>
    ) { }

    ngOnInit(): void {
        this.companyForm = this.formBuilder.group({
            CompanyName: [null, Validators.required],
            CompanyEmail: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            IndustryTypeId: ["", Validators.required],
            Address1: ["", [Validators.required, Validators.maxLength(150)]],
            Address2: ["", null],
            Description:["", Validators.required],
            CountryId: ["", Validators.required],
            StateId: ["", Validators.required],
            CityId: ["", Validators.required],
            PinCode: ["", Validators.required],
            WebSite: ["", null],
            LinkedInPage: ["", null],
            firstname: [null, Validators.required],
            lastname: ["", Validators.required],
            MobileNo: ["", Validators.required],
            CompanyProfileName: ["", null],
            Logo:['',[Validators.nullValidator]],
            UserTypeId: [2],
            CountryISDCode: ['', Validators.nullValidator],

        });
        this.allIndustry = this.Service.Get('IndustryTypes/GetIndustryTypes?Id=0', null);
        this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
        this.allCountry = this.Service.Get('Country/GetCountryISDCode?Id=0', null);
        this.companyForm.value.CountryISDCode.Sele
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.companyForm.controls;
    }
    onchangeCountry(event) {
        this.allStates = this.Service.Get('State/GetStateByCountry?countryId=' + event, null);
    }
    onchangeCity(event) {
        this.allCities = this.Service.Get('City/GetCityDataByState?StateId=' + event, null);
    }
    companyInfoForm() {

        this.submittedComp = true;
        if (this.companyForm.invalid) {
            this.toastr.error("Enter All Required Values");
        }
        else {
            const company = this.companyForm.getRawValue();
            company.Logo = this.base64textString[0];
            var UserName = this.companyForm.value.CompanyEmail;
            this.companyForm.value.CompanyProfileName = this.companyForm.value.CompanyName;
            company.CompanyProfileName = this.companyForm.value.CompanyName;
            this.Service.Post('Company/SigninCompanyData', company).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.companyForm.reset();
                    // this.Service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
                    //     (res) => {
                    //         this.toastr.success(res);
                    //     },
                    //   )
                      this.router.navigate(['/login']);
                },
                (error) => {
                    //if (error.status == 401) this.router.navigate(['/login']);
                    this.toastr.error(error.error);
                });
            this.submittedComp = false;
        }
    }

    Uploadcompanylogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }


   

    ngOnDestroy(): void {
    }



}
