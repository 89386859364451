import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './components/guards/auth.guard';
import { RoleGuardGuard } from './components/guards/role-guard.guard';
import { AboutComponent } from './components/pages/about/about.component';
import { CandidatesComponent } from './components/pages/candidates/candidates.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { CompanyCreateComponent } from './components/pages/companys/company-create/company-create.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { EmployersRegisterComponent } from './components/pages/employers-register/employers-register.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { LoginComponent } from './components/pages/login/login.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { UsersComponent } from './components/pages/User/users.component';
import { CountryComponent } from './components/pages/Configuration/Country/country.component';
import { AddCountryComponent } from './components/pages/Configuration/Country/Add-Edit Country/add-country.component';
import { StateComponent } from './components/pages/Configuration/State/state.component';
import { AddStateComponent } from './components/pages/Configuration/State/add-state/add-state.component';
import { CityComponent } from './components/pages/Configuration/City/city.component';
import { AddCityComponent } from './components/pages/Configuration/City/add-city/add-city.component';
import { CurrencyComponent } from './components/pages/Configuration/Currency/currency.component';
import { AddCurrencyComponent } from './components/pages/Configuration/Currency/addcurrency/addcurrency.component';
import { IndustryTypeComponent } from './components/pages/Configuration/IndustryType/industrytype.component';
import { JobRoleComponent } from './components/pages/Configuration/JobRole/jobrole.component';
import { AddIndustrytypeComponent } from './components/pages/Configuration/IndustryType/addindustrytype/addindustrytype.component';
import { AddJobRoleComponent } from './components/pages/Configuration/JobRole/addjobrole/addjobrole.component';
import { LanguagesComponent } from './components/pages/Configuration/Languages/languages.component';
import { AddLanguagesComponent } from './components/pages/Configuration/Languages/addlanguages/addlanguages.component';
import { QualificationComponent } from './components/pages/Configuration/Qualification/qualification.component';
import { AddQualificationComponent } from './components/pages/Configuration/Qualification/addqualification/addqualification.component';
import { SkillComponent } from './components/pages/Configuration/Skills/skills.component';
import { AddSkillComponent } from './components/pages/Configuration/Skills/addskills/addskills.component';
import { WorkFromHomeJobComponent } from './components/pages/WorkFromHomeJob/WorkFromHomeJob.component';
import { CreateCandidateComponent } from './components/pages/employers-register/candidate/createcandidate.component';
import { CreateCollegeComponent } from './components/pages/College/createcollege.component';
import { CompanySignupComponent } from './components/pages/companys/companysignup/companysignup.component';
import { UserTableComponent } from './components/pages/User/UserTable/usertable.component';
import { CompanyTableComponent } from './components/pages/companys/companytable/companytable.component';
import { ApplyJobComponent } from './components/pages/jobs/applyjob/applyjob.component';
import { ManageJobComponent } from './components/pages/jobs/managejobstable/managejobs.component';
import { CandidateTableComponent } from './components/pages/employers-register/candidate/candidatetable/candidatetable.component';
import { EditJobPostComponent } from './components/pages/post-a-job/edit-job-post/edit-job-post.component';
import { ViewResumeComponent } from './components/pages/candidates/ViewResume/viewresume.component';
import { UserHomeComponent } from './components/pages/Users/UserHome/userhome.component';
import { OwnerHomeComponent } from './components/pages/OwnerHome/ownerhome.component';
import { ShortListedCandidateForOwnerComponent } from './components/pages/ShortListedCandidateForOwner/shortlistedcandidateforowner.component';
import { EditCandidateComponent } from './components/pages/employers-register/EditCandidate/editcandidate.component';
import { CompanyHomeComponent } from './components/pages/companys/CompanyHome/companyhome.component';
import { ConfigDialogComponent } from './components/pages/config-dialog/confing-dialog.component';
import { ForgotPasswordComponent } from './components/pages/ForgotPassword/forgotpassword.component';

import { KeshavDashboardComponent } from './components/pages/Keshavdashboard/keshavdashboard.component';
import { GenralDialogComponent } from './components/pages/input -dialog/genral-dialog.component';
import { UserPointsComponent } from './components/pages/User/UserPoints/userpoints.component';
import { InputDialogComponent } from './components/pages/input -dialog/input-dialog.component';
import { WhatsappReceiveMsgComponent } from './components/pages/candidates/WhatsappReceiveMsg/whatsappreceivemsg.component';
import { WhatsappSendMsgComponent } from './components/pages/candidates/WhatsappSendMsg/whatsappsendmsg.component';
import { UserLastLoginComponent } from './components/pages/User/UserLastLogin/userlastlogin.component';
import { RecruiterHomeComponent } from './components/pages/Recruiter/recruiterhome.component';
import { OverdueJobComponent } from './components/pages/jobs/OverdueJob/overduejob.component';
import { JoiningDateComponent } from './components/pages/job-details/JoiningDate/add-joiningdate.component';
import { CandidateReportComponent } from './components/pages/CandidateReport/candidatereport.component';
import { CompanyEditComponent } from './components/pages/companys/companytable/Edit-Company/edit-company.component';
import { CompanyViewComponent } from './components/pages/companys/companytable/view-company/view-company.component';
import { BlacklistCandidateComponent } from './components/pages/config-dialog/BlackListCandidate/blacklistcandidate.component';
import { AddAcademicDescriptionComponent } from './components/pages/Configuration/Qualification/addacademicDiscipline/addacademicdiscipline.component';
import { AddSpecializationComponent } from './components/pages/Configuration/Qualification/addSpecialization/addSpecialization.component';
import { SpecializationComponent } from './components/pages/Configuration/Qualification/addSpecialization/specialization.component';
import { AcademicdisciplineComponent } from './components/pages/Configuration/Qualification/addacademicDiscipline/academicdiscipline.component';
import { ViewCandidateComponent } from './components/pages/employers-register/candidate/candidatetable/View/ViewCandidate.component';
import { ClientCreateComponent } from './components/pages/Client/client-register.component';
import { ClientComponent } from './components/pages/Client/ClientTable/clienttable.component';
import { ClientEditComponent } from './components/pages/Client/ClientTable/EditClient/editclient.component';
import { ClientViewComponent } from './components/pages/Client/ClientTable/ViewClient/viewclient.component';
import { FunctionComponent } from './components/pages/Configuration/Function/function.component';
import { AddFunctionComponent } from './components/pages/Configuration/Function/addfunction.component.ts/addfunction.component';
import { NewWhatsappSendMsgComponent } from './components/pages/candidates/newwpsendmsg/newwhatsappsendmsg.component';
import { WPReciveComponent } from './components/pages/candidates/wpreceivemsg/wprecivemsg.component';
import { WhatsappSendAndReceiveMsgComponent } from './components/pages/candidates/sendandreceivemsg/sendandreceivemsg.component';

const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'home-three', component: HomeThreeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'jobs', component: JobsComponent },
    { path: 'searchjobs', component: EmployersComponent },
    { path: 'workfromhomejobs', component: WorkFromHomeJobComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'about', component: AboutComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
    { path: 'createcandidate', component: CreateCandidateComponent },
    { path: 'createcollege', component: CreateCollegeComponent },
    { path: 'company-register', component: CompanySignupComponent },
    { path: 'shortlistedcandidateforowner', component: ShortListedCandidateForOwnerComponent },
    { path: 'configdialog',component:ConfigDialogComponent},
    { path: 'forgotpassword', component: ForgotPasswordComponent },
    { path: 'genraldialog', component: GenralDialogComponent },
    { path: 'dashboard', component: KeshavDashboardComponent },
    { path: 'inputdialog', component: InputDialogComponent },
    { path: 'recruiterlastlogin', component: UserLastLoginComponent },
    { path: 'overduejob', component: OverdueJobComponent },
    { path: 'joiningdate', component: JoiningDateComponent },
    { path: 'candidatereport', component: CandidateReportComponent },
    { path: 'blacklist', component: BlacklistCandidateComponent },
    { path: 'specialization', component: SpecializationComponent },
    { path: 'academicdiscipline', component: AcademicdisciplineComponent },
    { path: 'function', component: FunctionComponent },
    { path: 'editcompany/:id', component: CompanyEditComponent},
    { path: 'editclient/:id', component: ClientEditComponent},
    { path: 'viewcompany/:id',component: CompanyViewComponent},
    {
        path: 'viewclient/:id',
        // canActivate: [AuthGuard],
        component: ClientViewComponent
    },
    {
        path: 'whatsappmsgandreceive',
        // canActivate: [AuthGuard],
        component: WhatsappSendAndReceiveMsgComponent
    },
    {
        path: 'whatsappmsg',
        // canActivate: [AuthGuard],
        component: WhatsappReceiveMsgComponent
    },
    {
        path: 'wpreceivemsg',
        // canActivate: [AuthGuard],
        component: WPReciveComponent

    },
    
    {
        path: 'whatsappsendmsg',
        // canActivate: [AuthGuard],
        component: WhatsappSendMsgComponent
    },
    {
        path: 'wpsendmsg',
        // canActivate: [AuthGuard],
        component: NewWhatsappSendMsgComponent
    },
    {
        path: 'RecruitersPoints',
        // canActivate: [AuthGuard],
        component: UserPointsComponent
    },
    {
        path: 'viewcandidate/:id',
        // canActivate: [AuthGuard],
        component: ViewCandidateComponent
    },

   {path: 'editcandidate/:id',
    component: EditCandidateComponent,
    // canActivate: [AuthGuard, RoleGuardGuard],
    data: {
        expectedRoles: ["ROLE_SUPER_USER"]
    },
    children: [
        {
            path: '',
            loadChildren: () => import('./components/pages/employers-register/EditCandidate/editcandidate.module')
                .then(m => m.EditCandidateModule)
        }
    ]
},

{
    path: 'companyhome/profile',
    component: CompanyHomeComponent,
    // canActivate: [AuthGuard, RoleGuardGuard],
    // data: {
    //     expectedRoles: ['ROLE_CANDIDATE']
    // },
    children: [
        {
            path: '',
            loadChildren: () => import('./components/pages/companys/CompanyHome/companyhome.module')
                .then(m => m.CompanyModule)
        }
    ]
},

    {
        path: 'ownerhome/profile',
        component: OwnerHomeComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_CANDIDATE']
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./components/pages/OwnerHome/ownerhome.module')
                    .then(m => m.OwnerModule)
            }
        ]
    },

    {
        path: 'recruiterhome/profile',
        component: RecruiterHomeComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_CANDIDATE']
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./components/pages/Recruiter/recruterhome.module')
                    .then(m => m.RecruiterModule)
            }
        ]
    },


    {
        path: 'userhome/:id',
        component: UserHomeComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_CANDIDATE']
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./components/pages/Users/UserHome/userhome.module')
                    .then(m => m.UserModule)
            }
        ]
    },

   
    {
        path: 'candidatetable',
        // canActivate: [AuthGuard],
        component: CandidateTableComponent
    },
    {
        path: 'managejobtable',
        // canActivate: [AuthGuard],
        component: ManageJobComponent
    },
    {
        path: 'appliedjob',
        // canActivate: [AuthGuard],
        component: ApplyJobComponent
    },
    {
        path: 'company',
        // canActivate: [AuthGuard],
        component: CompanyTableComponent
    },
    {
        path: 'Recruiters',
        // canActivate: [AuthGuard],
        component: UserTableComponent
    },
    // {
    //     path: 'employers',
    //     canActivate: [AuthGuard],
    //     component: EmployersComponent
    // },
    {
        path: 'testimonials',
        canActivate: [AuthGuard],
        component: TestimonialsComponent
    },
    {
        path: 'faq',
        component: FaqComponent
    },

    {
        path: 'coming-soon',
        canActivate: [AuthGuard],
        component: ComingSoonComponent
    },
    {
        path: 'error',
        canActivate: [AuthGuard],
        component: ErrorComponent
    },

    
    {
        path: 'edit-job-post',
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_EMPLOYER']
        },
        component: EditJobPostComponent
    },
    {
        path: 'post-a-job',
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_EMPLOYER']
        },
        component: PostAJobComponent
    },

    {
        path: 'candidates',
        component: CandidatesComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ["ROLE_SUPER_USER", "ROLE_CANDIDATE", "ROLE_EMPLOYER"]
        },
    },

    {
        path: 'viewresume/:id',
        component: ViewResumeComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ["ROLE_SUPER_USER", "ROLE_CANDIDATE", "ROLE_EMPLOYER"]
        },
    },

    { path: 'job-details',component: JobDetailsComponent},


    { path: 'users', component: UsersComponent },

    {
        path: 'skill',
        // canActivate: [AuthGuard],
        component: SkillComponent
    },
    {
        path: 'addskill/:id',
        // canActivate: [AuthGuard],
        component: AddSkillComponent
    },
    {
        path: 'qualification',
        // canActivate: [AuthGuard],
        component: QualificationComponent
    },
    {
        path: 'qualificationform/:id',
        // canActivate: [AuthGuard],
        component: AddQualificationComponent
    },
    {
        path: 'language',
        // canActivate: [AuthGuard],
        component: LanguagesComponent
    },
    {
        path: 'addlanguage/:id',
        // canActivate: [AuthGuard],
        component: AddLanguagesComponent
    },
    {
        path: 'jobrole',
        // canActivate: [AuthGuard],
        component: JobRoleComponent
    },
    {
        path: 'addjobrole/:id',
        // canActivate: [AuthGuard],
        component: AddJobRoleComponent
    },

    {
        path: 'industrytype',
        // canActivate: [AuthGuard],
        component: IndustryTypeComponent
    },
    {
        path: 'addindustrytype/:id',
        // canActivate: [AuthGuard],
        component: AddIndustrytypeComponent
    },
    {
        path: 'currency',
        // canActivate: [AuthGuard],
        component: CurrencyComponent
    },
    {
        path: 'addcurrency/:id',
        // canActivate: [AuthGuard],
        component: AddCurrencyComponent
    },
    {
        path: 'addfunction/:id',
        // canActivate: [AuthGuard],
        component: AddFunctionComponent
    },
    {
        path: 'city',
        // canActivate: [AuthGuard],
        component: CityComponent
    },
    {
        path: 'add-city/:id',
        // canActivate: [AuthGuard],
        component: AddCityComponent
    },

    {
        path: 'state',
        // canActivate: [AuthGuard],
        component: StateComponent
    },
    {
        path: 'add-state/:id',
        // canActivate: [AuthGuard],
        component: AddStateComponent
    },
    {
        path: 'country',
        // canActivate: [AuthGuard],
        component: CountryComponent
    },
    {
        path: 'add-country/:id',
        // canActivate: [AuthGuard],
        component: AddCountryComponent
    },
    {
        path: 'addacademicdiscipline/:id',
        // canActivate: [AuthGuard],
        component: AddAcademicDescriptionComponent
    },

    {
        path: 'addspecialization/:id',
        // canActivate: [AuthGuard],
        component: AddSpecializationComponent
    },

    { path: 'companies/create', component: CompanyCreateComponent },
    { path: 'client/create', component: ClientCreateComponent },
    { path: 'client', component: ClientComponent },

    { path: 'employer/register', component: EmployersRegisterComponent },

    // ADMIN

    { path: '**', component: ErrorComponent } // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
