import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';

import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';



@Component({
    selector: 'app-addfunction',
    templateUrl: './addfunction.component.html',
    styleUrls: ['./addfunction.component.scss']
})
export class AddFunctionComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    functionform: FormGroup;
    public companyInfo = [];
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: number = 0;
    public dataSource: any;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private service: SharedService<any>,
    ) { }

    ngOnInit(): void {
        this.functionform = this.formBuilder.group({
            Id: ['', null],
            FunctionName: [null, Validators.required],
            
        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                this.id = Number(params.get('id'));
            }
        );

        if (this.id != 0) {
            this.boolIdVisible = false;
            
            this.GetFunctionDataById(this.id);
            this.ActionBtn = "Update";
           
        }
        else {
            this.boolIdVisible = true;
            this.ActionBtn = "Save";  
        }
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.functionform.controls;
    }


    FunctionForm() {
        this.submittedComp = true;
        if (this.functionform.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            if (this.id == 0) {
                const currency = this.functionform.getRawValue();
                this.service.Post('Function/InsertFunction', currency).subscribe(
                    (res) => {

                        this.toastr.success(res);
                        this.router.navigate(['./function'])
                    },
                    (error) => {
                        this.toastr.error(error.error);
                    });
                this.submittedComp = false;
            }
            else {
                this.UpdateCurrency();
            }
        }
    }

    Close(){
        this.router.navigate(['./function'])
    }

    UpdateCurrency() {
        const country = this.functionform.getRawValue();
        this.service.Put('Function/UpdateFunction', country).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['./function'])
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    GetFunctionDataById(Id: number) {
        this.service.Get('Function/GetFunction?Id=' + Id, null).subscribe(data => {
            this.dataSource = data;
            this.functionform.controls["Id"].setValue(this.dataSource[0].Id);
            this.functionform.controls["FunctionName"].setValue(this.dataSource[0].FunctionName);
        })
      }


    ngOnDestroy(): void {

    }

    
}
