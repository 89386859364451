import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { State } from '@popperjs/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../config-dialog/confing-dialog.component';

@Component({
    selector: 'app-state',
    templateUrl: './state.component.html',
    styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit, OnDestroy {

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private dialog: MatDialog,
        private service: SharedService<any>
    ) { }

    displayedColumns = [ "StateCode","StateName","CountryName", "IsActive","Edit"];
    message: string = '';
    result: string = '';
    dataSource = new MatTableDataSource<State>();
    StateData: any;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    public info: any;

    ngOnInit(): void {
       this.refreshList()
    }

    refreshList() {
        this.service.Get('State/GetStateData?Id=0', null).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      }
    
      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }
    
    AddState() {
        this.router.navigate(['add-state', 0])
    }

    EditState(row: any) {
        this.router.navigate(['add-state', row.Id]);
    }

    IsActive(row: any) {
        this.confirmDialog(row)
      }
    
      IsActiveContry(row: any) {
        this.service.Put('State/UpdateActiveStatus', row).subscribe({
          next: (res) => {
            if (row.IsActive == true) {
                this.toastr.success('State inactive sucessfully');
            }
            else {
                this.toastr.success('State active sucessfully');
            }
            this.refreshList();
          },
    
        })
      }
    
      confirmDialog(row: any) {
        
          if (row.IsActive == true) {
            this.message = 'Are you sure want to inactive State ' + row.StateName + ' ?';
          }
          else {
            this.message = 'Are you sure want to active State ' + row.StateName + ' ?';
          }
    
          const dialogData = new ConfirmDialogModel("Confirm Action", this.message);
    
          const dialogRef = this.dialog.open(ConfigDialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });
    
          dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
              this.IsActiveContry(row);
          }
        })
      }
    
      showIcon(index: boolean): string {
        if (index === true) {
          return 'toggle_on';
        } else {
          return 'toggle_off';
        }
      }
      
    ngOnDestroy(): void {

    }
   
}
