<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Find Jobs</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Find Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <ul class="job-list-item align-items-center">
                    <li>
                        <a href="#">Results Found</a>
                        <!-- <a class="rss" routerLink="/"><i class='bx bx-rss'></i>RSS Feed</a> -->
                    </li>
                    <!-- <li>
                        <span class="sort">Sort By:</span>
                        <form>
                            <div class="form-group">
                                <select>
                                    <option>Title</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select>
                            </div>
                        </form>
                    </li> -->
                </ul>

                <!-- <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Job Reference No</th>
                        <th scope="col">Job Role	</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Job Posted Date	</th>
                        <th scope="col">No. Of Opening	</th>
                        <th scope="col">Edit</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td routerLink="/job-details">BSP5677</td>
                        <td>Software Developer</td>
                        <td>@BeSpoke Systems Private Limited	</td>
                        <td>2022-12-08T00:00:00		</td>
                        <td>1	</td>
                        <td>
                            <i class='bx bxs-edit-alt'></i>                        </td>

                      </tr>
                      <tr>
                        <td>BSP5677</td>
                        <td>Software Developer</td>

                        <td>@BeSpoke Systems Private Limited	</td>
                        <td>2022-12-08T00:00:00		</td>

                        <td>1	</td>
                        <td>
                            <i class='bx bxs-edit-alt'></i>                        </td>
                      </tr>
                      <tr>
                        <td>BSP5677</td>
                        <td>Software Developer</td>

                        <td>@BeSpoke Systems Private Limited	</td>
                        <td>2022-12-08T00:00:00		</td>

                        <td>1	</td>
                        <td>
                            <i class='bx bxs-edit-alt'></i>                        </td>
                      </tr>
                    </tbody>
                  </table> -->

                <!-- <div *ngIf="jobPostsInfo.length > 0">

                    <div class="employer-item" *ngFor="let job of jobPostsInfo;">
                        <a [routerLink]="['/job-details', job.id]">
                            <img src="assets/img/home-one/job1.png" alt="Employer">
                            <h3>{{job.job_title}}</h3>
                            <ul>
                                <li><i class="flaticon-send"></i> {{job.job_location}}, Pakistan</li>
                                <li>{{job.createdAt | date}}</li>
                            </ul>
                            <p>{{job.job_description}}</p>
                            <span class="span-one">{{job.required_career_level}}</span>
                            <span class="span-two">{{job.workplace_type}}</span>

                        </a>
                    </div>
                </div> -->

                <!-- <div *ngIf="jobPostsInfo.length <= 0">
                    <h3>No Job Posted Yet!</h3>
                </div> -->



                <!-- <div *ngIf="jobPostsInfo.length > 0"> -->

                    <div class="employer-item"  *ngFor="let jobpost of obs" (click)="JobClick(jobpost)">
                     
                            <img [src]="jobpost.Logo" alt="Dashboard" style="height: 30%;margin-top: 57px;
                            margin-left: -12px;" *ngIf="jobpost.Logo">

                            <h3>{{jobpost.JobRole}}</h3>

                            <ul>
                                <li><i class="flaticon-send"></i> {{jobpost.CompanyIndustry}}</li>
                                <li>{{jobpost.JobOpening}} Opening</li>

                            </ul>

                            <p>{{jobpost.JobDescription}}</p>

                            <div style="display:flex;flex-flow:initial;column-gap:23px;">

                          
                            
                            <span  *ngIf="jobpost.NoticePerioddays" class="span-one five">
                                {{jobpost.NoticePerioddays}}
                            </span>
                            <span class="span-one five">
                                {{jobpost.JobLocationCity}}
                            </span>
                            <span class="span-one five">{{jobpost.MinYearExp}} Experinece</span>

                            <span class="span-one five">{{jobpost.Qualification}}</span>

                            <div *ngIf="jobpost.Salarytype == 1">
                                <span class="span-two">Salary Range:
                                    {{jobpost.MinSalary}}Rs.-{{jobpost.MaxSalary}}Rs</span>
                            </div>

                          <div *ngIf="jobpost.Salarytype == 0">
                    <span class="span-two five">
                        {{jobpost.SalaryDesc}}</span>
                </div>
                        </div>
                        
                    </div>
 
            </div>

            <div class="col-lg-4 col-md-12"> 
                <div class="job-list-right">
                    
                    <!-- <div class="job">
                        <h3>Find A Job</h3>

                        <form>
                            <label><i class="flaticon-send"></i></label>
                            <input type="email" class="form-control" placeholder="Email address">
                            <button type="submit" class="btn">Get A Job Alert</button>
                        </form>
                    </div> -->

                    <!-- <div class="job-list-all">
                        <div class="search">
                            <h3>Search Keywords</h3>

                            <form>
                                <input type="text" class="form-control" placeholder="Keyword">
                                <button type="submit" class="btn"><i class="flaticon-send"></i></button>
                            </form>

                            <h3>JobRole</h3>

                            <form>
                                <ul class="job-cmn-cat">
                                    <li *ngFor="let JobRole of allJobRole">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" [formControl]="jobRoleControl">
                                            <label class="form-check-label" for="defaultCheck1">{{JobRole.JobRole}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </form>


                            <div class="job-type cmn-padding">
                                <h3>City</h3>
    
                                <form>
                                    <ul class="job-cmn-cat">
                                        <li *ngFor="let cities of allCities">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="defaultCheck16" [formControl]="citycontrol">
                                                <label class="form-check-label" for="defaultCheck16"> {{cities.CityName}}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>

                        <div class="location-list cmn-padding">
                            <h3>Education</h3>

                            <form>
                                <ul class="job-cmn-cat">
                                    <li *ngFor="let Qualification of allQualification">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                id="defaultCheck10" [formControl]="EducationControl">
                                            <label class="form-check-label" for="defaultCheck10">{{Qualification.Qualification}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>

                     

                    </div> -->

                  
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
