import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { AcademicDiscipline, Country, EmployeeMaster, JobRole, Qualificationa, Specialization } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-viewwpmessage',
    templateUrl: './viewwpmessage.component.html',
    styleUrls: ['./viewwpmessage.component.scss']
})
export class ViewWhatappMessageComponent implements OnInit, OnDestroy {

    displayedColumns: string[] = ['Date', 'SentMsg', 'RecMesg'];
  dataSource: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  allCandidate: Observable<EmployeeMaster[]>;
  mobileNo: string;
  Id: number;
  Name: string;

    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) {

    }

    ngOnInit(): void {
       this.ViewWpMesg(this.editData)
       this.Name = this.editData.FirstName + ' ' +this.editData.LastName;
       this.mobileNo = this.editData.MobileNo;
    }

    ViewWpMesg(event) {
        this.Id = event.Id
        this.service.Get('WpMessage/GetCandidateWPMessage?CandidateId=' + this.Id, null).subscribe(data => {
    
          this.dataSource = new MatTableDataSource(data);
        //   if (data.length > 0) {
        //     this.mobileNo = data[0].MobileNo;
        //   }
        //   else {
        //     this.mobileNo = '';
        //   }
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      }

      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }
  
      onScrolled($event){
        this.paginator.nextPage();
      }
    ngOnDestroy(): void {

    }
}
