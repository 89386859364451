<div mat-dialog-content>
  <form [formGroup]="addEditForm">
    <div>
      <h2 mat-dialog-title>ShortList Candidate</h2>
      <div mat-dialog-content>
        <div class="col-lg-4 col-md-12">
          <div class="form-group">
            <label>Company</label>
            <select class="form-control form-select" formControlName="JobCompanyNameId"
              (change)="onchangeCompany($event.target.value)">
              <option value="" disabled selected>Choose any one</option>
              <option *ngFor="let comp of (allCompanyName | async)" [value]="comp.Id">
                {{comp.CompanyName}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-lg-4 col-md-12">
          <div class="form-group">
            <label>Job Role</label>
            <select class="form-control form-select" formControlName="JobRoleId"
              (change)="onchangeJobRole($event.target.value)">
              <option value="" disabled selected>Choose any one</option>
              <option *ngFor="let JobRole of (allJobRole | async)" [value]="JobRole.Id">
                {{JobRole.JobRole}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div mat-dialog-action class="float-container">
    <button mat-raised-button style="background-color: #26448c; margin-right: 1.5rem; color: white;" (click)="search()">
      Select
    </button>
    <button mat-raised-button style="background-color: #26448c;color: white;" mat-dialog-close>
      CLOSE
    </button>
  </div>
</div>