<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Language</h2>

                        <form [formGroup]="languagesform">
                            <div class="form-group">
                                <label>Language</label>
                                <input class="form-control" formControlName="Language" placeholder="Enter Language"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Language.errors }" maxlength="100">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Language.errors">
                                    <div *ngIf="fcomp.Language.errors.required">
                                        Language is required
                                    </div>
                                </div>
                            </div>

                            <button (click)="languageForm()" type="submit" class="btn Actionbutton">{{ActionBtn}}</button>
                            <button  (click)="Close()" type="close" class="btn Cancelbutton"> Close</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->