<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Resume</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Dashboard -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12" *ngIf="getresumeData">
                <div class="profile-item">
                    <div *ngIf="getresumeData.Photo">
                        <img [src]="getresumeData.Photo" alt="Dashboard">
                    </div>
                    <div *ngIf="getresumeData.Photo === ''">
                        <img src="../../assets/candidateimg/Male_Colors.jpg" alt="Logo">
                    </div>
                    <h2>{{getresumeData.FirstName}} {{getresumeData.LastName}}</h2>
                    <span>{{getresumeData.JobRole}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                    <a class="nav-link" id="v-pills-basicInfo-tab" data-bs-toggle="pill" role="tab"
                        aria-controls="v-pills-basicInfo" aria-selected="true" [routerLink]="['/editcandidate', userId]"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <i class='bx bx-user'></i>
                        Basic Information
                    </a>

                    <a class="nav-link" id="v-pills-education-tab" data-bs-toggle="pill" routerLink="education"
                        role="tab" aria-controls="v-pills-education" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-graduation"></i>
                            Education
                        </div>
                    </a>
                    <a class="nav-link" id="v-pills-experience-tab" data-bs-toggle="pill" routerLink="experience"
                        role="tab" aria-controls="v-pills-experience" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-briefcase"></i>
                            Experience
                        </div>
                    </a>

                    <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill" routerLink="certification"
                        role="tab" aria-controls="v-pills-certification" aria-selected="false"
                        routerLinkActive="active">
                        <div class="profile-list">
                            <i class='bx bxs-certification'></i>
                            Certification
                        </div>
                    </a>

                    <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill" routerLink="uplodresume"
                        role="tab" aria-controls="v-pills-certification" aria-selected="false"
                        routerLinkActive="active">
                        <div class="profile-list">
                            <i class='bx bx-cloud-upload'></i>
                            Upload Resume
                        </div>
                    </a>

                    <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill"
                        routerLink="viewuplodedresume" role="tab" aria-controls="v-pills-certification"
                        aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class='bx bx-bullseye'></i>
                            View Uploaded Resume
                        </div>
                    </a>

                    <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill" routerLink="function"
                        role="tab" aria-controls="v-pills-certification" aria-selected="false"
                        routerLinkActive="active">
                        <div class="profile-list">
                            <i class='bx bxs-cube-alt'></i>
                            Function
                        </div>
                    </a>
                    <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill" routerLink="section"
                        role="tab" aria-controls="v-pills-certification" aria-selected="false"
                        routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-customize"></i>
                            Industry Type
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Dashboard -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->