<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Candidate Status
                    </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                        </li>
                        <li>Candidate Status
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul class="job-list-item align-items-center">

                    <li>
                        <!-- <span class="sort">Sort By:</span> -->
                        <form>
                            <div class="form-group">
                            </div>
                        </form>
                    </li>
                </ul>
                <button type="submit" class="filterbtn" (click)="exportAsXLSX()">Download Report<i
                        class='bx bx-download'></i></button>

                <input class="form-control search"
                    (keyup)="applyFilter($event)" placeholder="Enter Text To Search">

                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">

                        <ng-container matColumnDef="CandidateName">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                                Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.CandidateName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Email">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                                Email</th>
                            <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                        </ng-container>


                        <ng-container matColumnDef="MobileNo">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                                Mobile Number</th>
                            <td mat-cell *matCellDef="let element"> {{element.MobileNo}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Resume">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                                Resume</th>
                            <td mat-cell *matCellDef="let element"> {{element.Resume}}</td>
                        </ng-container>

                        <ng-container matColumnDef="ResumeverifiedName">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                                Resume Review </th>
                            <td mat-cell *matCellDef="let element"> {{element.ResumeverifiedName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="MobileNumberVerify">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                                Mobile And Email Verify </th>
                            <td mat-cell *matCellDef="let element"> {{element.MobileNumberVerify}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                    <mat-paginator color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15,25]"
                        aria-label="Select page" >
                    </mat-paginator>
                </div>

            </div>

        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->