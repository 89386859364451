<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>City</h2>
                        <form [formGroup]="cityForm">

                            <div class="form-group">
                                <label>City</label>
                                <input class="form-control" formControlName="CityName" placeholder="Enter City Name"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CityName.errors }" maxlength="100">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CityName.errors">
                                    <div *ngIf="fcomp.CityName.errors.required">
                                        City Name is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>State</label>
                                <select class="form-control form-select" formControlName="StateId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.StateId.errors }">
                                    <option value="" disabled selected>Choose any one state</option>
                                    <option *ngFor="let state of (allState | async)" [value]="state.Id">
                                        {{state.StateName}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.StateId.errors">
                                    <div *ngIf="fcomp.StateId.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>


                            <button (click)="countryinfForm()" type="submit" class="btn Actionbutton"> {{ActionBtn}}</button>
                            <button  (click)="Close()" type="close" class="btn Cancelbutton"> Close</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->