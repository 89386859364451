<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Client Informaton
                    </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Client Informaton
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul>

                </ul>

                <input class="form-control" style="width: 269px;margin-left: 1074px;"
                    (keyup)="applyFilter($event)" placeholder="Enter Text To Search" 
                    [(ngModel)]="this.global.ClientSearch" name="Search" >
                    <div class="row">
                    <i class='bx bx-message-alt-x Clear' (click)="clearsearch()"></i>
                </div>

            
                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">

                        <ng-container matColumnDef="ClientName">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Client Name </th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.ClientName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="CityName">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> City </th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.CityName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="FirstName">
                            <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Contact Person </th>
                            <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.FirstName}} {{element.LastName}}</td>
                          </ng-container>

                          <ng-container matColumnDef="MobileNo">
                            <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Mobile Name </th>
                            <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.MobileNo}} </td>
                          </ng-container>
                    
                        <ng-container matColumnDef="Edit">
                          <th mat-header-cell *matHeaderCellDef class="M1">
                            Edit
                          </th>
                          <td mat-cell *matCellDef="let row">
                              <i class="bx bx-edit bx-tada-hover" (click)="Edit(row)" style="color: #062fb8;font-size:14px"></i>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="View">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                              View
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="bi-eye" (click)="View(row)" style="color: #7406ff;font-size:13px"></i>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="Delete">
                            <th mat-header-cell *matHeaderCellDef class="M1">
                                Delete
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <i class="bi bi-trash3" (click)="Delete(row)" style="color: red;font-size:13px"></i>
                            </td>
                          </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <!-- <mat-paginator  color="primary" [pageSize]="10" 
                    aria-label="Select page" style="font-size: 17px; color: #26448c;">
                </mat-paginator> -->
                
                    <div class="row">
                        <div class="col-lg-2 col-md-12">

                            <form [formGroup]="ClientForm">
                                <input class="form-control gototxt" (keyup)="GoToPage($event)" placeholder="Goto Page"
                                    formControlName="PageSearch">
                            </form>

                        </div>
                        <div class="col-lg-2 col-md-12">
                            <button type="submit" (click)="GOTO()" class="gotobtn" >Goto Page<i
                                class='bx bx-search'></i></button>
                        </div>
                        <div class="col-lg-2 col-md-12">
                            <button type="submit" (click)="Previous()" class="Previous">Previous<i
                                class='bx bx-skip-previous-circle' style="margin-left: 10px;"></i></button>

                            
                        </div>
                        <div class="col-lg-2 col-md-12">
                            <button type="submit" (click)="Next()" class="Next">Next<i
                                class='bx bx-skip-next-circle' style="margin-left: 10px;"></i></button>
                        </div>
                        <div class="col-lg-2 col-md-12">
                            <label class="totalRecords">Total Records : {{totalItems}}</label>
                        </div>
                        <div class="col-lg-2 col-md-12">
                            <label>Total Pages : {{totalpages}}</label>
                        </div>
                    </div>
                    <!-- <div class="col-sm-3 col-lg-3" style="margin-left: 387px; margin-top: 10px;">
                        <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [boundaryLinks]="true" [maxSize]="5"
                            (pageChange)="pageChanged(page)">
                           
                        </ngb-pagination>
                    </div> -->
                    <!-- <div style="    margin-left: 819px;
                    margin-top: -47px;
                    margin-bottom: 23px;">
                        <label>Total Records : {{totalItems}}</label>
                    </div> -->

                </div>

            </div>
        </div>
    </div>

    <!-- Footer -->
    <app-footer-style-two></app-footer-style-two>

    <!-- End Footer -->