export class user {
    Id: number;
    Email: string;
    ProfileName: string;
    Password: string;
    UserType: number;
}

export class usertoken {
    id: number;
    username: string;
    email: string;
    roles: string[] = [];
    token: string;
}

export class Users {
    Id: Number;
    Email: String;
    Password: String;
    ProfileName: String;
    UserType: String;

    constructor(Email: string, Password: string) {

        this.Email = Email;
        this.Password = Password;
        // this.Id = UserId;
    }

    getEmail(): String {
        return this.Email;
    }

    getPassword(): String {
        return this.Password;
    }

    getId(): Number {

        return this.Id;
    }

    setId(id: Number) {
        this.Id = id;
    }
}