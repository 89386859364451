<div class="user-form-area">
  <div class="container-fluid p-0">
    <div class="row m-0 align-items-center">
      <!-- <div class="col-lg-6 p-0 col-md-12">
              <div class="user-img">
                  <img src="assets/img/resgister-bg.jpg" alt="User">
              </div>
          </div> -->

      <div class="col-lg-12 p-0 col-md-12">
        <div class="user-content">
          <div class="top">
            <h2>Forgot Password</h2>

            <form [formGroup]="addEditForm">
              <div class="form-group" *ngIf="User">
                <input type="email" class="form-control" placeholder="Email" formControlName="Email">
              </div>

              <div class="form-group" *ngIf="OTP">
                <input type="password" class="form-control" placeholder="Please Enter OTP" formControlName="OTP">
              </div>
<div>
  <button (click)="Submit()" type="submit" class="btn"  *ngIf="Userbtn">Submit</button>
  <button (click)="SendOtp()" type="submit" class="btn"  *ngIf="OTP"> Verify OTP</button>
  <!-- <button  type="close" class="btn"  *ngIf="Userbtn">Close</button> -->
</div>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>