import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService<T>{

  constructor(private http: HttpClient,
    private auth: AuthService,
    private global: GlobalService
  ) { }

  Get(url: string, requestOptions: any): Observable<T> {
    return this.http.get<T>(this.auth.ApiUrl + url, { headers: requestOptions });
  }

  Post(url: string, data: any): Observable<T> {
    if (!this.global.isSubmitted) {
      return this.http.post<T>(this.auth.ApiUrl + url, data);
    }
    else {
      let requestOptions = new HttpHeaders()
        .set('userId', this.global.getuserId().toString());
      return this.http.post<T>(this.auth.ApiUrl + url, data, { headers: requestOptions });
    }
  }

  Put(url: string, data: any): Observable<T> {
    if (!this.global.isSubmitted) {
      return this.http.put<T>(this.auth.ApiUrl + url, data);
    }
    else {
      let requestOptions = new HttpHeaders()
        .set('userId', this.global.getuserId().toString());
      return this.http.put<T>(this.auth.ApiUrl + url, data, { headers: requestOptions });
    }
  }

  Delete(url: string, data: any): Observable<T> {
    return this.http.delete<T>(this.auth.ApiUrl + url + '/' + data);
  }

  getMenuList(id: number): Observable<any[]> {
    return this.http.get<any>(this.auth.ApiUrl + '/Menu/' + id);
  }

  Updateresume(data: any) {
    return this.http.put(this.auth.ApiUrl + 'resume/updateresume', data);
  }

  getStatus(data: any) {
    return this.http.get(this.auth.ApiUrl + 'company/getstatus/' + data);
  }

  resumebuildGet() {
    return this.http.get(this.auth.ApiUrl + 'resume/getresumedata');
  }
  resumebuildgetbyId(data: any) {
    return this.http.get(this.auth.ApiUrl + 'resume/getresumedatabyid/' + data);
  }

  resumeupload(data: any) {
   if (!this.global.isSubmitted) {
    return this.http.put<T>('https://keshavwebapi.jobonfingertip.com'+ '/api/CandidateInfo/UplodPDF', data);
  // return this.http.put<T>('http://localhost:65128'+ '/api/CandidateInfo/UplodPDF', data);
  }
  else {
    let requestOptions = new HttpHeaders()
      .set('userId', this.global.getuserId().toString());
    return this.http.post('https://keshavwebapi.jobonfingertip.com'+ '/api/CandidateInfo/UplodPDF', data, { headers: requestOptions })
  // return this.http.post('http://localhost:65128'+ '/api/CandidateInfo/UplodPDF', data, { headers: requestOptions })
  }
}}
