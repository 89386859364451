<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>FAQ</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            FAQ
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- FAQ -->
<div class="faq-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            <a>What's your ideal about company?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat natus, enim nobis perspiciatis iste possimus nemo doloremque tempore deleniti fugit? In delectus, accusantium facilis quae saepe commodi ipsum fuga.</p>
                        </li>
                        <li>
                            <a>Why should we hire you?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat natus, enim nobis perspiciatis iste possimus nemo doloremque tempore deleniti fugit? In delectus, accusantium facilis quae saepe commodi ipsum fuga.</p>
                        </li>
                        <li>
                            <a>What do we serve from our company?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat natus, enim nobis perspiciatis iste possimus nemo doloremque tempore deleniti fugit? In delectus, accusantium facilis quae saepe commodi ipsum fuga.</p>
                        </li>
                        <li>
                            <a>Why do you want to work here?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat natus, enim nobis perspiciatis iste possimus nemo doloremque tempore deleniti fugit? In delectus, accusantium facilis quae saepe commodi ipsum fuga.</p>
                        </li>
                        <li>
                            <a>When will you be most satisfied in our job?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat natus, enim nobis perspiciatis iste possimus nemo doloremque tempore deleniti fugit? In delectus, accusantium facilis quae saepe commodi ipsum fuga.</p>
                        </li>
                        <li>
                            <a>What attracted me to this company?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat natus, enim nobis perspiciatis iste possimus nemo doloremque tempore deleniti fugit? In delectus, accusantium facilis quae saepe commodi ipsum fuga.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-img">
                    <img src="assets/img/faq-main.jpg" alt="FAQ">
                </div>
            </div>
        </div>

        <div class="faq-bottom">
            <h3>If you don't find your questions or need help</h3>
            <a routerLink="/contact">Contact Us</a>
        </div>
    </div>
</div>
<!-- End FAQ -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->