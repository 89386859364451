import {  Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { cities, JobRole, Qualificationa } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';


@Component({
  selector: 'app-WorkFromHomeJob',
  templateUrl: './WorkFromHomeJob.component.html',
  styleUrls: ['./WorkFromHomeJob.component.scss']
})
export class WorkFromHomeJobComponent implements OnInit, OnDestroy {

  allJobRole: Observable<JobRole[]>;
  allQualification: Observable<Qualificationa[]>;
  allCities: Observable<cities[]>;
  cityControl = new FormControl();
  EducationControl = new FormControl();
  jobTypeControl = new FormControl();
  obs: any;
  data: any;
  dataFilter1: number[] = [];
  datasource = [];
  changeDetectorRef: any;
  paginator: any;
  page: number = 1;
  pagesize: number = 10;
  totalItems: number = 0;
  datapageInfo: any;

  constructor(
    private Service: SharedService<any>,
    public Global: GlobalService,
  ) {
    this.dataFilter1 = [];
    this.dataFilter1.push(0);
    this.dataFilter1.push(0);
    this.dataFilter1.push(0);
  }

  ngOnInit(): void {
    this.getAllJobRole();
    this.getAllQualification();
    this.getAllCity();
    this.refreshList();
  }

  pageChanged(value: any) {
    this.page = value;
    this.refreshList();
  }

  refreshList() 
  {
    if (this.Global.getjobTypeId() == 1) 
    {
      this.dataFilter1 = [];
      this.dataFilter1.push(1);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(this.page - 1);
      this.dataFilter1.push(this.pagesize);
      this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
        this.datasource = data[0];
        this.obs = this.datasource;
        this.datapageInfo = data[1];
        this.totalItems = this.datapageInfo[0].length;
      })
    }
    else if (this.Global.getjobTypeId() == 2) 
    {
      this.dataFilter1 = [];
      this.dataFilter1.push(0);
      this.dataFilter1.push(1);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(this.page - 1);
      this.dataFilter1.push(this.pagesize);
      this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
        this.datasource = data[0];
        this.obs = this.datasource;
        this.datapageInfo = data[1];
        this.totalItems = this.datapageInfo[0].length;
      })
    }
    else if (this.Global.getjobTypeId() == 3) 
    {
      this.dataFilter1 = [];
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(1);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(this.page - 1);
      this.dataFilter1.push(this.pagesize);
      this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
        this.datasource = data[0];
        this.obs = this.datasource;
        this.datapageInfo = data[1];
        this.totalItems = this.datapageInfo[0].length;
      })
    }
    else if (this.Global.getjobTypeId() == 4) 
    {
      this.dataFilter1 = [];
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(1);
      this.dataFilter1.push(0);
      this.dataFilter1.push(this.page - 1);
      this.dataFilter1.push(this.pagesize);
      this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
        this.datasource = data[0];
        this.obs = this.datasource;
        this.datapageInfo = data[1];
        this.totalItems = this.datapageInfo[0].length;
      })
    }
    else if (this.Global.getjobTypeId() == 5)
     {
      this.dataFilter1 = [];
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(1);
      this.dataFilter1.push(this.page - 1);
      this.dataFilter1.push(this.pagesize);
      this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
        this.datasource = data[0];
        this.obs = this.datasource;
        this.datapageInfo = data[1];
        this.totalItems = this.datapageInfo[0].length;
      })
    }
  }

  getAllJobRole() {
    this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
      this.allJobRole = jobrole;
    })
  }

  getAllQualification() {
    this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(Qualificationa => {
      this.allQualification = Qualificationa;
    })
  }

  getAllCity() {
    this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
      this.allCities = city;
    })
  }

  ngOnDestroy(): void {

  }
}