import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    userForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    Emailscmd: boolean = false;
    Emails: boolean = true;
    allCountryISD: Observable<any>;
    allrecruitermails: Observable<any>;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>
    ) { }

    ngOnInit(): void {
        this.userForm = this.formBuilder.group({
            FirstName: [null, Validators.required],
            LastName: ["", Validators.required],
            Email: [null, Validators.required],
            MobileNo: ["", Validators.required],
            UserType: [5],
            CountryISDCode: ['', Validators.required],
        });

        this.allrecruitermails = this.Service.Get('Recruiter/GetActiveRecruiterData', null);
        this.allCountryISD = this.Service.Get('Country/GetCountryISDCode?Id=0', null);

        this.Service.Get('Country/GetCountryISDCode?Id=0', null).subscribe({
            next: (res) => {
              this.allCountryISD = res;
              this.userForm.controls["CountryISDCode"].setValue(this.allCountryISD[0].ISDCode);
            }
          });
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.userForm.controls;
    }

    userinfForm() {

        this.submittedComp = true;
        if (this.userForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const user = this.userForm.getRawValue();
            var UserName = this.userForm.value.Email;
            this.Service.Post('Recruiter/InsertRecruiterData', user).subscribe(
                (res) => {

                    this.toastr.success(res);
                    this.userForm.reset();
                    // this.Service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
                    //     (res) => {
                    //         this.toastr.success(res);
                    //     },
                    // )
                    this.router.navigate(['/login']);
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submittedComp = false;
        }
    }

    reassignrecruiterForm() {
        this.submittedComp = true;
        if (this.userForm.invalid) {
            this.toastr.error("this data is not valid");
        }
        else {
            const user = this.userForm.getRawValue();
            var UserName = this.userForm.value.Email;
            this.Service.Post('Recruiter/ReassignRecruiterInfo', user).subscribe(
                (res) => {

                    this.toastr.success(res);
                    this.userForm.reset();
                    this.Service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
                        (res) => {
                            this.toastr.success(res);
                        },
                    )
                    this.router.navigate(['/login']);
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submittedComp = false;
        }
    }

    onCheckboxChange(event) {
        if (event.target.checked) {
            this.Emailscmd = true;
            this.Emails = false;
        }
        else {
            this.Emailscmd = false;
            this.Emails = true;
        }
    }

    keyPressAlphaNumeric(event: { keyCode: number; preventDefault: () => void; }) {
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9 ]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    ngOnDestroy(): void {

    }


}
