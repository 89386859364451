import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { Country, State } from 'src/app/interface';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-createcollege',
    templateUrl: './createcollege.component.html',
    styleUrls: ['./createcollege.component.scss']
})
export class CreateCollegeComponent implements OnInit, OnDestroy{

  submitted: boolean = true;
    clgForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    // allCountries: Observable<any>;
    // allStates: Observable<any>;
    // allCities: Observable<any[]>;
    compId: number = null;
    fileType: string = '';
    base64textString: string[] = [];

    allCountries: Country[]
    filterCountries?: Observable<Country[]>;
    formCountries= new FormControl();
    CountriesId: number;

    allStates: State[];
    filterStates?: Observable<State[]>;
    formStates= new FormControl();
    StatesId: number;

    allCities: any[];
    filterCities?: Observable<any[]>;
    formCities= new FormControl();
    CityId: number;
    


    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service:SharedService<any>
    ) { }

    ngOnInit(): void {
        this.clgForm = this.formBuilder.group({
            CollegeName: [null, Validators.required],
            CollegeEmail: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            ProfileName: ["", null],
            Address1: ["", Validators.required],
            Address2: ["", null],
            CollegeDescription: ["", Validators.required],
            CountryId: ["", Validators.required],
            StateId: ["", Validators.required],
            CityId: ["", Validators.required],
            PinCode: ["", Validators.required],
            WebSite: ["", null],
            LinkedInPage: ["", null],
            Logo:['',[Validators.nullValidator]],
            UserTypeId: [3],
        });

        this.Service.Get('Country/GetCountry?Id=0', null).subscribe(data => {
            if (data != null) {
              this.allCountries = data;
              this.filterCountries = this.formCountries.valueChanges.pipe(
                startWith<string | Country>(''),
                map(value => typeof value === 'string' ? value : value.CountryName),
                map(CountryName => CountryName ? this._filterCountry(CountryName) : this.allCountries.slice()),
              );
            }
          })
        //this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
    }

    get f(): { [key: string]: AbstractControl } {
        return this.clgForm.controls;
    }

    private _filterCountry(CountryName: string): Country[] {
        const filterValue = CountryName.toLowerCase();
        return this.allCountries.filter(
          option => option.CountryName?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForCountry(value: any) {
        return value ? value.CountryName : undefined;
      }

      private _filterState(StateName: string): State[] {
        const filterValue = StateName.toLowerCase();
        return this.allStates.filter(
          option => option.StateName?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForState(value: any) {
        return value ? value.StateName : undefined;
      }

      private _filterCity(CityName: string): any[] {
        const filterValue = CityName.toLowerCase();
        return this.allCities.filter(
          option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForCity(value: any) {
        return value ? value.CityName : undefined;
      }


    onchangeCountry(event) {
        this.CountriesId = event
         
         this.Service.Get('State/GetStateByCountry?countryId=' +    this.CountriesId, null).subscribe(data => {
             if (data != null) {
               this.allStates = data;
               this.filterStates = this.formStates.valueChanges.pipe(
                 startWith<string | State>(''),
                 map(value => typeof value === 'string' ? value : value.StateName),
                 map(StateName => StateName ? this._filterState(StateName) : this.allStates.slice()),
               );
             }
           })
     }
     onchangeState(event) {
         this.StatesId = event;
         this.Service.Get('City/GetCityDataByState?StateId=' + this.StatesId, null).subscribe(data => {
             if (data != null) {
               this.allCities = data;
               this.filterCities = this.formCities.valueChanges.pipe(
                 startWith<string | any>(''),
                 map(value => typeof value === 'string' ? value : value.CityName),
                 map(CityName =>  CityName? this._filterCity(CityName) : this.allCities.slice()),
               );
             }
           })
     }
     onchangeCity(event) {
         this.CityId = event;
     }
    ClgForm() {
     
        this.clgForm.controls["CountryId"].setValue(this.CountriesId);
        this.clgForm.controls["StateId"].setValue(this.StatesId);
        this.clgForm.controls["CityId"].setValue(this.CityId);
        if (this.clgForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const clg = this.clgForm.getRawValue();
            clg.Logo = this.base64textString[0];
            var UserName = this.clgForm.value.CollegeEmail;
            clg.ProfileName = this.clgForm.value.CollegeName;
            
            this.Service.Post('College/InsertCollegeData', clg).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.clgForm.reset();
                    // this.Service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
                    //     (res) => {
                    //         this.toastr.success(res);
                    //     },
                    //   )
                      this.router.navigate(['/login']);
                },
                (error) => {
                    //if (error.status == 401) this.router.navigate(['/login']);
                    this.toastr.error(error.error);
                });
            this.submitted = false;
        }
    }


    Uploadcompanylogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }
    ngOnDestroy(): void {
    }


}
