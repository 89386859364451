import { Component, OnDestroy, OnInit } from '@angular/core';
import { JobsService } from "./services/jobs.service";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { AcademicDiscipline, cities, JobRole, Qualificationa } from 'src/app/interface';
import { FormControl } from '@angular/forms';
import { GlobalService } from 'src/app/global.service';
import { AuthService } from 'src/app/auth.service';


@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit, OnDestroy {

    jobPostsInfo = [];
    allJobRole: Observable<JobRole[]>;
    allQualification: Observable<Qualificationa[]>;
    allCities: Observable<cities[]>
    obs: any;
    dataFilter1: number[] = [];
    data: any;
    citycontrol = new FormControl();
    EducationControl = new FormControl();
    jobRoleControl = new FormControl();
    datasource: any;
    Education: number = 0;
    City: number = 0;
    JobRole: number = 0;
    alldiscipline: Observable<AcademicDiscipline[]>;
    page:number = 1;
    pagesize:number =10 ;
    totalItems : number = 0;
    datapageInfo : any;

    constructor(
        // private jobsService: JobsService,
        private Service: SharedService<any>,
        private toastr: ToastrService,
        private router: Router,
        public Global: GlobalService,
        private auth: AuthService
    ) {
        // this.data = this.router.getCurrentNavigation().extras;
        // if (this.data.queryParams != null) {
        //   if (this.data.queryParams.myArray[0] == "searchString") {
        //     this.dataFilter1 = [];
        //     this.jobRoleControl.setValue(this.data.queryParams.myArray[1]);
        //     this.citycontrol.setValue(this.data.queryParams.myArray[2]);
        //     this.EducationControl.setValue(this.data.queryParams.myArray[3]);
        //     this.dataFilter1.push(this.Global.getuserId().toString());
        //     this.dataFilter1.push(this.data.queryParams.myArray[1]);
        //     this.dataFilter1.push(this.data.queryParams.myArray[2]);
        //     this.dataFilter1.push(this.data.queryParams.myArray[3]);
        //     this.getAllJobs();
        //   }
        // }
        // else if (this.data.queryParams == null) {
        //   this.dataFilter1 = [];
        //   this.dataFilter1.push(this.Global.getuserId().toString());
        //   this.dataFilter1.push(this.Global.GetUserType().toString());
        //   this.dataFilter1.push("0");
        //   this.dataFilter1.push("0");
        //   this.dataFilter1.push("0");
        //   this.getAllJobs();

        // }
    }

    ngOnInit(): void {
        //this.getAllJobs();
        this.dataFilter1 = [];
        this.dataFilter1.push(this.Global.getuserId());
        this.dataFilter1.push(this.Global.GetUserType());
        this.dataFilter1.push(0);
        this.dataFilter1.push(0);
        this.dataFilter1.push(0);
        this.getAllJobs();
        this.allJobRole = this.Service.Get('JobRole/GetJobRole?Id=0', null);
        this.allQualification = this.Service.Get('Qualification/GetQualification?Id=0', null);
        this.allCities = this.Service.Get('City/GetCity?Id=0', null);
        // this.getrecommandedjob();
    }

    getAllJobs() {
        this.dataFilter1.push(this.page - 1);
        this.dataFilter1.push(this.pagesize);
        this.Service.Post('Job/GetJobPostDataOfSigleJob', this.dataFilter1).subscribe(
            (res) => {
                this.jobPostsInfo = res[0];
                this.obs = this.jobPostsInfo;
                this.datapageInfo = res[1];
                this.totalItems = this.datapageInfo[0].length; 

            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }

    pageChanged(value : any ){
        this.page = value;
        this.applyselection();
        this.removeselection();
    }
    JobClick(jobpost: any) {
        this.auth.setJobPostId(jobpost.Id);
        this.router.navigate(['job-details']);
    }
    getrecommandedjob() {
        this.Service.Get('job/GetJobForEmployeeDashboard?userId=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.jobPostsInfo = res;
                this.obs = this.jobPostsInfo;

            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    onchangeCity(city: any) {
        this.City = city;
    }

    onchangeJobRole(jobrole: any) {
        this.JobRole = jobrole;
    }

    onchangeQualification(Qualification: any) {
        this.Education = Qualification;       
    }

    applyselection() {

        this.dataFilter1 = [];
        this.dataFilter1.push(this.Global.getuserId());
        this.dataFilter1.push(this.Global.GetUserType());
        this.dataFilter1.push(this.JobRole);
        this.dataFilter1.push(this.Education);
        this.dataFilter1.push(this.City);        
        this.getAllJobs();
    }
    removeselection() {
        this.dataFilter1 = [];
        this.dataFilter1.push(this.Global.getuserId());
        this.dataFilter1.push(this.Global.GetUserType());
        this.dataFilter1.push(0);
        this.dataFilter1.push(0);
        this.dataFilter1.push(0);
        this.getAllJobs();
    }
    ngOnDestroy(): void {


    }

}
