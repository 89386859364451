<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>Receive Message
          </h2>
          <ul>
            <li>
              <img src="assets/png/pinklogo.png" alt="Image">
              <!-- <a routerLink="/">Home</a> -->
            </li>
            <!-- <li><span>/</span></li> -->
            <li>Receive Message
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <ul class="job-list-item align-items-center">
          <li>
            <form>
              <div class="form-group">
              </div>
            </form>
          </li>
        </ul>
        
        <input class="form-control" style="width: 300px; margin-left: 963px;margin-top: -49px;"
          (keyup)="applyFilter($event)" placeholder="Enter Text To Search">

        <div class="row">
          <div class="col-sm-4 col-lg-6" style="max-width: 25%; margin-left: 22px;">
            <div class="form-group">
              <label>Candidate Name</label>
              <select class="form-control" (change)="onchangecandidateName($event.target.value)">
                <option value="" disabled selected>Choose any one CandidateName</option>
                <option *ngFor="let candid of (allCandidate | async)" [value]="candid.Id">
                  {{candid.FirstName}} {{candid.LastName}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="form-group">
              <label>Mobile No : </label>
              <span>{{mobileNo}}</span>
            </div>
          </div>
        </div>

        <div class="card mat-elevation-z6 m-3 w-auto">
          <table mat-table [dataSource]="dataSource" matSort class="table-responsive">

            <ng-container matColumnDef="Date">
              <th mat-header-cell *matHeaderCellDef class="M1">
                Date</th>
              <td mat-cell *matCellDef="let element"> {{element.DateAdded | date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="SentMsg">
              <th mat-header-cell *matHeaderCellDef class="M1">
                Send Message</th>
              <td mat-cell *matCellDef="let element"> {{element.SentMsg}} </td>
            </ng-container>

            <ng-container matColumnDef="RecMesg">
              <th mat-header-cell *matHeaderCellDef class="M1">
                Receive Message</th>
              <td mat-cell *matCellDef="let element"> {{element.RecMesg}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15,25]" aria-label="Select page"
           >
          </mat-paginator>
        </div>

      </div>

    </div>
  </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->