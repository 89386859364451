import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-view-company',
    templateUrl: './view-company.component.html',
    styleUrls: ['./view-company.component.scss']
})
export class CompanyViewComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    companyForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$";
    fileType: string = '';
    base64textString: string[] = [];
    allCountry: Observable<any>;
    DataSource?: any;
    Id: number
    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.companyForm = this.formBuilder.group({
            Id: ["", null],
            CompanyName: [null, Validators.required],
            CompanyEmail: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            IndustryTypeId: ["", Validators.required],
            Address1: ["", [Validators.required, Validators.maxLength(150)]],
            Address2: ["", null],
            Description: ["", Validators.required],
            CountryId: ["", Validators.required],
            StateId: ["", Validators.required],
            CityId: ["", Validators.required],
            PinCode: ["", Validators.required],
            WebSite: ["", null],
            LinkedInPage: ["", null],
            firstname: [null, Validators.required],
            lastname: ["", Validators.required],
            MobileNo: ["", Validators.required],
            CompanyProfileName: ["", null],
            Logo: ['', [Validators.nullValidator]],
            UserTypeId: [2],
            CountryISDCode: ['', Validators.nullValidator],

        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));

                this.Id = Number(params.get('id'));
            }
        );

        this.GetEmployeeDataById(this.Id);
        this.allIndustry = this.Service.Get('IndustryTypes/GetIndustryTypes?Id=0', null);
        this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
        this.allCountry = this.Service.Get('Country/GetCountryISDCode?Id=0', null);
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.companyForm.controls;
    }
    onchangeCountry(event) {
        this.allStates = this.Service.Get('State/GetStateByCountry?countryId=' + event, null);
    }
    onchangeCity(event) {
        this.allCities = this.Service.Get('City/GetCityDataByState?StateId=' + event, null);
    }

    GetEmployeeDataById(Id: any) {

        this.Service.Get('Company/GetCompanyDataforOwner?Id=' + this.Id, null).subscribe(data => {
            this.DataSource = data;
            this.companyForm.controls["Id"].setValue(this.DataSource[0].Id);
            this.companyForm.controls["CompanyName"].setValue(this.DataSource[0].CompanyName);
            this.companyForm.controls["CompanyEmail"].setValue(this.DataSource[0].CompanyEmail);
            this.companyForm.controls["IndustryTypeId"].setValue(this.DataSource[0].IndustryTypeId);
            this.companyForm.controls["Address1"].setValue(this.DataSource[0].Address1);
            this.companyForm.controls["Address2"].setValue(this.DataSource[0].Address2);
            this.companyForm.controls["Description"].setValue(this.DataSource[0].Description);
            this.companyForm.controls["CountryId"].setValue(this.DataSource[0].CountryId);
            this.companyForm.controls["StateId"].setValue(this.DataSource[0].StateId);
            this.companyForm.controls["CityId"].setValue(this.DataSource[0].CityId);
            this.companyForm.controls["PinCode"].setValue(this.DataSource[0].PinCode);
            this.companyForm.controls["WebSite"].setValue(this.DataSource[0].WebSite);
            this.companyForm.controls["LinkedInPage"].setValue(this.DataSource[0].LinkedInPage);
            this.companyForm.controls["firstname"].setValue(this.DataSource[0].FirstName);

            this.companyForm.controls["lastname"].setValue(this.DataSource[0].LastName);
            this.companyForm.controls["MobileNo"].setValue(this.DataSource[0].MobileNo);
            // this.companyForm.controls["CompanyProfileName"].setValue(this.DataSource[0].CompanyProfileName);
            this.companyForm.controls["CountryISDCode"].setValue(this.DataSource[0].CountryISDCode);
            this.companyForm.controls["Logo"].setValue(this.DataSource[0].Logo);


            this.allIndustry = this.Service.Get('IndustryTypes/GetIndustryTypes?Id=0', null);
            this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
            this.allCountry = this.Service.Get('Country/GetCountryISDCode?Id=0', null);
            this.allStates = this.Service.Get('State/GetStateData?Id=0', null);
            this.allCities = this.Service.Get('City/GetCity?Id=0', null);
        })
    }

    Close() {
        this.router.navigate(['/company']);
    }


    ngOnDestroy(): void {
    }


}
