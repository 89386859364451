<!-- Navbar -->
<!-- <app-navbar-style-three></app-navbar-style-three> -->
<!-- End Navbar -->
<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a href="#" class="logo"><img src="assets/img/logo-4.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="#"><img src="assets/img/logo-4.png" alt="Logo"></a>
            </nav>
        </div>
    </div>
</div>
<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>About Us</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-img">
                    <img src="assets/img/about.jpg" alt="New">
                    <img src="assets/img/about-shape.png" alt="New">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span class="sub-title">Explore New Opportunities</span>
                        <h2>We Are A Team Of Experts In the Field of Hiring </h2>
                    </div>
                    <p> We are ISO 9001:2015 certified company based at vadodara, Gujarat. We are
                        service provider to process industries basis services includes operation & 
                        maintenance on single point responsibilities.</p>
                    
                    <!-- <a class="cmn-btn" routerLink="/contact">Get Started<i class='bx bx-plus'></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End New -->

<!-- Counter -->
<div class="counter-area two pt-100">
    <div class="counter-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div>

    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-hiring"></i>
                    <p>CANDIDATES</p>
                    <h3><span class="odometer" data-count="0">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-candidate"></i>
                    <p>TOTAL JOBS</p>
                    <h3><span class="odometer" data-count="0">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-working-at-home"></i>
                    <p>EMPLOYERS</p>
                    <h3>
                        <span class="odometer" data-count="0">00</span> 
                    </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-choice"></i>
                    <p>JOB APPLICATIONS</p>
                    <h3><span class="odometer" data-count="0">00</span> </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Counter -->

<!-- Work -->
<section class="work-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Possibilities</span>
            <h2>Our Company Possibilities</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-verify"></i>
                    <span>01</span>
                    <h3>Excellence</h3>
                    <p>to be defined</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-file"></i>
                    <span>02</span>
                    <h3>Adaptability</h3>
                    <p>to be defined</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>03</span>
                    <h3>Integrity</h3>
                    <p>to be defined</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>04</span>
                    <h3>Knowledge</h3>
                    <p>to be defined</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>05</span>
                    <h3>The Passion</h3>
                    <p>to be defined</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>06</span>
                    <h3>Innovation</h3>
                    <p>to be defined</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work -->


<!-- Candidate -->
<section class="candidate-area two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Working Process</span>
            <h2>Leadership Team</h2>
        </div>

        <div class="candidate-slider owl-theme owl-carousel">
            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
                    <span>Web Developer</span>
                    <p>I’m IRanin dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>Php</li>
                        <li>jQuery</li>
                        <li>WordPress</li>
                        <li>CSS3</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate1.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
                    <span>Senior UX/UI Designer</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate2.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Tom Henry</a></h3>
                    <span>SEO</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate3.jpg" alt="Candidate">
            </div>
        </div>
    </div>
</section>
<!-- End Candidate -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->