import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blacklistcandidate',
  templateUrl: './blacklistcandidate.component.html',
  styleUrls: ['./blacklistcandidate.component.scss']
})
export class BlacklistCandidateComponent implements OnInit {

 
  title: string;
  message: string;
  status:any;
  BlacklistForm: FormGroup;
   remark : boolean = false;
   Blacklist : boolean = true;
  constructor(public dialogRef: MatDialogRef<BlacklistCandidateComponent>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: BlacklistCandidateModel) {
    // Update view with given values 
    this.title = data.title;
     this.message = data.message;
     this.status = data.Status
  }

  ngOnInit(): void {

    this.BlacklistForm = this.formBuilder.group({
      Remarks: [null, Validators.required],
      Status : [null, Validators.nullValidator],
    });
    
  }
  get f(): { [key: string]: AbstractControl } {
    return this.BlacklistForm.controls;
  }
  onConfirm(): void {
    if(this.status == true){
      this.Blacklist = true;
      this.remark = false;
      const black = this.BlacklistForm.getRawValue();
      black.Status = true
      this.dialogRef.close(true);
    }
    else{
      this.remark = true;
      this.Blacklist = false;
    }
    
    
    // Close the dialog, return true
   
  }

  Save(){    
   if (this.BlacklistForm.invalid) {
      this.toastr.error("This data is not valid");
    }
    else{
      const black = this.BlacklistForm.getRawValue();
       black.Status = true
     this.dialogRef.close(black);
    }
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

export class BlacklistCandidateModel {

  constructor(public title: string,
     public message: string,
     public Status: string) {
  }

}
