import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';
import userToken from "../../config/userToken";
import { NavbarService } from '../services/navbar.service';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {
    token: any = userToken.token;
    isToken: boolean = false;

    constructor(public myGlobals: GlobalService,
        private toastr: ToastrService,
        public router :Router,
        private service: SharedService<any>,
        ) { }

    ngOnInit(): void {
        //this.getToken();
    }

    getToken()
    {

    }
    onLogout(event: Event){
        this.service.Post('User/SetUserLogOutAudit?userName=' +  this.myGlobals.Getprofilename(), null).subscribe({
            next: (res) => {
        this.myGlobals.isSubmitted = false;
        this.myGlobals.isCompany = false;
       // this.myGlobals.getLoginData = null;
        this.router.navigate(['/login']);

    }
})}  

}
