
<h1 mat-dialog-title class="h5">
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p class="h6 m">{{message}}</p>
  </div>

  
  <div mat-dialog-actions>
      <button mat-raised-button  (click)="onConfirm()" class="Actionbutton">Yes</button>
    <button mat-button  (click)="onDismiss()" class="Actionbutton">No</button>
  </div>
