import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExcelServicesService } from 'src/app/excel-services.service';
import { GlobalService } from 'src/app/global.service';
import { CandidateReport, CandidateUploadedErrorLogs, EmployeeMaster, UserType } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../../config-dialog/confing-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BlacklistCandidateComponent, BlacklistCandidateModel } from '../../../config-dialog/BlackListCandidate/blacklistcandidate.component';
import { EditCandidateComponent } from '../../EditCandidate/editcandidate.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ViewWhatappMessageComponent } from './viewwpmsg/viewwpmessage.component';
import { Subject } from 'rxjs';

export class BlacklistCandidate {
  Id: null;
  BlackListRemark: string;
  Blacklisted: boolean;
}


@Component({
  selector: 'app-candidatetable',
  templateUrl: './candidatetable.component.html',
  styleUrls: ['./candidatetable.component.scss']
})

export class CandidateTableComponent implements OnInit, OnDestroy {
  @ViewChild('myInput') myInputVariable: any;

  Info = [];
  dataSource: MatTableDataSource<EmployeeMaster>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  data: any;
  candidateData: any;
  message: string = '';
  usertype: number
  blacklistbtn: boolean;
  ID: number;
  page: any = 0;
  pagesize: number = 10;
  candidateForm: FormGroup;
  datapageInfo: any;
  totalItems: number = 0;
  // Search: string = '';
  empSearch: string = '';
  totalpages: number;
  StartDate: any = null
  endDate: any = null
  date = new FormControl(new Date());
  Todate = new FormControl(new Date());
  minDate;
  minDateToFinish = new Subject<string>();
  // 'MobileNumberVerify',
  displayedColumns: string[] = ['FirstName', 'MobileNo', 'UserName', 'Qualification', 'AcademicDescription', 'SpecializationDescription', 'JobRole', 'Resume', 'ResumeverifiedName', 'BlackList', 'Action', 'View'];

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private service: SharedService<any>,
    private excelService: ExcelServicesService,
    private dialog: MatDialog,
    public Global: GlobalService,
    private formBuilder: FormBuilder,
  ) {
    this.minDate = this.endDate
    this.minDateToFinish.subscribe(r => {
      this.minDate = new Date(r);
    })

  }

  ngOnInit(): void {

    this.candidateForm = this.formBuilder.group({
      PageSearch: [null, Validators.nullValidator],

    });
    this.candidateForm.controls["PageSearch"].setValue(this.Global.GoToPage);
    const momentDate1 = new Date();
    this.StartDate = moment(momentDate1).format("YYYY-MM-DD");
    const EndDate1 = new Date;
    this.endDate = moment(EndDate1).format("YYYY-MM-DD");


    this.getCandidates();
    this.usertype = this.Global.GetUserType();

    if (this.Global.GetUserType() == UserType.Recruiter) {
      this.blacklistbtn = true
    }
    else {
      this.blacklistbtn = false;
    }
  }

  getCandidates() {
    this.service.Get('CandidateInfo/GetCandidateTableData?pageno=' + this.Global.GoToPage + '&pagesize=' + this.pagesize + '&search=' + this.Global.CandidateTableSearch, null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data[0]);
      this.dataSource.paginator = this.paginator;
      this.datapageInfo = data[1];
      this.totalItems = this.datapageInfo[0].length;
      if (this.totalItems > 0) {
        this.totalpages = Math.ceil(this.totalItems / 10);
      }
      else {
        this.totalpages = 0;
      }

    },
      (error) => {
        this.toastr.error(error.error);
      });
  }


  applyFilter(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.Global.CandidateTableSearch = ''
      this.Global.GoToPage = 1
      this.candidateForm.controls["PageSearch"].setValue(this.Global.GoToPage);
      this.getCandidates();
    }
    else {
      this.Global.CandidateTableSearch = (event.target as HTMLInputElement).value;
    }
  }
  searchbtn() {
    if (this.Global.CandidateTableSearch != '') {
    this.Global.CandidateTableSearch.trim();
      this.search();
    }

  }

  GoToPage(event: Event) {
    var value = (event.target as HTMLInputElement).value;
    if (+value > this.totalpages
      || +value < 1) {
      this.toastr.success('Page no Should be between : 1 And ' + this.totalpages);
    }
    else {
      this.Global.GoToPage = +value;
    }

  }

  GOTO() {
    this.service.Get('CandidateInfo/GetCandidateTableData?pageno=' + this.Global.GoToPage + '&pagesize=' + this.pagesize + '&search=' + this.Global.CandidateTableSearch, null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data[0]);
      this.dataSource.paginator = this.paginator;
    },
      (error) => {
        this.toastr.error(error.error);
      });
  }

  Previous() {
    if (this.Global.GoToPage > 1) {
      this.Global.GoToPage = this.Global.GoToPage - 1
      this.candidateForm.controls["PageSearch"].setValue(this.Global.GoToPage);
      this.getCandidates();
    } else {

    }

  }

  Next() {
    if (this.totalpages > this.Global.GoToPage) {
      this.Global.GoToPage = this.Global.GoToPage + 1
      this.candidateForm.controls["PageSearch"].setValue(this.Global.GoToPage);
      this.getCandidates();
    }
  }

  search() {
    this.Global.GoToPage = 1;
    this.candidateForm.controls["PageSearch"].setValue(this.Global.GoToPage);
    this.service.Get('CandidateInfo/GetCandidateTableData?pageno=' + this.Global.GoToPage + '&pagesize=' + this.pagesize + '&search=' + this.Global.CandidateTableSearch, null)
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data[0]);
        this.datapageInfo = data[1];
        this.totalItems = this.datapageInfo[0].length;
        if (this.totalItems > 0) {
          this.totalpages = Math.ceil(this.totalItems / 10);
        }
        else {
          this.totalpages = 0;
        }
      },
        (error) => {
          this.toastr.error(error.error);
        });
  }

  clearsearch() {
      this.Global.CandidateTableSearch = ''
      this.Global.GoToPage = 1
      this.candidateForm.controls["PageSearch"].setValue(this.Global.GoToPage);
      this.getCandidates();
    
  }

  Edit(row: Number) {
    this.candidateData = row;
    this.router.navigate(['editcandidate', this.candidateData.UserId]);
  }

  View(row: Number) {
    this.candidateData = row;
    this.router.navigate(['viewcandidate', this.candidateData.Id]);
  }

  Blacklist(row: any) {
    this.confirmDialog(row)
  }

  UpdateBlacklistCandidate(row: any) {
    this.service.Put('CandidateInfo/UpdateBlacklistCandidateStatus', row).subscribe({
      next: (res) => {

        if (row.Blacklisted == true) {

          this.toastr.success('Candidate  activate sucessfully');
        }
        else {
          this.toastr.error('Candidate blacklisted sucessfully');
        }
        this.getCandidates();
      },
    })
  }


  confirmDialog(row: any) {
    var blacklist = new BlacklistCandidate();
    blacklist.Id = row.Id
    blacklist.Blacklisted = row.Blacklisted

    if (row.Blacklisted == true) {
      this.message = 'Are you sure want to activate candidate ' + row.FirstName + ' ?';
    }
    else {
      this.message = 'Are you sure want to blacklisted candidate ' + row.FirstName + ' ?';
    }

    const dialogData = new BlacklistCandidateModel("Confirm Action", this.message, row.Blacklisted);

    const dialogRef = this.dialog.open(BlacklistCandidateComponent, {
      maxWidth: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        dialogRef.afterClosed()
      }
      else {
        blacklist.BlackListRemark = dialogResult.Remarks
        this.UpdateBlacklistCandidate(blacklist);
      }

      // this.UpdateBlacklistCandidateRemark(blacklist);

    }

    )
  }


  exportAsXLSX(): void {
    const InternShipStartDate: any = 'mm-dd-yyyy';
    const InternShipEndDate: any = 'mm-dd-yyyy';
    const MobileNo: any = 1234567890;
    const GET: any = 1;
    let objEMColln: EmployeeMaster[] = [];
    let objEM: EmployeeMaster = new EmployeeMaster();
    objEM.FirstName = 'Bhumi';
    objEM.LastName = 'Pathak';
    objEM.ProfileName = 'Bhumi Pathak';
    objEM.Email = 'test@gmail.com';
    objEM.CountryISDCode = +91
    objEM.MobileNo = MobileNo;
    objEM.Qualification = '';
    objEM.AcademicDescription = '';
    objEM.SpecializationDescription = '';
    objEM.IsGET = GET;
    objEM.JobRole = '';
    objEM.NoOfExperience = 1;
    objEM.PassOutYear = 2021;
    objEM.InternShipStartDate = InternShipStartDate;
    objEM.InternShipEndDate = InternShipEndDate;
    objEMColln.push(objEM);
    this.excelService.exportAsExcelFile(objEMColln, 'JobPost_');
  }


  onFileChange(event: any): void {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = XLSX.utils.sheet_to_json(ws);

      let objEMColln: EmployeeMaster[] = [];

      for (let item of this.data) {
        if (item.ProfileName != ' ') {

          let objER: EmployeeMaster = new EmployeeMaster();
          objER.FirstName = item.FirstName;
          objER.LastName = item.LastName;
          objER.ProfileName = item.ProfileName;
          objER.CountryISDCode = item.CountryISDCode
          objER.MobileNo = item.MobileNo;
          objER.Email = item.Email;
          objER.IsGET = item.IsGET;
          objER.Qualification = item.Qualification;
          objER.AcademicDescription = item.AcademicDiscipline;
          objER.SpecializationDescription = item.Specialization;
          objER.JobRole = item.JobRole;
          objER.NoOfExperience = item.NoOfExperience;
          objER.PassOutYear = item.PassOutYear;

          const momentSatrtDate = new Date(item.InternShipStartDate);
          const formatedStartdate = moment(momentSatrtDate).format("YYYY-MM-DD");
          item.InternShipStartDate = formatedStartdate;
          objER.InternShipStartDate = item.InternShipStartDate;

          const momentEndDate = new Date(item.InternShipEndDate);
          const formatedEnddate = moment(momentEndDate).format("YYYY-MM-DD");
          item.InternShipEndDate = formatedEnddate;
          objER.InternShipEndDate = item.InternShipEndDate;
          objER.UserTypeId = 4;
          objEMColln.push(objER);
        }
      }
      this.service.Post('CandidateInfo/ImportCandidateInfoDetails', objEMColln).subscribe(
        (res) => {
          this.myInputVariable.nativeElement.value = "";
          this.getCandidates();
          this.toastr.success(res);
        },
        (error) => {
          //if (error.status == 401) this.router.navigate(['/login']);
          this.toastr.error(error.error);
        });

    }
  }
  ViewWPMSg(Id: any) {
    this.dialog.open(ViewWhatappMessageComponent, {

      width: '60%',
      height: '75%',

      data: Id
    });
  }

  // showIcon(index: boolean): string {

  //   if (index === true) {
  //     return 'bx-toggle-left';
  //   } else {
  //     return 'bx-toggle-right';
  //   }
  // }

  ImportAsXLSX(): void {
    let objCRColln: CandidateUploadedErrorLogs[] = [];
    this.service.Get('CandidateInfo/CandidateUploadedErrorLogs?userId=' + this.Global.getuserId() + '&startDate=' + this.StartDate + '&endDate=' + this.endDate, null).subscribe(data => {
      for (let item of data) {
        let objCR: CandidateUploadedErrorLogs = new CandidateUploadedErrorLogs();
        objCR.UploadedBy = item.UploadedBy;
        objCR.UploadedDate = item.UploadedDate;
        objCR.CandidateEmail = item.CandidateEmail;
        objCR.Remarks = item.Remarks;
        objCRColln.push(objCR);
      }
      this.excelService.exportAsExcelFile(objCRColln, 'Candidate_Uploaded_Data_');
    });
  }

  dateChange(event: any) {
    this.minDateToFinish.next(this.date.value.toString());
    const momentDate1 = this.date.value;
    this.StartDate = moment(momentDate1).format("YYYY-MM-DD");

  }
  EnddateChange(event) {
    const momentDate1 = event.value;
    this.endDate = moment(momentDate1).format("YYYY-MM-DD");

  }

  ngOnDestroy(): void {

  }

}

