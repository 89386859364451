<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <!-- <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div> -->

            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Industry Type</h2>

                        <form [formGroup]="industrytypeform">
                           
                            <div class="form-group">
                                <label>Industry Type</label>
                                <input class="form-control" formControlName="IndustryType"
                                    placeholder="Enter Industry Type"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.IndustryType.errors }" maxlength="100">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.IndustryType.errors">
                                    <div *ngIf="fcomp.IndustryType.errors.required">
                                        Industry Type is required
                                    </div>
                                </div>
                            </div>


                            <div class="form-group">
                                <label>Description</label>
                                <input class="form-control" formControlName="Description" placeholder="Enter Descrption"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Description.errors }" maxlength="500">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Description.errors">
                                    <div *ngIf="fcomp.Description.errors.required">
                                        Description is required
                                    </div>
                                </div>
                            </div>




                            <button (click)="IndustrytypeForm()" type="submit" class="btn Actionbutton">{{ActionBtn}}</button>
                            <button  (click)="Close()" type="close" class="btn Cancelbutton"> Close</button>
                        </form>
                        <!-- <div class="create-account">
                            <span class="account-direction">Create an account? <a style="font-weight: 600; color: #fe4a55;" routerLink="/register">Sign Up</a></span>
                        </div> -->

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->