<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Banner -->
<div class="banner-area ">
    <div class="banner-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <!-- <div class="banner-content"> -->
                    <!-- <div class="d-table"> -->
                        <!-- <div class="d-table-cell">
                            <h1>Find Thousand <span>Job</span> If You’re Ready To Get</h1>
                            <p>We have 100+ live jobs adverts on our site, helping you to find any type of job</p>
                        </div> -->
                    <!-- </div> -->
                <!-- </div> -->
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="banner-img">
                  
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner -->

<!-- Work -->
<section class="work-area pb-70 pt-120">
    <div class="container">
        <div class="section1">
            <span class="sub-title">Summary</span>
            <h4 style="color: #221668;">Candidate , Jobs , Colleges , Companies summary. </h4>
        </div>

        <div class="row" *ngIf="info">

            <div class="col-sm-6 col-lg-4">
                <div class="work-item" >
                    <i class="flaticon-enter"></i>
                    <span><h2>{{info[0].TodayAddedCnadidate}}</h2></span>
                    <h3>Candidates</h3>
                    <p>No of candidates registered today.</p>
                </div>
            </div>


            <div class="col-sm-6 col-lg-4">
                <div class="work-item" >
                    <i class="flaticon-candidate"></i>
                    <span><h2>{{info[0].CandidateCount}}</h2></span>
                    <h3>Candidates</h3>
                    <p>No of candidates registered.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item" >
                    <i class="flaticon-resume"></i>
                    <span><h2>{{info[0].UplodedResume}}</h2></span>
                    <h3>Resume Uploaded </h3>
                    <p>No of candidates for whom resumes uploaded.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-verify"></i>
                    <span><h2>{{info[0].ResumeVerifiedCount}}</h2></span>
                    <h3>Candidate Resume Verified</h3>
                    <p>No of candidates for whom resumes verified.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-enter"></i>
                    <span><h2>{{info[0].JobRoleCount}}</h2></span>
                    <h3>Candidate Job Role</h3>
                    <p>No of Job Role Updated.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-appointment"></i>
                    <span><h2>{{info[0].Fuction1Count}}</h2></span>
                    <h3>Candidate Fuction 1</h3>
                    <p>No of Fuction1 Updated.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-appointment"></i>
                    <span><h2>{{info[0].Fuction2Count}}</h2></span>
                    <h3>Candidate Fuction 2</h3>
                    <p>No of Fuction2 Updated.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-appointment"></i>
                    <span><h2>{{info[0].Fuction3Count}}</h2></span>
                    <h3>Candidate Fuction 3</h3>
                    <p>No of Fuction3 Updated.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span><h2>{{info[0].Section1Count}}</h2></span>
                    <h3>Candidate Industry Type 1</h3>
                    <p>No of Industry Type 1 Updated.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span><h2>{{info[0].Section2Count}}</h2></span>
                    <h3>Candidate Industry Type 2</h3>
                    <p>No of Industry Type 2 Updated.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span><h2>{{info[0].Section1Count}}</h2></span>
                    <h3>Candidate Industry Type 2</h3>
                    <p>No of Industry Type 2 Updated.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item" >
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <span><h2>{{info[0].CompanyCount}}</h2></span>
                    <h3>Company</h3>
                    <p>No of companies registered.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-hiring"></i>
                    <span><h2>{{info[0].PostJobCount}}</h2></span>
                    <h3>Job Post</h3>
                    <p>No of Jobs Posted.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-customer"></i>
                    <span><h2>{{info[0].CollegeCount}}</h2></span>
                    <h3>College</h3>
                    <p>No of colleges registered.</p>
                </div>
            </div>
        </div>
        <div class="section-title">
            <span class="sub-title">Candidate Status</span>
            <h2>Candidate Status </h2>
        </div>
        
        <div class="counter-area pt-100">
            <div class="counter-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div>
        
            <div class="container">
                <div class="row" *ngIf="info">
                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-candidate"></i>
                            <p>New Candidates</p>
                            <h3><span class="odometer">{{info[0].NewCandidatesCount}}</span> </h3>
                        </div>
                    </div>
        
                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-verify"></i>
                            <p>Shortlisted Candidates</p>
                            <h3><span class="odometer" >{{info[0].ShortlistedCandidatesCount}}</span> </h3>
                        </div>
                    </div>
        
                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-enter"></i>
                            <p>Submitted Candidate</p>
                            <h3><span class="odometer" >{{info[0].SubmittedCandidateCount}}</span> </h3>
                        </div>
                    </div>
        
                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-resume"></i>
                            <p>Interview Candidate</p>
                            <!-- {{info[0].AppliedjobCount}} -->
                            <h3><span class="odometer" >{{info[0].InterviewCandidateCount}}</span> </h3>
                        </div>
                    </div>

                    <!-- <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-appointment"></i>
                            <p>Interview Candidate</p>
                          
                            <h3><span class="odometer" >{{info[0].InterviewCandidateCount}}</span> </h3>
                        </div>
                    </div> -->

                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-choice"></i>
                            <p>Offered Candidate</p>
                            <!-- {{info[0].AppliedjobCount}} -->
                            <h3><span class="odometer" >{{info[0].OfferedCandidateCount}}</span> </h3>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-hiring"></i>
                            <p>Joined Candidate</p>
                            <!-- {{info[0].AppliedjobCount}} -->
                            <h3><span class="odometer" >{{info[0].JoinedCandidateCount}}</span> </h3>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-accounting"></i>
                            <p>Releaved Or Resinged Candidate</p>
                            <!-- {{info[0].AppliedjobCount}} -->
                            <h3><span class="odometer" >{{info[0].ReleavedCandidateCount}}</span> </h3>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-left-arrow"></i>
                            <p>Drop Candidate</p>
                            <!-- {{info[0].AppliedjobCount}} -->
                            <h3><span class="odometer" >{{info[0].DropCandidateCount}}</span> </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</section>
<!-- End Work -->


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->