<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <!-- <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div> -->

            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Currency</h2>

                        <form [formGroup]="currencyform">

                            <div class="form-group">
                                <label>Currency Code</label>
                                <input class="form-control" formControlName="CurrencyCode"
                                placeholder="Enter Currency Code"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.CurrencyCode.errors }" maxlength="5" >
                            <div class="invalid-feedback" *ngIf="submittedComp && fcomp.v.errors">
                                <div *ngIf="fcomp.CurrencyCode.errors.required">
                                    Currency Code is required
                                </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Currency Name</label>
                                <input class="form-control" formControlName="CurrencyName"
                                placeholder="Enter Currency Name"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.CurrencyName.errors }" maxlength="100">
                            <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CurrencyName.errors">
                                <div *ngIf="fcomp.CurrencyName.errors.required">
                                    Currency Name is required
                                </div>
                                </div>
                            </div>
                         
                            <div class="form-group">
                                <label>Currency Symbol</label>
                                <input class="form-control" formControlName="CurrencySymbol"
                                placeholder="Enter Currency Symbol"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.CurrencySymbol.errors }" maxlength="5">
                            <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CurrencySymbol.errors">
                                <div *ngIf="fcomp.CurrencySymbol.errors.required">
                                    Currency Symbol is required
                                </div>
                                </div>
                            </div>
                           


                            <button (click)="CurrencyForm()" type="submit" class="btn Actionbutton">{{ActionBtn}}</button>
                            <button  (click)="Close()" type="close" class="btn Cancelbutton"> Close</button>
                        </form>
                        <!-- <div class="create-account">
                            <span class="account-direction">Create an account? <a style="font-weight: 600; color: #fe4a55;" routerLink="/register">Sign Up</a></span>
                        </div> -->

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->