import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';
import userToken from "../../config/userToken";
import { NavbarService } from '../services/navbar.service';


@Component({
    selector: 'app-navbar-style-three',
    templateUrl: './navbar-style-three.component.html',
    styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {
    token: any = userToken.token;
    isToken: boolean = false;
    userName: string;

    constructor(private navbarService: NavbarService,
        private toastr: ToastrService,
        public myGlobals : GlobalService,
        public router: Router,
        private service: SharedService<any>,
        ) { }

    ngOnInit(): void {
        this.getToken();
        this.userName = this.myGlobals.Getprofilename();
    }

    getToken() {
        if (this.token) this.isToken = true;
    }

    onLogout(event: Event){
        this.service.Post('User/SetUserLogOutAudit?userName=' +  this.myGlobals.Getprofilename(), null).subscribe({
            next: (res) => {
        this.myGlobals.isSubmitted = false;
        this.myGlobals.isCompany = false;
        this.router.navigate(['/login']);
    }
})}
}
