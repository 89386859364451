<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item"> 
                    
                    <h2 *ngIf="allJobRole == null && allQualification == null ">Jobs</h2>
                    <h2 *ngIf="allJobRole != null && allQualification == null "> Jobs For {{allJobRole[0].JobRole}}</h2>
                    <h2 *ngIf="allQualification != null && allJobRole == null "> Jobs For {{allQualification[0].Qualification}}</h2>
                    <!-- <h2 *ngIf="allQualification == null && allJobRole == null && allcities != null"> Jobs For {{allcities[0].CityName}}</h2> -->
                    <h2 *ngIf="allQualification != null && allJobRole != null "> Jobs For {{allJobRole[0].JobRole}} and Qualification is {{allQualification[0].Qualification}}</h2>
                  

                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<div class="job-filter-area pt-100">
    <div class="container">
        <form [formGroup]="candidateform">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <div class="form-group">
                            <label>Select Job Types</label>
                            <select class="form-control form-select" (change)="SelectJobType($event.target.value)"  formControlName="jobtype">
                                <option value="" disabled selected>Select Job Types</option>
                                <option>Work From Home</option>
                                <option>Part Time</option>
                                <option>Jobs For Women</option>
                                <option>Jobs For Freshers</option>
                                <option>Internship</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="form-group">
                        <div class="form-group">
                            <label>Select City </label>
                            <select class="form-control form-select" (change)="cityselect($event.target.value)" formControlName="city">
                                <option value="" disabled selected>Select City</option>
                                <option *ngFor="let city of allcities" [value]="iscity == true ? city.Id :  city.Id ">
                                    {{city.CityName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            
                

                <div class="col-sm-6 col-lg-3">
                    <button type="submit" class="cmn-btn m" (click)="search()">Filter<i class='bx bx-search'></i></button>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <button type="submit" class="cmn-btn m" (click)="removefilter()">Show All<i
                            class='bx bx-search'></i></button>
                </div>

                <div style="margin-left:42%" *ngIf="jobPostsInfo[0] != null">
                    <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [boundaryLinks]="true"
                        (pageChange)="pageChanged(page)" [rotate]="false" [ellipses]="true" [maxSize]="5">
                    </ngb-pagination>
                </div>

                <div style="margin-left:42%" *ngIf="jobPostsInfo[0] == null">    
                    <h2>Coming Soon .....</h2>              
                </div>

                <!-- Employer -->
                <div class="employer-area two pb-100">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-12" *ngFor="let jobpost of obs">
                                <div class="employer-item">
                                    <!-- || jobpost.IsDisClose == true -->
                                    <img [src]="jobpost.Logo" alt="Dashboard"
                                        style="height: 12%;margin-top: 82px;margin-left: -12px;" *ngIf="jobpost.Logo">

                                        <h3>{{jobpost.JobRole}}</h3>
                                        <div class="details-inner" *ngIf="jobpost.IsDisClose == false">
                                        <span>Keshav Encon Private Limited</span>
                                        </div>
                                        <div class="details-inner" *ngIf="jobpost.IsDisClose == true">
                                            <span>{{jobpost.JobForName}}</span>
                                        </div>
                                    <ul>
                                        <li><i class="flaticon-send"></i> {{jobpost.CompanyIndustry}}</li>
                                        <li>{{jobpost.JobOpening}} Opening</li>
                                    </ul>

                                    <div>
                                        <div [ngClass]="{'limitTextHeight': isReadMore}">
                                            <p>{{jobpost.JobDescription}}
                                            </p>
                                        </div>
                                        <span class="span-one seven" (click)="showText()"
                                            style="cursor: pointer; margin-bottom: 7px;margin-left: -8px;">
                                            {{ isReadMore ? 'Read More..': 'Read Less' }}
                                        </span>
                                    </div>

                                    <div style="display:flex;flex-flow:initial;column-gap:23px;">
                                        <span *ngIf="jobpost.NoticePerioddays" class="span-one five">
                                            {{jobpost.NoticePerioddays}}
                                        </span>
                                        <span class="span-one five">
                                            {{jobpost.JobLocationCity}}
                                        </span>
                                        <span class="span-one five">{{jobpost.MinYearExp}} Year Of Experience</span>

                                        <span class="span-one five">{{jobpost.Qualification}}</span>

                                        <div *ngIf="jobpost.Salarytype == 1">
                                            <span class="span-two">Salary Range:
                                                {{jobpost.MinSalary}}Rs.-{{jobpost.MaxSalary}}Rs</span>
                                        </div>

                                        <div *ngIf="jobpost.Salarytype == 0">
                                            <span class="span-two five">Salary:
                                                {{jobpost.SalaryDesc}}
                                            
                                        </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- End Employer -->

                <!-- Footer -->
                <app-footer-style-two></app-footer-style-two>
                <!-- End Footer -->