<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <!-- <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div> -->

            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Academic Discipline</h2>

                        <form [formGroup]="AcademicDisciplineform">
                            <div class="form-group">
                                <label>Qualification</label>
                                <select class="form-control form-select" formControlName="QualificationId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.QualificationId.errors }">
                                    <option value="" disabled selected>Choose any one state</option>
                                    <option *ngFor="let qu of (allQualification | async)" [value]="qu.Id">
                                        {{qu.Qualification}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.QualificationId.errors">
                                    <div *ngIf="fcomp.QualificationId.errors.required">
                                        QualificationId is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Academic Description</label>
                                <input class="form-control" formControlName="AcademicDescription"
                                placeholder="Enter Academic Description"
                                [ngClass]="{ 'is-invalid': submittedComp && fcomp.AcademicDescription.errors }">
                            <div class="invalid-feedback" *ngIf="submittedComp && fcomp.AcademicDescription.errors">
                                <div *ngIf="fcomp.AcademicDescription.errors.required">
                                    Academic Description is required
                                </div>
                                </div>
                            </div>
                            <button (click)="AcademicDescriptionForm()" type="submit" class="btn Actionbutton">{{ActionBtn}}</button>
                            <button  (click)="Close()" type="close" class="btn Cancelbutton"> Close</button>
                        </form>
                        <!-- <div class="create-account">
                            <span class="account-direction">Create an account? <a style="font-weight: 600; color: #fe4a55;" routerLink="/register">Sign Up</a></span>
                        </div> -->

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->