<!-- Footer -->
<footer class="pt-16 ">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a class="logo" routerLink="/index.html"><img src="assets/png/finallightthme.png" alt="Logo"></a>
                        
                        <ul>
                            <li><span>Address: </span>Plot No-11, Shree Rangam Residency, GJ SH 158, Vemali, Vadodara, Gujarat 391740</li>
                            <li><span>Email</span><a href="contact@jobonfingertip.com"> contact@jobonfingertip.com</a></li>
                            <li><span>Phone: </span><a href="tel:2151234567">+917567800121,+919512345363</a></li>
                            <li><span>Open: </span>Mon - Sat / 9:00 AM - 6:00 PM</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Jobs List</h3>
                        <ul>
                          
                            <li><a  (click)="WorkFromHome(1)">Work From Home  </a></li>
                            <li><a  (click)="WorkFromHome(2)">Part Time Jobs  </a></li>
                            <li><a  (click)="WorkFromHome(3)">Jobs For Women  </a></li>
                            <li><a  (click)="WorkFromHome(4)">Jobs For Freshers  </a></li>
                            <li><a  (click)="WorkFromHome(5)">Internship  </a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-service">
                        <ul>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin-square'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <p>Copyright @2022 Reserved</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->