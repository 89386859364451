import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-viewclient',
    templateUrl: './viewclient.component.html',
    styleUrls: ['./viewclient.component.scss']
})
export class ClientViewComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    clientForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$";
    fileType: string = '';
    base64textString: string[] = [];
    allCountry: Observable<any>;
    DataSource?: any;
Id : number
    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service:SharedService<any>,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.clientForm = this.formBuilder.group({
            Id: ["",null],
            ClientName: [null, Validators.required],
            Address1: ["", [Validators.required, Validators.maxLength(150)]],
            Address2: ["", null],
            Description: ["", Validators.required],
            CountryId: ["", Validators.required],
            StateId: ["", Validators.required],
            CityId: ["", Validators.required],
            PinCode: ["", Validators.required],
            WebSite: ["", null],
            LinkedInPage: ["", null],
            firstname: [null, Validators.required],
            lastname: ["", Validators.required],
            MobileNo: ["", Validators.required],
            Logo:['',[Validators.nullValidator]],
            CountryISDCode: ['', Validators.nullValidator],

        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                
                this.Id = Number(params.get('id'));
            }
        );

        this.GetClientDataById(this.Id);
      
        this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
        this.allCountry = this.Service.Get('Country/GetCountryISDCode?Id=0', null);
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.clientForm.controls;
    }
    onchangeCountry(event) {
        this.allStates = this.Service.Get('State/GetStateByCountry?countryId=' + event, null);
    }
    onchangeCity(event) {
        this.allCities = this.Service.Get('City/GetCityDataByState?StateId=' + event, null);
    }

    GetClientDataById(Id: any) {
        this.Service.Get('Client/GetClientData?Id='+this.Id, null).subscribe(data => {
            this.DataSource = data;
            this.clientForm.controls["Id"].setValue(this.DataSource[0].Id);
            this.clientForm.controls["ClientName"].setValue(this.DataSource[0].ClientName);
            this.clientForm.controls["Address1"].setValue(this.DataSource[0].Address1);
            this.clientForm.controls["Address2"].setValue(this.DataSource[0].Address2);
            this.clientForm.controls["Description"].setValue(this.DataSource[0].Description);
            this.clientForm.controls["CountryId"].setValue(this.DataSource[0].CountryId);
            this.clientForm.controls["StateId"].setValue(this.DataSource[0].StateId);
            this.clientForm.controls["CityId"].setValue(this.DataSource[0].CityId);
            this.clientForm.controls["PinCode"].setValue(this.DataSource[0].PinCode);
            this.clientForm.controls["WebSite"].setValue(this.DataSource[0].WebSite);
            this.clientForm.controls["LinkedInPage"].setValue(this.DataSource[0].LinkedInPage);
            this.clientForm.controls["firstname"].setValue(this.DataSource[0].FirstName);

            this.clientForm.controls["lastname"].setValue(this.DataSource[0].LastName);
            this.clientForm.controls["MobileNo"].setValue(this.DataSource[0].MobileNo);
            // this.companyForm.controls["CompanyProfileName"].setValue(this.DataSource[0].CompanyProfileName);
            this.clientForm.controls["CountryISDCode"].setValue(this.DataSource[0].CountryISDCode);
            this.clientForm.controls["Logo"].setValue(this.DataSource[0].Logo);
        
            this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
            this.allCountry = this.Service.Get('Country/GetCountryISDCode?Id=0', null);
            this.allStates = this.Service.Get('State/GetStateData?Id=0', null);
            this.allCities = this.Service.Get('City/GetCity?Id=0', null);
        })
    }



    Close(){
        this.router.navigate(['/client']);  
    }



    ngOnDestroy(): void {
    }


}
