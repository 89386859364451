<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Send Message</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Send Message
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<div class="dashboard-area ">
    <div class="">
        <div class="profile-content">
            <form [formGroup]="jobPostForm">
                <div class="profile-content-inner">
                    <!-- <h2>Post a Job</h2> -->
                    <div class="row">
                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Job Role </label>
                                <input type="text" class="form-control" [formControl]="formControlObj"
                                    [matAutocomplete]="auto" placeholder="Select Job Role">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                                    (optionSelected)="onchangejobrole($event.option.value.Id)" panelWidth="auto">
                                    <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                        {{jobrole.JobRole}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Job Title</label>
                                <input class="form-control" formControlName="JobTitle" placeholder="Enter Job Title"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobTitle.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobTitle.errors">
                                    <div *ngIf="fjob.JobTitle.errors.required">
                                        Job Title is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Qualification</label>
                                <input class="form-control textarea" formControlName="Qualification"
                                    placeholder="Enter Qualification"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Qualification.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Qualification.errors">
                                    <div *ngIf="fjob.Qualification.errors.required">
                                        Qualification is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Experience</label>
                                <input class="form-control textarea" formControlName="Experience"
                                    placeholder="Enter Experience"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Experience.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Experience.errors">
                                    <div *ngIf="fjob.Experience.errors.required">
                                        Experience is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Candidate</label>
                                <mat-select #select multiple formControlName="MobileNo" class="form-control" placeholder="Select Candidate">
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection()">Select All</mat-checkbox>
                                    </div>
                                    <mat-option (onSelectionChange)="getValues($event)"
                                        *ngFor="let Candidate of (allcandidate | async)" [value]="Candidate.MobileNo">
                                        {{Candidate.FirstName}} {{Candidate.LastName}} </mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Job Type</label>
                                <input class="form-control textarea" formControlName="JobType"
                                    placeholder="Enter Job Type"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobType.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobType.errors">
                                    <div *ngIf="fjob.JobType.errors.required">
                                        JobType is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Job Duration</label>
                                <input class="form-control textarea" formControlName="JobDuration"
                                    placeholder="Enter Job Duration"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobDuration.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobDuration.errors">
                                    <div *ngIf="fjob.JobDuration.errors.required">
                                        Job Duration is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Job Location</label>
                                <input class="form-control textarea" formControlName="JobLocation"
                                    placeholder="Enter Job Location"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobLocation.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobLocation.errors">
                                    <div *ngIf="fjob.JobLocation.errors.required">
                                        Job Location is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12"></div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Payroll</label>
                                <input class="form-control textarea" formControlName="Payroll"
                                    placeholder="Enter Payroll"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Payroll.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Payroll.errors">
                                    <div *ngIf="fjob.Payroll.errors.required">
                                        Payroll is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Recruiter Email</label>
                                <input class="form-control textarea" formControlName="Email"
                                    placeholder="Enter Recruiter Email"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Email.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Email.errors">
                                    <div *ngIf="fjob.Email.errors.required">
                                        Email is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-12">
                            <div class="form-group">
                                <label>Recruiter Mobile Number</label>
                                <input class="form-control textarea" formControlName="ContactNo"
                                    placeholder="Enter Recruiter Mobile Number"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.ContactNo.errors }">
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.ContactNo.errors">
                                    <div *ngIf="fjob.ContactNo.errors.required">
                                        ContactNo is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-12"></div>

                        <div class="col-lg-9 col-md-12">
                            <div class="form-group">
                                <label>Other</label>
                                <textarea class="form-control textarea" formControlName="Others"
                                    placeholder="Enter Other Description" style="height: 90px;"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Other.errors }"></textarea>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Other.errors">
                                    <div *ngIf="fjob.Other.errors.required">
                                        Other is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12"></div>
                        <button class="filterbtn" (click)="sendData()">
                            Send
                        </button>

                    </div>
                </div>
            </form>


        </div>
    </div>
</div>


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->