<div class="dashboard-area ptb-50">
  <div class="container">

    <div class="profile-content-inner">
      <h4>{{title}} </h4>
    </div>
    <form [formGroup]="inputForm">

    <div class="row">
   <div class="col-lg-4 col-md-12">
    <div class="form-group">
      <label>Currency</label>
      <select class="form-control form-select" formControlName="CurrencyId"
          [ngClass]="{ 'is-invalid': submitted && fcomp.CurrencyId.errors }">
          <option *ngFor="let Currency of (allCurrency | async)" [value]="Currency.Id">
              {{Currency.CurrencyCode}}
          </option>
      </select>
      <div class="invalid-feedback" *ngIf="submitted && fcomp.CurrencyId.errors">
          <div *ngIf="fcomp.CurrencyId.errors.required">
              Currency is required
          </div>
      </div>
  </div>

  </div>
  <div class="col-lg-8 col-md-12">
    <div class="form-group">
      <label>Annual CTC</label>
      <input class="form-control" formControlName="CTC" placeholder="Enter Your CTC"
          [ngClass]="{ 'is-invalid': submitted && fcomp.CityName.errors }">
      <div class="invalid-feedback" *ngIf="submitted && fcomp.CTC.errors">
          <div *ngIf="fcomp.CTC.errors.required">
            Annual CTC is required
          </div>
      </div>
  </div>
</div>

<div class="col-lg-12 col-md-12">
<div class="form-group">
  <label>PayRoll</label>
  <select class="form-control form-select" formControlName="PayRoll"
      [ngClass]="{ 'is-invalid': submitted && fcomp.PayRoll.errors }">
      <option *ngFor="let PayRoll of (allPayRoll | async)" [value]="PayRoll.ListIndex">
          {{PayRoll.MessageText}}
      </option>
  </select>
  <div class="invalid-feedback" *ngIf="submitted && fcomp.PayRoll.errors">
      <div *ngIf="fcomp.PayRoll.errors.required">
        PayRoll required
      </div>
  </div>
</div>
</div>


</div>
</form>

      <button class="Actionbutton btn" (click)="onConfirm()">
       {{btnMessage}}
      </button>

      <button class="Cancelbutton btn" (click)="onCancel()">
        Cancel
      </button>
    </div>

  </div>




<!-- <div class="dashboard-area ptb-50">
  <div class="container">

    <div class="profile-content-inner">
      <h4>Enter Text </h4>
    </div>
    <div class="row">
      <div class="form-group">
        <textarea type="text" id="Address1" class="form-control"></textarea>
      </div>

      <button class="Actionbutton btn">
        Send Message
      </button>
    </div>

  </div>
</div> -->
<!-- <ng-template #mymodal let-modal>
  <div class="modal-body">
    <textarea placeholder="Enter Message"></textarea>

  </div>
  <div >
    This is example from ItSolutionStuff.com
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template> -->