import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { City, JobRole, Qualificationa } from 'src/app/interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-employers',
  templateUrl: './employers.component.html',
  styleUrls: ['./employers.component.scss']
})
export class EmployersComponent implements OnInit {

  jobPostsInfo = [];
  allJobRole: any;
  allQualification: Observable<Qualificationa[]>;
  allcities: Observable<City[]>
  
  obs: any;
  dataFilter1: number[] = [];
  dataFilter2: number[] = [];
  candidateform: FormGroup;
  data: any;
  citycontrol = new FormControl();
  EducationControl = new FormControl();
  jobRoleControl = new FormControl();
  datasource: any;
  allCompanyName: Observable<any>;
  gender: number = 0;
  parttime: number = 0;
  workFromHome: number = 0;
  frehsherjob: number = 0;
  shifttype: number = 0;
  Internshipjob: number = 0;
  company: number = 0;
  Education: number = 0;

  jobType: number = 0;
  city: number = 0;
  page: number = 1;
  pagesize: number = 10;
  totalItems: number = 0;
  datapageInfo: any;

  jobrole :number;
  homecity :number;
  education :number


  constructor(
    // private jobsService: JobsService,
    private Service: SharedService<any>,
    private router: Router,
    public Global: GlobalService,
    private formBuilder: FormBuilder,

  ) {
     this.data = this.router.getCurrentNavigation().extras;
        if (this.data.queryParams != null) {
          if (this.data.queryParams.myArray[0] == "searchString") {
            this.dataFilter2 = [];
            this.jobRoleControl.setValue(this.data.queryParams.myArray[1]);
            this.citycontrol.setValue(this.data.queryParams.myArray[2]);
            this.EducationControl.setValue(this.data.queryParams.myArray[3]);
            this.dataFilter2.push(this.data.queryParams.myArray[1]);
            this.dataFilter2.push(this.data.queryParams.myArray[2]);
            this.dataFilter2.push(this.data.queryParams.myArray[3]);
            this.dataFilter2.push(this.data.queryParams.myArray[4]);
            this.dataFilter2.push(this.data.queryParams.myArray[5]);
            this.dataFilter2.push(this.data.queryParams.myArray[6]);
            this.dataFilter2.push(this.data.queryParams.myArray[7]);
            this.dataFilter2.push(this.data.queryParams.myArray[8]);
            this.dataFilter2.push(this.data.queryParams.myArray[9]);
            this.refreshListFilter();
            this.jobrole = this.dataFilter2[0];
           this.homecity = this.dataFilter2[1];
           this.education = this.dataFilter2[2];
            // this.getAllJobs();
          }
        }
        else if (this.data.queryParams == null) {
          this.dataFilter2 = [];
          this.refreshListFilter();
        }
  }

  ngOnInit(): void {

    this.candidateform = this.formBuilder.group({
      jobtype: ['', Validators.nullValidator],
      city: ['', Validators.nullValidator],
  });

    this.refreshListFilter();
    this.getAllQualification();
    this.refreshListOfCompany();
    this.getAllCity();
    this.getAllJobRole();
    
  }

  pageChanged(value: any) {
    this.page = value;
    this.search();
  }

  getAllJobRole() {
    this.Service.Get('JobRole/GetJobRoleById?Id='+ this.jobrole, null).subscribe(jobrole => {
      this.allJobRole = jobrole;
    })
  }
  getAllQualification() {
    this.Service.Get('Qualification/GetQualificationById?Id='+this.education, null).subscribe(Qualificationa => {
      this.allQualification = Qualificationa;
    })
  }

  getAllCity() {
    this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
      this.allcities = city;
    })
  }

  refreshListOfCompany() {
    this.Service.Get('Company/GetCompanyDataSelection', null).subscribe(company => {
      this.allCompanyName = company;
    });
  }

  shift() {
    this.shifttype = 1;
    this.gender = 0;
    this.parttime = 0;
    this.frehsherjob = 0;
    this.workFromHome = 0;
    this.Internshipjob = 0;
    this.search();
  }

  search() {

    this.dataFilter2 = [];
    this.dataFilter2.push(Number(this.jobrole));
    this.dataFilter2.push(Number(this.homecity));
    this.dataFilter2.push(Number(this.education));
    this.dataFilter2.push(this.city);
    this.dataFilter2.push(this.workFromHome);
    this.dataFilter2.push(this.gender);
    this.dataFilter2.push(this.parttime);
    this.dataFilter2.push(this.frehsherjob);
    this.dataFilter2.push(this.Internshipjob);
    this.refreshListFilter();
  }

  refreshListFilter() {
    this.dataFilter2.push(this.page - 1);
    this.dataFilter2.push(this.pagesize);
    this.datasource = null;
    this.Service.Post('Job/GetJobsCardData', this.dataFilter2).subscribe(res => {
      this.jobPostsInfo = res[0];
      this.obs = this.jobPostsInfo;
      this.datapageInfo = res[1];
      this.totalItems = this.datapageInfo[0].length;
    })
  }
  cityselect(event: any) {
    this.city = event;
    this.search();
  }
  
  SelectJobType(event: any) {    
    if (event == 'Work From Home') {
      this.workFromHome = 1;
      this.gender = 0;
      this.parttime = 0;
      this.frehsherjob = 0;
      this.Internshipjob = 0;
      this.search();
    }
    else if (event == 'Part Time') {
      this.parttime = 1;
      this.workFromHome = 0;
      this.gender = 0;
      this.frehsherjob = 0;
      this.Internshipjob = 0;
      this.search();
    }
    else if (event == 'Jobs For Women') {
     this.workFromHome = 0;
      this.gender = 1;
      this.parttime = 0;
      this.frehsherjob = 0;
      this.Internshipjob = 0;
      this.search();
    }
    else if (event == 'Jobs For Freshers') {

       this.workFromHome = 0;
      this.frehsherjob = 1;
      this.gender = 0;
      this.parttime = 0;
      this.Internshipjob = 0;
      this.search();
    }
    else if (event == 'Internship') {
      this.workFromHome = 0;
      this.frehsherjob = 0;
      this.gender = 0;
      this.parttime = 0;
      this.Internshipjob = 1;
      this.search();
    }

  }
  // AllFilters() {
  //   if (this.workFromHome == 1) {
  //     this.workFromHome = 1;
  //     this.parttime = 0;
  //     this.gender = 0;
  //     this.frehsherjob = 0;
  //     this.Internshipjob = 0;

  //     this.search();

  //   }
  //   else if (this.parttime == 1) {
  //     this.workFromHome = 0;
  //     this.parttime = 1;
  //     this.gender = 0;
  //     this.frehsherjob = 0;
  //     this.Internshipjob = 0;
  //     this.search();
  //   }
  //   else if (this.gender == 1) {
  //     this.gender = 1;
  //     this.frehsherjob = 0;
  //     this.workFromHome = 0;
  //     this.parttime = 0;
  //     this.Internshipjob = 0;
  //     this.search();


  //   }

    // else if (this.shifttype == 1) {
    //   this.shifttype = 1;
    //   this.workFromHome = 0;
    //   this.parttime = 0;
    //   this.gender = 0;
    //   this.frehsherjob = 0;
    //   this.company;
    //   this.Education;
    //   this.Internshipjob = 0;


    //   this.search();
    // }

    // else if (this.frehsherjob == 1) {
    //   this.workFromHome = 0;
    //   this.parttime = 0;
    //   this.gender = 0;
    //   this.frehsherjob = 1;
    //   this.Internshipjob = 0;


    //   this.search();
    // }
    // else if (this.Internshipjob == 1) {
    //   this.workFromHome = 0;
    //   this.parttime = 0;
    //   this.gender = 0;
    //   this.frehsherjob = 0;
    //   this.Internshipjob = 1;
    //   this.search();
    // }
    // else {
    //   this.workFromHome = 0;
    //   this.parttime = 0;
    //   this.gender = 0;
    //   this.frehsherjob = 0;
    //   this.Internshipjob = 0;


    //   this.search();


    // }


  // }

  removefilter() {
    this.workFromHome = 0;
    this.parttime = 0;
    this.gender = 0;
    this.frehsherjob = 0;
    this.Internshipjob = 0;
    this.jobrole = 0;
    this.homecity = 0;
    this.education = 0
    this.dataFilter2 = [0];
       this.dataFilter2.push(0);
        this.dataFilter2.push(0);
        this.dataFilter2.push(0);
        this.dataFilter2.push(0);
        this.dataFilter2.push(0);
        this.dataFilter2.push(0);
        this.dataFilter2.push(0);
        this.dataFilter2.push(0);
      
      
        this.allJobRole = null;
        this.allQualification = null;
        this.refreshListFilter();
        this.candidateform.reset()
  }

  isReadMore = true

  showText() {
      this.isReadMore = !this.isReadMore
  }


}
