import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Currency } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../config-dialog/confing-dialog.component';

@Component({
    selector: 'app-currency',
    templateUrl: './currency.component.html',
    styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit, OnDestroy {

    jobPostsInfo = [];

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
        private dialog: MatDialog,
    ) { }


    displayedColumns = [ "CurrencyCode", "CurrencyName","CurrencySymbol","IsActive","Edit"];
    message: string = '';
    result: string = '';
    dataSource = new MatTableDataSource<Currency>();
    CityData: any;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    
    ngOnInit(): void {
        this.refreshList();
    }

    refreshList() {
        this.service.Get('Currency/GetCurrency?Id=0', null).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      }

    AddCurrency() {
        this.router.navigate(['addcurrency', 0])
    }

    EditCurrency(row: any) {
        this.router.navigate(['addcurrency', row.Id]);
    }
    
      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }

      
  IsActive(row: any) {
    this.confirmDialog(row)
  }

  IsActiveContry(row: any) {
    this.service.Put('Currency/UpdateActiveStatus', row).subscribe({
      next: (res) => {
        if (row.IsActive == true) {
          this.toastr.success('Currency inactive sucessfully');
        }
        else {
          this.toastr.success('Currency active sucessfully');
        }
        this.refreshList();
      },

    })
  }

  confirmDialog(row: any) {
    
      if (row.IsActive == true) {
        this.message = 'Are you sure want to inactive Currency ' + row.CurrencyName + ' ?';
      }
      else {
        this.message = 'Are you sure want to active Currency ' + row.CurrencyName + ' ?';
      }

      const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

      const dialogRef = this.dialog.open(ConfigDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == true) {
          this.IsActiveContry(row);
        
      }
  
    })
  }

  showIcon(index: boolean): string {
    if (index === true) {
      return 'toggle_on';
    } else {
      return 'toggle_off';
    }
  }

    ngOnDestroy(): void {

    }
   
}
