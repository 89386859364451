<!-- Navbar -->
<!-- <app-navbar-style-three></app-navbar-style-three> -->
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2> Jobs</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li> Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <!-- <ul class="job-list-item align-items-center">
                    <li>
                        <a href="#">Results Found</a>
                    </li>
                    
                </ul> -->

                <div style="margin-left:42%">
                    <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [boundaryLinks]="true"
                    (pageChange)="pageChanged(page)" [rotate]="false" [ellipses]="true" [maxSize]="5">
                   </ngb-pagination>
                </div>

                    <div class="employer-item"  *ngFor="let jobpost of obs" (click)="JobClick(jobpost)">
                            <img [src]="jobpost.Logo" alt="Dashboard" style="height: 8%;margin-top: 57px;
                            margin-left: -12px;" *ngIf="jobpost.Logo">

                            <h3>{{jobpost.JobRole}}</h3>
                            <span>{{jobpost.CompanyName}}</span>

                            <ul>
                                <li><i class="flaticon-send"></i> {{jobpost.CompanyIndustry}}</li>
                                <li>{{jobpost.JobOpening}} Opening</li>

                            </ul>

                            <p>{{jobpost.JobDescription}}</p>

                            <div style="display:flex;flex-flow:initial;column-gap:23px;">

                          
                            
                            <span  *ngIf="jobpost.NoticePerioddays" class="span-one five">
                                {{jobpost.NoticePerioddays}}
                            </span>
                            <span class="span-one five">
                                {{jobpost.JobLocationCity}}
                            </span>
                            <span class="span-one five">{{jobpost.MinYearExp}} Experinece</span>

                            <span class="span-one five">{{jobpost.Qualification}}</span>

                            <div *ngIf="jobpost.Salarytype == 1">
                                <span class="span-two">Salary Range:
                                    {{jobpost.MinSalary}}Rs.-{{jobpost.MaxSalary}}Rs</span>
                            </div>

                            <div *ngIf="jobpost.Salarytype == 0">
                                <span class="span-two five">
                                    {{jobpost.SalaryDesc}}
                                
                            </span>
                            </div>
                        </div>
                    </div>
                  
            </div>

            <!-- <div class="col-lg-4 col-md-12"> 
                <div class="job-list-right">
                    <div class="job-list-all">
                        <div class="search">
                            <h3>Search Keywords</h3>
                            <form>
                                <input type="text" class="form-control" placeholder="Keyword">
                                <button type="submit" class="btn"><i class="flaticon-send"></i></button>
                            </form>
                            <h3>JobRole</h3>
                            <form>
                                <ul class="job-cmn-cat">
                                    <li *ngFor="let JobRole of allJobRole">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" [formControl]="jobRoleControl">
                                            <label class="form-check-label" for="defaultCheck1">{{JobRole.JobRole}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <div class="job-type cmn-padding">
                                <h3>City</h3>
    
                                <form>
                                    <ul class="job-cmn-cat">
                                        <li *ngFor="let cities of allCities">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="defaultCheck16" [formControl]="citycontrol">
                                                <label class="form-check-label" for="defaultCheck16"> {{cities.CityName}}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                        <div class="location-list cmn-padding">
                            <h3>Education</h3>

                            <form>
                                <ul class="job-cmn-cat">
                                    <li *ngFor="let Qualification of allQualification">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                id="defaultCheck10" [formControl]="EducationControl">
                                            <label class="form-check-label" for="defaultCheck10">{{Qualification.Qualification}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                </div>
            </div> 
        </div> -->
    </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
