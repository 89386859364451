import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AcademicDiscipline, Area, CompanyInformation, Currency, DocumentsList, JobRole, Qualificationa, Skills, Specialization, SpecialQualificationa, ListValuesMap, UserType, cities, Country, State, Qualification } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { map, startWith } from 'rxjs/operators';

export class Model {
  value1: number;
}
export class POstJobSpecialization {
  SpecializationId: number[];
  PostJobId: number;
}

export class POstJobList {
  JobCompanyName: string;
  Id: number;
  SpecializationDescription: string
  PostJobId: number;
}


@Component({
  selector: 'app-post-a-job',
  templateUrl: './post-a-job.component.html',
  styleUrls: ['./post-a-job.component.scss']
})
export class PostAJobComponent implements OnInit, OnDestroy {
  @ViewChild('select') select: MatSelect;

  // @ViewChild('nextField') nextField: ElementRef;

  // onEnter(event: KeyboardEvent) {
  //   if (event.key == 'Enter') {
  //     this.nextField.nativeElement.focus();
  //   }
  // }




  submittedJob: boolean = false;

  jobPostForm: FormGroup;
  public jobPostInfo = [];

  response: any;
  showMe: boolean = false;
  displayMe: boolean = false;
  hideMe: boolean = false;
  appearMe: boolean = true;


  // allJobRole: Observable<JobRole[]>;
  allareas: Observable<Area[]>;
  //allQualification?: Observable<Qualificationa[]>;
  allspecialqualification?: Observable<SpecialQualificationa[]>;
  allDocuments?: Observable<DocumentsList[]>;
  allSkills?: Observable<Skills[]>;
  allCurrency?: Observable<Currency[]>;
  allGender?: Observable<any[]>;
  allSalarytype?: Observable<any[]>;
  allJobType?: Observable<any[]>;
  allTypeofjob?: Observable<any[]>;
  allExperienceType?: Observable<any[]>;
  allWorkingDays?: any[];
  allDutyType?: any[];

  allJobLocationType: Observable<any[]>;
  allworkinghpurs: Observable<any[]>;
  allShiftType?: any[];
  allNoticePeriod?:any[];

  //alldiscipline: Observable<AcademicDiscipline[]>;
  allspecialization: Observable<POstJobList[]>;
  //allCompanyName: Observable<CompanyInformation>;
  //allJobFor: Observable<CompanyInformation>;
  allrecruiter: Observable<CompanyInformation>;
  usertype: number;
  internshipvalue: boolean = true;
  locationValue: number = 2;
  stipend: boolean = false;
  salary: boolean = true;
  selectedStatus: number = 0;
  companydata: CompanyInformation;
  Company: number;
  CompanyAddress1: string = '';
  allResumebuild: CompanyInformation;
  selectCompanyID: number;
  Country: number;
  salarytype: boolean = false;
  apiid: any
  allSelected = false;

  specializationsId: number[] = [];
  specializationlist: POstJobList[];
  sendspcId: POstJobSpecialization;

  allJobRole: JobRole[];
  filterJobRole?: Observable<JobRole[]>;
  formControlObj = new FormControl();
  JobRoleId: number;

  allCountries: Country[]
  filterCountries?: Observable<Country[]>;
  formCountries = new FormControl();
  CountriesId: number;

  allStates: State[];
  filterStates?: Observable<State[]>;
  formStates = new FormControl();
  StatesId: number;

  allCities: any[];
  filterCities?: Observable<any[]>;
  formCities = new FormControl();
  CityId: number;

  alldiscipline: AcademicDiscipline[];
  filterdiscipline?: Observable<AcademicDiscipline[]>;
  formControldiscipline = new FormControl();
  disciplineId: number;

  allCompanyName: any;
  filterCompanyName?: Observable<CompanyInformation[]>;
  formControlCompanyName = new FormControl();
  JobCompanyName: any;
  selectCompany: any;

  allJobFor:  CompanyInformation[];
  filterJobFor?: Observable<CompanyInformation[]>;
  formControlJobFor = new FormControl();
  JobForId: any;

  allColumns: any;
  formControlForjoining: FormControl;
  Joiningid : number;
  myForm: FormGroup;

  allQualification: Qualification[];
  filterQualification?: Observable<Qualification[]>;
  formControlQualificationine = new FormControl();
  QualificationId: number;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private service: SharedService<any>,
    private router: Router,
    private Global: GlobalService,
  ) { 

  }

  ngOnInit(): void {
    this.jobPostForm = this.formBuilder.group({
      CompanyId: ['', Validators.nullValidator],
      JobCompanyName: ['', Validators.required],
      JobRoleId: ['', Validators.required],
      JobLocation: ['', Validators.required],
      JobLocationCity: ['', Validators.nullValidator],
      JobType: ['', Validators.required],
      CurrencyId: ['', Validators.nullValidator],
      MinSalary: ['', Validators.nullValidator],
      MaxSalary: ['', Validators.nullValidator],
      JobOpening: ['1', Validators.required],
      ReqQualification: ['', Validators.required],
      ExperienceType: ['', Validators.required],
      ExperienceTypeName: ['', Validators.nullValidator],
      JobShift: ['', Validators.required],
      JobShiftName: ['', Validators.nullValidator],
      MinYearExp: ['', Validators.nullValidator],
      Gender: ['', Validators.required],
      JobDescription: ['', Validators.required],
      Country: ['', [Validators.required]],
      State: ['', [Validators.required]],
      WorkingDays: ['', Validators.required],
      CompanyAddress: ['', Validators.required],
      Typeofjob: ['', Validators.required],
      SubTypeofJob: ['', Validators.nullValidator],
      DutyType: ['', Validators.required],
      DutyTypeName: ['', Validators.nullValidator],
      NoticePeriod: ['', Validators.required],
      NoticePerioddays: ['', Validators.nullValidator],
      Timing: ['', Validators.required],
      JobTiming: ['', Validators.nullValidator],
      JobLocationType: ['2', Validators.required],
      IsInternShip: ['false', Validators.required],
      AcademicDisciplineId: ['', Validators.required],
      RecruiterId: ['', Validators.required],
      SpecializationId: ['', Validators.required],
      Salarytype: ['', Validators.required],
      TargetDate: ['', Validators.required],
      JobSpecification: ['', Validators.required],
      JobFor: ['', Validators.nullValidator],
      IsDisClose: ['false', Validators.required],
    })
  
    this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        this.formControlObj.setValue(this.allJobRole[0]);
        this.filterJobRole = this.formControlObj.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
        );
        this.onchangejobrole(this.allJobRole[0].Id);
      }
    });

    this.service.Get('Country/GetCountry?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allCountries = data;
        this.filterCountries = this.formCountries.valueChanges.pipe(
          startWith<string | Country>(''),
          map(value => typeof value === 'string' ? value : value.CountryName),
          map(CountryName => CountryName ? this._filterCountry(CountryName) : this.allCountries.slice()),
        );
      }
    });

    this.service.Get('Company/GetCompanyDataSelection', null).subscribe(data => {
      if (data != null) {
        this.allCompanyName = data;
        this.formControlCompanyName.setValue(this.allCompanyName[0]);
        this.filterCompanyName = this.formControlCompanyName.valueChanges.pipe(
          startWith<string | CompanyInformation>(''),
          map(value => typeof value === 'string' ? value : value.CompanyName),
          map(CompanyName => CompanyName ? this._filterCompany(CompanyName) : this.allCompanyName.slice()),
        );
        this.onchangecompany(this.allCompanyName[0])
      }
    });
    
    this.service.Get('Client/GetClientData?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobFor = data;
        this.formControlJobFor.setValue(this.allJobFor[0]);
        this.filterJobFor = this.formControlJobFor.valueChanges.pipe(
          startWith<string | CompanyInformation>(''),
          map(value => typeof value === 'string' ? value : value.ClientName),
          map(ClientName => ClientName ? this._filterJobFor(ClientName) : this.allJobFor.slice()),
        );
        this.onchangejobfor(this.allJobFor[0].Id)
      }
    });

    this.formControlForjoining = new FormControl(this.allColumns);

    this.service.Get('GetListValue?Id=' + ListValuesMap.NoticePeriods, null).subscribe({
      next: (res) => {
        this.allNoticePeriod = res;
        this.jobPostForm.controls["NoticePeriod"].setValue(this.allNoticePeriod[1].ListIndex);
      }
    });

    this.service.Get('GetListValue?Id=' + ListValuesMap.JobType, null).subscribe({
      next: (res) => {
        this.allJobType = res;
        this.jobPostForm.controls["JobType"].setValue(this.allJobType[0].ListIndex);
      }
    });

    this.service.Get('GetListValue?Id=' + ListValuesMap.TypeOfJob, null).subscribe({
      next: (res) => {
        this.allTypeofjob = res;
        this.jobPostForm.controls["Typeofjob"].setValue(this.allTypeofjob[1].ListIndex);
      }
    });

    this.service.Get('GetListValue?Id=' + ListValuesMap.ShiftType, null).subscribe({
      next: (res) => {
        this.allShiftType = res;
        this.jobPostForm.controls["JobShift"].setValue(this.allTypeofjob[0].ListIndex);
      }
    });

    this.service.Get('GetListValue?Id=' + ListValuesMap.JobWokingHours, null).subscribe({
      next: (res) => {
        this.allworkinghpurs = res;
        this.jobPostForm.controls["Timing"].setValue(this.allworkinghpurs[0].ListIndex);
      }
    });

    this.service.Get('GetListValue?Id=' + ListValuesMap.WorkingDays, null).subscribe({
      next: (res) => {
        this.allWorkingDays = res;
        this.jobPostForm.controls["WorkingDays"].setValue(this.allWorkingDays[0].ListIndex);
      }
    });
 
    this.service.Get('GetListValue?Id=' + ListValuesMap.DutyType, null).subscribe({
      next: (res) => {
        this.allDutyType = res;
        this.jobPostForm.controls["DutyType"].setValue(this.allDutyType[0].ListIndex);
      }
    });

    this.service.Get('GetListValue?Id=' + ListValuesMap.ExperienceType, null).subscribe({
      next: (res) => {
        this.allExperienceType = res;
        this.jobPostForm.controls["ExperienceType"].setValue(this.allExperienceType[1].ListIndex);
      }
    });

    this.service.Get('GetListValue?Id=' + ListValuesMap.Salarytype, null).subscribe({
      next: (res) => {
        this.allSalarytype = res;
        this.jobPostForm.controls["Salarytype"].setValue(this.allSalarytype[0].ListIndex);
      }
    });

    this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allQualification = data;
        this.formControlQualificationine.setValue(this.allQualification[2]);
        this.filterQualification = this.formControlQualificationine.valueChanges.pipe(
          startWith<string | Qualification>(''),
          map(value => typeof value === 'string' ? value : value.Qualification),
          map(Qualification => Qualification ? this._filterQualification(Qualification) : this.allQualification.slice()),
        );
        this.onchangeQualification(this.allQualification[2].Id)
      }
    });

    this.jobPostForm.controls["JobLocationType"].setValue(this.selectedStatus);
    this.usertype = this.Global.GetUserType();
    this.allCurrency = this.service.Get('Currency/GetCurrency?Id=0', null);
    this.allSkills = this.service.Get('Skills/GetSkills?Id=0', null);
    this.allGender = this.service.Get('GetListValue?Id=' + ListValuesMap.Gender, null);
    this.allworkinghpurs = this.service.Get('GetListValue?Id=' + ListValuesMap.JobWokingHours, null);
    this.allExperienceType = this.service.Get('GetListValue?Id=' + ListValuesMap.ExperienceType, null);
    this.allJobLocationType = this.service.Get('GetListValue?Id=' + ListValuesMap.JobLocationType, null);
    this.allrecruiter = this.service.Get('Recruiter/GetActiveRecruiterData', null);
    this.jobPostForm.controls["JobCompanyName"].setValue(this.Global.GetCompanyName());
    this.sendspcId = new POstJobSpecialization();
  }

  get fjob(): { [key: string]: AbstractControl } {
    return this.jobPostForm.controls;
  }

  private _filterRole(JobRole: string): JobRole[] {
    const filterValue = JobRole.toLowerCase();
    return this.allJobRole.filter(
      option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForRole(value: any) {
    return value ? value.JobRole : undefined;
  }

  private _filterCountry(CountryName: string): Country[] {
    const filterValue = CountryName.toLowerCase();
    return this.allCountries.filter(
      option => option.CountryName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForCountry(value: any) {
    return value ? value.CountryName : undefined;
  }

  private _filterQualification(Qualification: string): Qualification[] {
    const filterValue = Qualification.toLowerCase();
    return this.allQualification.filter(
      option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForQualification(value: any) {
    return value ? value.Qualification : undefined;
  }

  private _filterState(StateName: string): State[] {
    const filterValue = StateName.toLowerCase();
    return this.allStates.filter(
      option => option.StateName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForState(value: any) {
    return value ? value.StateName : undefined;
  }

  private _filterCity(CityName: string): any[] {
    const filterValue = CityName.toLowerCase();
    return this.allCities.filter(
      option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForCity(value: any) {
    return value ? value.CityName : undefined;
  }

  private _filterCompany(CompanyName: string): CompanyInformation[] {
    const filterValue = CompanyName.toLowerCase();
    return this.allCompanyName.filter(
      option => option.CompanyName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForCompany(value: any) {
    return value ? value.CompanyName : undefined;
  }

  private _filterJobFor(ClientName: string): CompanyInformation[] {
    const filterValue = ClientName.toLowerCase();
    return this.allJobFor.filter(
      option => option.ClientName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForJobFor(value: any) {
    return value ? value.ClientName : undefined;
  }

  onchangejobrole(event) {
    this.JobRoleId = event
  }

  onchangejobfor(event) {
    this.JobForId = event
  }


  jobPostInfoForm() {
    this.submittedJob = true;
    this.jobPostForm.controls["JobRoleId"].setValue(this.JobRoleId);
    this.jobPostForm.controls["Country"].setValue(this.CountriesId);
    this.jobPostForm.controls["State"].setValue(this.StatesId);
    this.jobPostForm.controls["JobLocation"].setValue(this.CityId);
    this.jobPostForm.controls["AcademicDisciplineId"].setValue(this.disciplineId);
    this.jobPostForm.controls["JobCompanyName"].setValue(this.JobCompanyName);
    this.jobPostForm.controls["JobFor"].setValue(this.JobForId);
    this.jobPostForm.controls["ReqQualification"].setValue(this.QualificationId);
    if (this.Global.UserType == UserType.Recruiter)
    {
      this.jobPostForm.controls["RecruiterId"].setValue(this.QualificationId);
    }

    if (this.jobPostForm.invalid) 
    {
      this.toastr.error("Please fill out the required fields (* fields)");
    }
    else
     {
      this.allspecialization.subscribe((value: POstJobList[]) => {
        this.specializationlist = value;
        if (this.allSelected) {
          for (var index in this.specializationlist) {
            this.specializationsId.push(this.specializationlist[index].Id);
          }
          this.sendspcId.SpecializationId = this.specializationsId;
          this.jobPostForm.controls["CompanyId"].setValue(this.selectCompanyID);
          const Job = this.jobPostForm.getRawValue();
          Job.IsInternShip = !this.internshipvalue;
          Job.JobLocationType = this.locationValue;
          
          Job.SpecializationId = this.sendspcId.SpecializationId = this.specializationsId
          this.service.Post('Job/InsertJobPostData', Job).subscribe(
            (res) => {
              this.toastr.success(res);
              this.jobPostForm.reset();
              this.router.navigate(['managejobtable']);
            },
            (error) => {
              this.toastr.error(error.error);
            });
          this.sendspcId.SpecializationId = [];
          this.submittedJob = false;
        }
        else {
          this.sendspcId.SpecializationId = this.specializationsId;
          this.jobPostForm.controls["CompanyId"].setValue(this.selectCompanyID);
          const Job = this.jobPostForm.getRawValue();
          Job.IsInternShip = !this.internshipvalue;
          Job.JobLocationType = this.locationValue;
          if (this.Global.UserType == UserType.Recruiter) {
            Job.RecruiterId = this.Global.getRecruiterId()
          }
          Job.SpecializationId = this.sendspcId.SpecializationId = this.specializationsId

          this.service.Post('Job/InsertJobPostData', Job).subscribe(
            (res) => {
              this.toastr.success(res);
              this.jobPostForm.reset();
              this.router.navigate(['managejobtable']);
            },
            (error) => {
              this.toastr.error(error.error);
            });
          this.sendspcId.SpecializationId = [];
          this.submittedJob = false;
        }
      });
    }
  }

  onchangeQualification(Qualification: any)
  {
    this.QualificationId = Qualification;
    this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' +  this.QualificationId, null).subscribe(data => {
      if (data != null) {
        this.alldiscipline = data;
        this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
          startWith<string | AcademicDiscipline>(''),
          map(value => typeof value === 'string' ? value : value.AcademicDescription),
          map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
        );
      }
    })
  }

  private _filterdiscipline(AcademicDescription: string): AcademicDiscipline[] {
    const filterValue = AcademicDescription.toLowerCase();
    return this.alldiscipline.filter(
      option => option.AcademicDescription?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnFordiscipline(value: any) {
    return value ? value.
      AcademicDescription : undefined;
  }

  onchangeCountry(event) {
    this.CountriesId = event
    this.service.Get('State/GetStateByCountry?countryId=' + this.CountriesId, null).subscribe(data => {
      if (data != null) {
        this.allStates = data;
        this.filterStates = this.formStates.valueChanges.pipe(
          startWith<string | State>(''),
          map(value => typeof value === 'string' ? value : value.StateName),
          map(StateName => StateName ? this._filterState(StateName) : this.allStates.slice()),
        );
      }
    })

    this.Country = event;
    if (this.Country == 1) {
      this.jobPostForm.controls["CurrencyId"].setValue(68);
    }
    else {
      this.jobPostForm.controls["CurrencyId"].setValue(0);
    }
  }

  onchangesalary(event)
   {
    if (event == 0) {
      this.salarytype = false;
    }
    else 
    {
      this.salarytype = true;
    }
  }

  onchangeState(event)
   {
    this.StatesId = event;
    this.service.Get('City/GetCityDataByState?StateId=' + this.StatesId, null).subscribe(data => {
      if (data != null) {
        this.allCities = data;
        this.filterCities = this.formCities.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.CityName),
          map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice()),
        );
      }
    })
  }

  onchangeCity(event) 
  {
    this.CityId = event;
  }

  onchangeDiscipline(event) {
    this.disciplineId = event
    this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.disciplineId, null);
  }

  onchangecompany(event) {
    this.JobCompanyName = event.CompanyName;
    this.jobPostForm.controls["CompanyAddress"].setValue(event.Address1 + ' ' + event.Address2);
    this.selectCompanyID = event.Id;
  }
 
  Wfh() 
  {
    this.locationValue = 1
  }

  FiledJob() 
  {
    this.locationValue = 2
  }

  intership() 
  {
    this.internshipvalue = true;
    this.stipend = false;
    this.salary = true;
  }

  intership1() 
  {
    this.internshipvalue = false;
    this.stipend = true;
    this.salary = false;
  }

  expType(event: any) 
  {
    if (event == 0) {
      this.appearMe = false;
    }
    else {
      this.appearMe = true;
    }
  }

  toggleAllSelection() 
  {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getValues(event: 
{
    isUserInput: any;
    source: { value: any; selected: any };
  }) {
    if (event.isUserInput) {

      if (event.source.selected === true) {

        this.specializationsId.push(event.source.value);
      } else {
        var index = this.specializationsId.indexOf(event.source.value);
        if (index !== -1) {
          this.specializationsId.splice(index, 1);
        }
      }
    }
  }

  ngOnDestroy(): void {

  }
}
