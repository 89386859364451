import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { AcademicDiscipline, Qualification, Specialization } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../config-dialog/confing-dialog.component';
import { AuthService } from 'src/app/auth.service';



@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.scss']
})
export class QualificationComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private service: SharedService<any>,
    private toastr: ToastrService,
    private authservice: AuthService,
    private formBuilder: FormBuilder,

  ) { }

  displayedColumns = ["Qualification", "IsActive", "Edit"];
  
  message: string = '';
  result: string = '';
  dataSource = new MatTableDataSource<Qualification>();
  dataSourceAD = new MatTableDataSource<AcademicDiscipline>();
  dataSourceSP = new MatTableDataSource<Specialization>();
  QualificationData: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  @ViewChild(MatSort) sort!: MatSort;
  
  selected = new FormControl(0);
  btn: boolean = false;
  qualificationId: number;
  acdamicdesplineId: number;
  disabled: boolean = true;
  disabledad: boolean = true;
  counter: number = 0;
  counterad: number = 0;

  submittedComp: boolean = false;
    AcademicDisciplineform: FormGroup;
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: any = 0;
    idSP: any = 0;
    public dataSourceAcadamic: any;
add :boolean; 
addSP :boolean; 
Specializationform: FormGroup;

public dataSourceSpecialization: any;

  ngOnInit(): void {
    this.refreshList();

   
  }

  get fcomp(): { [key: string]: AbstractControl } {
    return this.AcademicDisciplineform.controls;
}

  refreshList() {
    this.service.Get('Qualification/GetAllQualification?Id=0', null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // this.Country.emit();
    })
  }

 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  
  applyFilterAD(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceAD.filter = filterValue.trim().toLowerCase();
  }

  

  AddJobRole() {
    this.router.navigate(['qualificationform', 0])
  }

  EditJobRole(row: any) {
    this.router.navigate(['qualificationform', row.Id]);
  }

  IsActive(row: any) {
    this.confirmDialog(row)
  }

  IsActiveLanguge(row: any) {
    this.service.Put('Qualification/UpdateActiveStatus', row).subscribe({
      next: (res) => {
        if (row.IsActive == true) {
          this.toastr.success('Qualification inactive sucessfully');
        }
        else {
          this.toastr.success('Qualification active sucessfully');
        }
        this.refreshList();
      },

    })
  }

  confirmDialog(row: any) {

    if (row.IsActive == true) {
      this.message = 'Are you sure want to inactive qualification ' + row.Qualification + ' ?';
    }
    else {
      this.message = 'Are you sure want to active qualification' + row.Qualification + ' ?';
    }

    const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

    const dialogRef = this.dialog.open(ConfigDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.IsActiveLanguge(row);

      }

    })
  }

  showIcon(index: boolean): string {
    if (index === true) {
      return 'toggle_on';
    } else {
      return 'toggle_off';
    }
  }



Close(){
this.router.navigate(['./qualification'])
}


  ngOnDestroy(): void {

  }

}
