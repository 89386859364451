import { Component, Inject, Injectable, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Currency } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';




@Component({
  selector: 'app-add-joiningdate',
  templateUrl: './add-joiningdate.component.html',
  styleUrls: ['./add-joiningdate.component.scss']
})
export class JoiningDateComponent implements OnInit {
  [x: string]: any;

  message: string;
  title: string;
  btnMessage: string = "Confirm";
  inputForm: FormGroup;
  submitted: boolean = false;
  allCurrency?: Observable<Currency[]>;
  allPayRoll?: Observable<any[]>;
  closeResult: string = '';

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private service: SharedService<any>,
    private dialogRef: MatDialogRef<JoiningDateComponent>,
  ) {

    // Update view with given values
  }
  get fcomp(): { [key: string]: AbstractControl } {
    return this.inputForm.controls;
  }


  ngOnInit(): void {

    this.inputForm = this.formBuilder.group({
      JoiningDate: ['', Validators.required],
     
    })
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.submitted = true;

    if (this.inputForm.invalid) {
      this.toastr.error("This data is not valid");
    }
    else {
      this.dialogRef.close(this.inputForm.value);
    }
  }

  onCancel()
  {
    this.dialogRef.close();
  }
  onDismiss(): void {
    // Close the dialog, return false

  }
  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    });
  }


}

export class JoingDateDialogModel {

  constructor(public title: string,
    public message: string) {
  }

}


