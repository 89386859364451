import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-add-state',
    templateUrl: './add-state.component.html',
    styleUrls: ['./add-state.component.scss']
})
export class AddStateComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    stateForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: number = 0;
    public dataSource: any;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private service: SharedService<any>,
    ) { }

    ngOnInit(): void {
        this.stateForm = this.formBuilder.group({
            Id: ['', null],
            StateCode: [null, Validators.required],
            StateName: ["", Validators.required],
            CountryId: ["", Validators.required],
        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                this.id = Number(params.get('id'));
            }
        );

        if (this.id != 0) {
            this.boolIdVisible = false;
            this.GetCountryDataById(this.id);
            this.ActionBtn = "Update";
            this.allCountries = this.service.Get('Country/GetActiveCountry', null);
        }
        else {
            this.boolIdVisible = true;
            this.ActionBtn = "Save";
            this.allCountries = this.service.Get('Country/GetActiveCountry', null);
        }
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.stateForm.controls;
    }

    StateForm() {
        this.submittedComp = true;
        if (this.stateForm.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            if (this.id == 0) {
                const state = this.stateForm.getRawValue();
                this.service.Post('State/InsertState', state).subscribe(
                    (res) => {

                        this.toastr.success(res);
                        this.router.navigate(['./state'])
                    },
                    (error) => {
                        this.toastr.error(error.error);
                    });
                this.submittedComp = false;
            }
            else {
                this.UpdateState();
            }
        }
    }

    Close(){
        this.router.navigate(['./state'])
    }

    UpdateState() {
        const state = this.stateForm.getRawValue();
        this.service.Put('State/UpdateState', state).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['./state'])
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    GetCountryDataById(Id: number) {
        this.service.Get('State/GetStateData?Id=' + Id, null).subscribe(data => {
            this.dataSource = data;
            this.stateForm.controls["Id"].setValue(this.dataSource[0].Id);
            this.stateForm.controls["StateCode"].setValue(this.dataSource[0].StateCode);
            this.stateForm.controls["StateName"].setValue(this.dataSource[0].StateName);
            this.stateForm.controls["CountryId"].setValue(this.dataSource[0].CountryId);
        })
    }

    ngOnDestroy(): void {

    }
}
