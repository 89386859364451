import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../config-dialog/confing-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Country } from '../../employers-register/EditCandidate/basic-info/basic-info.types';


@Component({
    selector: 'app-country',
    templateUrl: './country.component.html',
    styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit, OnDestroy {

    showModalBox: boolean = false;

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
        private dialog: MatDialog,
    ) { }

    displayedColumns = [ "ISDCode","CountryCode","CountryName", "IsActive","Edit"];
    message: string = '';
    result: string = '';
    dataSource = new MatTableDataSource<Country>();
    CountryData: any;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    public info: any;

    ngOnInit(): void {
        this.refreshList()
    }

    refreshList() {
        this.service.Get('Country/GetCountry?Id=0', null).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      }
    
      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }

    AddContry() {
        this.router.navigate(['add-country', 0])
    }

    EditCountry(row: any) {
        this.router.navigate(['add-country', row.Id]);
    }

    ngOnDestroy(): void {

    }

  IsActive(row: any) {
    this.confirmDialog(row)
  }

  IsActiveContry(row: any) {
    this.service.Put('Country/UpdateActiveStatus', row).subscribe({
      next: (res) => {
        if (row.IsActive == true) {
         
          this.toastr.success('Country inactive sucessfully');
        }
        else {
            this.toastr.success('Country active sucessfully');
        }
        this.refreshList();
      },

    })
  }

  confirmDialog(row: any) {
      if (row.IsActive == true) {
        this.message = 'Are you sure want to inactive country ' + row.CountryName + ' ?';
      }
      else {
        this.message = 'Are you sure want to active country ' + row.CountryName + ' ?';
      }

      const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

      const dialogRef = this.dialog.open(ConfigDialogComponent, {
        maxWidth: "400px",
      
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == true) {
          this.IsActiveContry(row);
      }
    })
  }

  showIcon(index: boolean): string {
    if (index === true) {
      return 'toggle_on';
    } else {
      return 'toggle_off';
    }
  }

}
