import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from 'src/app/shared.service';
import { AuthService } from 'src/app/auth.service';

@Component({
    selector: 'app-addacademicdiscipline',
    templateUrl: './addacademicdiscipline.component.html',
    styleUrls: ['./addacademicdiscipline.component.scss']
})
export class AddAcademicDescriptionComponent implements OnInit, OnDestroy {
    
    submittedComp: boolean = false;
    AcademicDisciplineform: FormGroup;
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: number = 0;
    public dataSource: any;
    allQualification?: any;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private service: SharedService<any>,
        private authservice: AuthService,
    ) { }

    ngOnInit(): void {
        this.AcademicDisciplineform = this.formBuilder.group({
            Id: ['', null],
            QualificationId: ['', [Validators.nullValidator]],
            AcademicDescription: ['', [Validators.required]],
           
        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                this.id = Number(params.get('id'));
            }
        );

        if (this.id != 0) {
            this.boolIdVisible = false;
            this.GetADDataById(this.id);
            this.ActionBtn = "Update";
        }
        else {
            this.boolIdVisible = true;
            this.ActionBtn = "Save";
        }

        this.allQualification = this.service.Get('Qualification/GetQualification?Id=0', null);
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.AcademicDisciplineform.controls;
    }

    AcademicDescriptionForm() {
        this.submittedComp = true;
        if (this.AcademicDisciplineform.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            if (this.id == 0) {
                const AcademicDiscipline = this.AcademicDisciplineform.getRawValue();

                this.service.Post('AcademicDiscipline/InsertAcademicDiscipline', AcademicDiscipline).subscribe(
                    (res) => {
                        this.toastr.success(res);
                        this.router.navigate(['./academicdiscipline'])
                    },
                    (error) => {
                        this.toastr.error(error.error);
                    });
                this.submittedComp = false;
            }
            else {
                this.Updatequalification();
            }
        }
    }

Close(){
    this.router.navigate(['./academicdiscipline'])
}

    Updatequalification() {
        const AcademicDiscipline = this.AcademicDisciplineform.getRawValue();
        this.service.Put('AcademicDiscipline/UpdateAcademicDiscipline', AcademicDiscipline).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['./academicdiscipline'])
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    GetADDataById(Id: number) {
        this.service.Get('AcademicDiscipline/GetAcademicDisciplineData?Id=' + Id, null).subscribe(data => {
          this.dataSource = data;
          this.AcademicDisciplineform.controls["Id"].setValue(this.dataSource[0].Id);
          this.AcademicDisciplineform.controls["QualificationId"].setValue(this.dataSource[0].QualificationId);
          this.AcademicDisciplineform.controls["AcademicDescription"].setValue(this.dataSource[0].AcademicDescription);
        })
      }

    ngOnDestroy(): void {

    } 
}
