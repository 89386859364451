import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from 'src/app/shared.service';
import { AuthService } from 'src/app/auth.service';
import { Observable } from 'rxjs';
import { Qualificationa } from '../../../employers-register/EditCandidate/education/educartion.types';

@Component({
    selector: 'app-addSpecialization',
    templateUrl: './addSpecialization.component.html',
    styleUrls: ['./addSpecialization.component.scss']
})
export class AddSpecializationComponent implements OnInit, OnDestroy {
    
    submittedComp: boolean = false;
    Specializationform: FormGroup;
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: number = 0;
    allAD?: any;
    allQualification?: Observable<Qualificationa[]>;
    public dataSourceSpecialization: any;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private service: SharedService<any>,
        private authservice: AuthService,
    ) { }

    ngOnInit(): void {
        this.Specializationform = this.formBuilder.group({
            Id: ['', null],
            AcademicDisplineId: ['', [Validators.required]],
            QualificationId: ['', [Validators.nullValidator]],
            SpecializationDescription: ['', [Validators.required]],
           
        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                this.id = Number(params.get('id'));
            }
        );

        //this.allAD = this.service.Get('AcademicDiscipline/GetAcademicDisciplineData?Id=0', null);
        this.allQualification = this.service.Get('Qualification/GetQualification?Id=0', null);
        if (this.id != 0) {
            this.boolIdVisible = false;
            this.GetSPDataById(this.id);
            this.ActionBtn = "Update";
        }
        else {
            this.boolIdVisible = true;
            this.ActionBtn = "Save";
        }
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.Specializationform.controls;
    }
    onchangeQualification(Qualification: any) {
        this.allAD = this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + Qualification, null);
    }
    SpecializationForm() {
        this.submittedComp = true;
        if (this.Specializationform.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            if (this.id == 0) {
                const Specialization = this.Specializationform.getRawValue();
                this.service.Post('Specialization/InsertSpecialization', Specialization).subscribe(
                    (res) => {
                        this.toastr.success(res);
                        this.router.navigate(['./specialization'])
                    },
                    (error) => {
                        this.toastr.error(error.error);
                    });
                this.submittedComp = false;
            }
            else {
                this.UpdateSP();
            }
        }
    }

Close(){
    this.router.navigate(['./specialization'])
}

    UpdateSP() {
        const Specialization = this.Specializationform.getRawValue();
        this.service.Put('Specialization/UpdateSpecialization', Specialization).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['./specialization'])
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    GetSPDataById(Id: number) {
        this.service.Get('Specialization/GetAllSpecialization?Id=' + Id, null).subscribe(data => {
          this.dataSourceSpecialization = data;
          this.Specializationform.controls["Id"].setValue(this.dataSourceSpecialization[0].Id);
          this.Specializationform.controls["AcademicDisplineId"].setValue(this.dataSourceSpecialization[0].AcademicDisplineId);
          this.Specializationform.controls["SpecializationDescription"].setValue(this.dataSourceSpecialization[0].SpecializationDescription);
        })
      }

    ngOnDestroy(): void {

    } 
}
