

<mat-grid-list cols="3" gutterSize="20px" rowHeight="10px" >
  <mat-grid-tile [colspan]="1" [rowspan]="1.5">
      <h6>{{Name}}</h6> <h6 style="padding-left: 11px">{{mobileNo}}</h6>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="1" [rowspan]="1.5"></mat-grid-tile>

  <mat-grid-tile [colspan]="1" [rowspan]="1.5">
      <input class="form-control search"
      (keyup)="applyFilter($event)" placeholder="Enter Text To Search">
  </mat-grid-tile>
</mat-grid-list>


<div class="card mat-elevation-z6 m-2 w-auto">

  
  <table mat-table [dataSource]="dataSource" matSort class="table-responsive" >

    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef class="M1" style="width: 20%;">
        Date</th>
      <td mat-cell *matCellDef="let element"> {{element.DateAdded | date:'dd-MM-yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="SentMsg">
      <th mat-header-cell *matHeaderCellDef class="M1"  style="width: 40%; ">
        Send Message</th>
      <td mat-cell *matCellDef="let element" style="text-align: left; padding-left: 10px;"
        [ngStyle]="{'background-color':element.SentMsg == '' ? 'white':'#72cbf59e'}"> {{element.SentMsg}} </td>
    </ng-container>

    <ng-container matColumnDef="RecMesg">
      <th mat-header-cell *matHeaderCellDef class="M1"  style="width: 40%;">
        Receive Message</th>
      <td mat-cell *matCellDef="let element"  style="text-align: left; padding-left: 10px;" 
      [ngStyle]="{'background-color':element.RecMesg == '' ? 'white':'rgb(114 245 192 / 35%)'}"> {{element.RecMesg}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <mat-paginator color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15,25]" aria-label="Select page"
    >
  </mat-paginator>
</div>


<mat-grid-list cols="3" gutterSize="20px" rowHeight="10px" >
<mat-grid-tile [colspan]="1" [rowspan]="1.5"></mat-grid-tile>
<mat-grid-tile [colspan]="1" [rowspan]="1.5"> <button mat-flat-button matTooltip="close" 
  aria-label="Example icon button with a menu icon" mat-dialog-close style="background-color: #221668; color: white;">
   Close
</button></mat-grid-tile>
<mat-grid-tile [colspan]="1" [rowspan]="1.5"></mat-grid-tile>
</mat-grid-list>

