<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="title-item" *ngIf="datasource">
                            <h4 style="margin-right: 43%; color: white;">{{datasource[0].JobRole}}</h4>
                            <ul>
                                <li style="margin-right: 43%;"><i class='bx bx-pie-chart-alt-2'></i>
                                    {{datasource[0].CompanyIndustry}}
                                </li>
                                <li style="margin-right: 43%;"><i class='bx bx-time'></i> {{datasource[0].JobPostedDate
                                    | date:'dd-MM-yyyy'}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-tab-group (selectedTabChange)="getShortListedCandidatebyId()">
        <mat-tab style="position: sticky;">
            <div class="job-filter-area pt-50">
                <div class="container">
                    <form [formGroup]="userForm">
                        <div class="row">
                            <div class="col-sm-4 col-lg-3">
                                <div class="form-group">
                                    <label>Candidate</label>
                                    <!-- <select class="form-control form-select" formControlName="CandidateId"
                                        [ngClass]="{ 'is-invalid': submittedComp && fcomp.CandidateId.errors }"
                                        (change)="onchangeCandidate($event.target.value)">
                                        <option value="" disabled selected>Choose any one Company</option>
                                        <option *ngFor="let candidate of (allCandidate | async)" [value]="candidate.Id">
                                            {{candidate.CandidateName}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CandidateId.errors">
                                        <div *ngIf="fcomp.CandidateId.errors.required">
                                            Candidate is required
                                        </div>
                                    </div> -->

                                    <input type="text" class="form-control form-select" matInput
                                        [formControl]="formControlObj" [matAutocomplete]="autodec">
                                    <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnForcandidate"
                                        (optionSelected)="onchangeCandidate($event.option.value.Id)">
                                        <mat-option *ngFor="let candidate of filtercandidate | async"
                                            [value]="candidate">
                                            {{candidate.CandidateName}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-3" *ngIf="Global.isOwner">
                                <div class="form-group">
                                    <label>Recruiter</label>
                                    <!-- <select class="form-control form-select"
                                        (change)="onchangeRecruiter($event.target.value)"
                                        [ngClass]="{ 'is-invalid': submittedComp && fcomp.RecruiterId.errors }"
                                        formControlName="RecruiterId">
                                        <option value="" disabled selected>Choose any one Recruiter</option>
                                        <option *ngFor="let recruiter of (allrecruiter | async)" [value]="recruiter.Id">
                                            {{recruiter.FirstName}} {{recruiter.LastName}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="submittedComp && fcomp.RecruiterId.errors">
                                        <div *ngIf="fcomp.RecruiterId.errors.required">
                                            Recruiter is required
                                        </div>
                                    </div> -->

                                    <input type="text" class="form-control form-select" matInput
                                        [formControl]="formControlrecruiter" [matAutocomplete]="autore">
                                    <mat-autocomplete #autore="matAutocomplete" [displayWith]="displayFnForrecruiter"
                                        (optionSelected)="onchangeRecruiter($event.option.value.Id)">
                                        <mat-option *ngFor="let recruiter of filterrecruiter | async"
                                            [value]="recruiter">
                                            {{recruiter.FirstName}} {{recruiter.LastName}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-3" style="margin-top: 23px;">
                                <button type="submit" class="cmn-btn" (click)="shortListCandidate()">ShortList Candidate
                                    <i class='bx bx-plus'></i></button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

            <ng-template mat-tab-label>
                Summary
            </ng-template>

            <div class="job-details-area ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="details-item" *ngIf="datasource">

                                <div class="details-inner">
                                    <h3>Job Reference</h3>
                                    <p>{{datasource[0].JobNumber}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].IsDisClose == false">
                                    <h3>Company Name</h3>
                                    <p>Keshav Encon Private Limited</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].IsDisClose == true">
                                    <h3>Company Name</h3>
                                    <p>{{datasource[0].JobForName}}</p>
                                </div>

                                <div class="details-inner">
                                    <h3>Job Description</h3>
                                    <p>{{datasource[0].JobDescription}}</p>
                                </div>

                                <div class="details-inner">
                                    <h3>Qualification</h3>
                                    <p>{{datasource[0].Qualification}}</p>
                                </div>

                                <div class="details-inner">
                                    <h3>Required Experience</h3>
                                    <p>{{datasource[0].MinYearExp}}</p>
                                </div>

                                <div class="details-inner">
                                    <h3>Gender</h3>
                                    <p>{{datasource[0].GenderDesc}}</p>
                                </div>

                                <div class="details-inner">
                                    <h3>Working Days</h3>
                                    <p>{{datasource[0].WorkingDay}}</p>
                                </div>

                                <div class="details-inner">
                                    <h3>Timing</h3>
                                    <p>{{datasource[0].JobTiming}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Summary" style="position: sticky;">
            <ng-template mat-tab-label>
                Candidate
            </ng-template>

            <div class="board-wrapper" style="width: 3680px;">
                <div class="board-columns">
                    <div class="board-column">
                        <div class="row">
                            <div class="col" *ngFor="let status of allCandidateStatus">
                                <div>
                                    <div class="column-title text-center" style="width: 330px;">
                                        {{status.MessageText}}
                                    </div>
                                    <div *ngFor="let BuildResume of datasource1" (click)="ClickCard(BuildResume)"
                                        style="width: 83%;">
                                        <div *ngIf="status.ListIndex === BuildResume.CandidateStatus">
                                            <div class="candidate-item two">
                                                <div class="left">
                                                    <h3><a>{{BuildResume.FirstName}} {{BuildResume.LastName}}</a></h3>
                                                    <div><span>{{BuildResume.CurrentRoleWithCompanyName}}</span>
                                                    </div>
                                                    <ul class="experience">
                                                        <li style="width: 205px;">Email:
                                                            <span>{{BuildResume.EmailId}}</span>
                                                        <li>Education: <span>{{BuildResume.HighestEducation}}</span>
                                                        <li>Experience: <span>{{BuildResume.Experience}} Years</span>
                                                        </li>
                                                        <li>Gender:<span>{{BuildResume.GenderDesc}}</span></li>
                                                        <li>City: <span>{{BuildResume.CityName}}</span></li>
                                                        <li *ngIf="BuildResume.CandidateStatus === 7">CTC :
                                                            <span>{{BuildResume.CTC}}</span>
                                                        </li>
                                                        <li
                                                            *ngIf="BuildResume.CandidateStatus == 6 || BuildResume.CandidateStatus > 6 ">
                                                            Joining Date :
                                                            <span>{{BuildResume.JoiningDate | date:'dd-MM-yyyy'}}</span>
                                                        </li>
                                                        <li *ngIf="BuildResume.CandidateStatus === 7">PayRoll :
                                                            <span>{{BuildResume.PayRollDesc}}</span>
                                                        </li>

                                                        <li>Recruiter: <span>{{BuildResume.RecruiterName}}</span></li>
                                                    </ul>
                                                </div>
                                                <ng-container *ngIf="(BuildResume.Photo); else noImage">
                                                    <img class="circle-54" [src]="BuildResume.Photo">
                                                    <div class="viewresume" (click)="viewResume(BuildResume)">View
                                                        Resume</div>
                                                </ng-container>

                                                <ng-template #noImage>
                                                    <ng-container *ngIf="BuildResume.Gender == 0">
                                                        <img class="circle-54"
                                                            src="assets/candidateimg/Male_Colors.jpg">
                                                        <div class="viewresume" (click)="viewResume(BuildResume)">View
                                                            Resume</div>
                                                    </ng-container>

                                                    <ng-container *ngIf="BuildResume.Gender == 1">
                                                        <img class="circle-54"
                                                            src="assets/candidateimg/Female_Colors.jpg">
                                                        <div class="viewresume" (click)="viewResume(BuildResume)">View
                                                            Resume</div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="BuildResume.Gender != 1 && BuildResume.Gender != 0">
                                                        <img class="circle-54"
                                                            src="assets/candidateimg/Male_Colors.jpg">
                                                        <div class="viewresume" (click)="viewResume(BuildResume)">View
                                                            Resume</div>
                                                    </ng-container>


                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div style="z-index:5; position: fixed; bottom: 1.5rem; right: 1.5rem;">
    <div class="dialog_scrim" *ngIf="openMenu">
    </div>

    <button mat-fab style="background-color: #26448c; color: white;" (click)="clickMenu()" class="menu-button"
        *ngIf="jobPostId !== 0"> Status
    </button>

    <!-- <div *ngFor="let status of allCandidateStatus"> -->
    <button [disabled]="statusbtn" [ngClass]="{'nextbutton-disabled': statusbtn}" *ngIf="openMenu"
        style="border: none;">
        <button mat-mini-fab class="action-button one mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(0)" [disabled]="statusbtn">
            <img src="https://img.icons8.com/fluency-systems-filled/25/26448c/human-head.png" />
        </button>
        <!-- menu button -->
        <button mat-raised-button class="label labelone mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(0)" [disabled]="statusbtn">
            <span class="schedular"> New Candidates</span>
        </button>
    </button>

    <button [disabled]="statusbtn" [ngClass]="{'nextbutton-disabled': statusbtn}" *ngIf="openMenu"
        style="border: none;">
        <button mat-mini-fab class="action-button menutwo mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(1)" [disabled]="shortlistbtn">
            <img src="https://img.icons8.com/material-outlined/24/26448c/numbered-list.png" />
        </button>
        <button mat-raised-button class="label labeltwo mat-elevation-z8" *ngIf="openMenu" color="basic"
            [disabled]="shortlistbtn" (click)="candidatestatuschg(1)">
            <span class="schedular"> Shortlisted</span>
        </button>
    </button>

    <button [disabled]="statusbtn" [ngClass]="{'nextbutton-disabled': statusbtn}" *ngIf="openMenu"
        style="border: none;">
        <button mat-mini-fab class="action-button three mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(2)" [disabled]="statusbtn">
            <img src="https://img.icons8.com/material-rounded/24/26448c/checked--v1.png" />
        </button>
        <button mat-raised-button class="label labelthree mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(2)" [disabled]="statusbtn">
            <span class="schedular"> Submitted</span>
        </button>
    </button>

    <button [disabled]="statusbtn" [ngClass]="{'nextbutton-disabled': statusbtn}" *ngIf="openMenu"
        style="border: none;">
        <button mat-mini-fab class="action-button four mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(3)" [disabled]="statusbtn">
            <img src="https://img.icons8.com/material/24/26448c/view-shedule.png" />
        </button>
        <button mat-raised-button class="label labelfour mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(3)" [disabled]="statusbtn">
            <span class="schedular"> Interview</span>
        </button>
    </button>

    <button [disabled]="statusbtn" [ngClass]="{'nextbutton-disabled': statusbtn}" *ngIf="openMenu"
        style="border: none;">
        <button mat-mini-fab class="action-button five mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(6)" [disabled]="statusbtn">
            <img src="https://img.icons8.com/windows/32/26448c/give-gift.png" />
        </button>
        <button mat-raised-button class="label labelfive mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(6)" [disabled]="statusbtn">
            <span class="schedular"> Offered</span>
        </button>
    </button>

    <button [disabled]="statusbtn" [ngClass]="{'nextbutton-disabled': statusbtn}" *ngIf="openMenu"
        style="border: none;">
        <button mat-mini-fab class="action-button six mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(7)" [disabled]="statusbtn">
            <img
                src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/25/26448c/external-employee-human-resources-kmg-design-glyph-kmg-design-3.png" />
        </button>
        <button mat-raised-button class="label labelsix mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(7)" [disabled]="statusbtn">
            <span class="schedular"> Joined</span>
        </button>
    </button>

    <button [disabled]="statusbtn" [ngClass]="{'nextbutton-disabled': statusbtn}" *ngIf="openMenu"
        style="border: none;">
        <button mat-mini-fab class="action-button seven mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(9)" [disabled]="statusbtn">
            <img src="https://img.icons8.com/ios-glyphs/30/26448c/test-passed.png" />
        </button>

        <button mat-raised-button class="label labelseven mat-elevation-z8" *ngIf="openMenu" color="basic"
            (click)="candidatestatuschg(9)" [disabled]="statusbtn">
            <span class="schedular">Reliesed Or Resigned</span>
        </button>
    </button>

    <button mat-mini-fab class="action-button eight mat-elevation-z8" *ngIf="openMenu" color="basic"
        (click)="candidatestatuschg(10)">
        <img src="https://img.icons8.com/windows/30/26448c/circled-left-2.png" />
    </button>
    <button mat-raised-button class="label labeleight mat-elevation-z8" *ngIf="openMenu" color="basic"
        (click)="candidatestatuschg(10)">
        <span class="schedular">Drop</span>
    </button>

    <!-- </div> -->
</div>