<!-- Register -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-12 p-0 col-md-12">

                <div class="user-content">
                    <div class="user-img">
                        <img src="assets/png/bluelogo.png" alt="User">
                    </div>
                    <div class="top">
                        <h2>Candidate Sign Up</h2>

                        <form [formGroup]="candidateform">

                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" id="FirstName" class="form-control" placeholder="First Name"
                                    formControlName="FirstName" maxlength="50" (keypress)="keyPressAlpha($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                                <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                    <div *ngIf="f.FirstName.errors.required">First Name is required</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" id="LastName" class="form-control" placeholder="Last Name"
                                    formControlName="LastName" maxlength="50" (keypress)="keyPressAlpha($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
                                <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                    <div *ngIf="f.LastName.errors.required">Last Name is required</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" id="Email" class="form-control" placeholder="xyz@gmail.com"
                                    formControlName="Email" [ngClass]="{ 'is-invalid': submitted && f.Email.errors }"
                                    maxlength="150">
                                <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                                    <div *ngIf="f.Email.errors.required">Email is required</div>
                                    <div *ngIf="f.Email.errors.email">Email is invalid</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3 col-md-12">
                                    <div class="form-group">
                                        <label>ISD Code</label>
                                        <select class="form-control form-select" formControlName="CountryISDCode"
                                            [ngClass]="{ 'is-invalid': submitted && f.CountryISDCode.errors }">
                                            <option value="" disabled selected>Choose any one</option>
                                            <option *ngFor="let countries of (allCountries | async)"
                                                [value]="countries.ISDCode">
                                                {{countries.ISDCode}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback" *ngIf="submitted && f.CountryISDCode.errors">
                                            <div *ngIf="f.CountryISDCode.errors.required">
                                                ISD Codeis required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-9 col-md-12">
                                    <div class="form-group">
                                        <label>Mobile No.</label>
                                        <input type="text" class="form-control" placeholder="XXXXX-XXXXX "
                                            formControlName="MobileNo" maxlength="15" (keypress)="keyPressAlphaNumeric($event)"
                                            [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }">
                                        <div *ngIf="submitted && f.MobileNo.errors" class="invalid-feedback">
                                            <div *ngIf="f.MobileNo.errors.required">Phone number is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Qualification:</label>
                                <input type="text" class="form-control" [formControl]="formControlQuli"
                                    [matAutocomplete]="autoQuali">
                                <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForQuli"
                                    (optionSelected)="onchangeQualification($event.option.value.Id)">
                                    <mat-option *ngFor="let Quli of filterQuli | async" [value]="Quli">
                                        {{Quli.Qualification}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.QualificationId.errors">
                                    <div *ngIf="f.QualificationId.errors.required">
                                        Qualification is required
                                    </div>
                                </div>
                            </div>


                            <div class="form-group">
                                <label>Academic Discipline:</label>
                                <input type="text" class="form-control" [formControl]="formControldiscipline"
                                    [matAutocomplete]="autodec">
                                <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnFordiscipline"
                                    (optionSelected)="onchangeAcademicDiscipline($event.option.value.Id)">
                                    <mat-option *ngFor="let discipline of filterdiscipline | async"
                                        [value]="discipline">
                                        {{discipline.AcademicDescription}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.AcademicDisciplineId.errors">
                                    <div *ngIf="f.AcademicDisciplineId.errors.required">
                                        Academic Discipline is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Specialization:</label>
                                <input type="text" class="form-control form-select" 
                                    [formControl]="formControlspecialization" [matAutocomplete]="autospec">
                                <mat-autocomplete #autospec="matAutocomplete" [displayWith]="displayFnForSpecialization"
                                    (optionSelected)="onchangeSpecialization($event.option.value.Id)">
                                    <mat-option *ngFor="let specialization of filterspecialization | async"
                                        [value]="specialization">
                                        {{specialization.SpecializationDescription}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.SpecializationId.errors">
                                    <div *ngIf="f.SpecializationId.errors.required">
                                        Specialization Discipline is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Job Role:</label>
                                <input type="text" class="form-control form-select" [formControl]="formControlObj"
                                    [matAutocomplete]="auto" placeholder="Select Job Role">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                                    (optionSelected)="onchangejobrole($event.option.value.Id)" panelWidth="auto">
                                    <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                        {{jobrole.JobRole}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.JobRoleId.errors">
                                    <div *ngIf="f.JobRoleId.errors.required">
                                        Job Role is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>PassOutYear</label>
                                <input id="NoOfExperience" class="form-control" placeholder="Enter Pass out Year"
                                    formControlName="PassOutYear" maxlength="4">
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Available For InternShip(Graduate Engineer Trainee)</label>
                                    <input class="form-check-input" type="radio" formControlName="IsGET"
                                        name="inlineRadioOptions" id="inlineRadio1" value="option1"
                                        style="margin-left:2%" (click)="toggleTag()">
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                    <input class="form-check-input" type="radio" formControlName="IsGET"
                                        name="inlineRadioOptions" id="inlineRadio2" value="option2"
                                        style="margin-left: 2%; margin-right:2%" (click)="toggleTag1()">
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="this.displayMe">
                                <label>Instership StartDate</label>
                                <input type="date" class="form-control" placeholder="01/01/1995"
                                    formControlName="InternShipStartDate"
                                    [ngClass]="{ 'is-invalid': submitted && f.InternShipStartDate.errors }">
                                <div *ngIf="submitted && f.InternShipStartDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.InternShipStartDate.errors.required">Date of birtd is required</div>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="this.displayMe">
                                <label>Instership EndDate</label>
                                <input type="date" class="form-control" placeholder="01/01/1995"
                                    formControlName="InternShipEndDate"
                                    [ngClass]="{ 'is-invalid': submitted && f.InternShipEndDate.errors }">
                                <div *ngIf="submitted && f.InternShipEndDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.InternShipEndDate.errors.required">Date of birtd is required</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Profile Picture</label>
                                <input (change)="Uploadcompanylogo($event)" type="file" class="input">
                            </div>

                            <button (click)="CandidateForm()" type="submit" class="btn">Sign Up Here</button>
                        </form>
                        <div class="create-account">
                            <span class="account-direction">Already have an account? <a
                                    style="font-weight: 600; color: #fe4a55;" routerLink="/login">Login</a></span>
                        </div>
                    </div>

                    <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-google-plus'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a><i class="bx bx-home-alt" routerLink="/login"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Register -->