import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { EmployeeMaster } from 'src/app/interface';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-whatsappreceivemsg',
  templateUrl: './whatsappreceivemsg.component.html',
  styleUrls: ['./whatsappreceivemsg.component.scss']

})

export class WhatsappReceiveMsgComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['Date', 'SentMsg', 'RecMesg'];
  dataSource: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  allCandidate: Observable<EmployeeMaster[]>;
  mobileNo: string;
  Id: number

  constructor(
    private service: SharedService<any>
  ) { }

  ngOnInit(): void {
    this.CandidateList();
  }

  onchangecandidateName(event) {
    this.Id = event
    this.service.Get('WpMessage/GetCandidateWPMessage?CandidateId=' + this.Id, null).subscribe(data => {

      this.dataSource = new MatTableDataSource(data);
      if (data.length > 0) {
        this.mobileNo = data[0].MobileNo;
      }
      else {
        this.mobileNo = '';
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  CandidateList() {
    this.allCandidate = this.service.Get('CandidateInfo/GetCandidateData?Id=0', null);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {

  }
}

