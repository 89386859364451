import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { SharedService } from 'src/app/shared.service';
import { AuthService } from '../../guards/services/auth.service';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {

  addEditForm!: FormGroup;
  OTP!: boolean;
  User!: boolean;
  Userbtn !: boolean;
  constructor(private service: SharedService<any>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.addEditForm = this.formBuilder.group({
      Id: ['', null],
      Email: ['', null],
      OTP: ['', null],
    });
    this.OTP = false;
    this.User = true;
    this.Userbtn = true;
  }

  Submit() {
    if (this.addEditForm.invalid) {
      this.toastr.error("Enter all required data");
    }
    else {
      const forgotpassword = this.addEditForm.getRawValue();
      var UserName = forgotpassword.Email;
      this.service.Post('SendOTPForForgetPassword?loginName=' + UserName, null).subscribe({
        next: (res) => {
          this.toastr.success(res);
          this.Userbtn = false;
          this.OTP = true;
        },
      })
    }
  }


  SendOtp() {
    if (this.addEditForm.invalid) {
      this.toastr.error("Enter all required data");
    }
    else {
      const forgotpassword = this.addEditForm.getRawValue();
      var UserName = forgotpassword.Email;
      var OTP = forgotpassword.OTP;
      this.service.Post('VerifyOTPCode?loginName=' + UserName + '&OTPCode=' + OTP, null).subscribe({
        next: (res) => {
          this.toastr.success(res);
          if (res == 'Forget Password Email Sent.') {
            this.addEditForm.reset();
            this.router.navigate(['/login']);

          }
        },
      })
    }

  }
  ngOnDestroy(): void {

  }
}
