import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';

import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { SharedService } from 'src/app/shared.service';


@Component({
    selector: 'app-add-country',
    templateUrl: './add-country.component.html',
    styleUrls: ['./add-country.component.scss']
})
export class AddCountryComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    countryForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    ActionBtn: string = "Save";
    boolIdVisible = false;
    id: number = 0;
    public dataSource: any;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private service: SharedService<any>,
    ) { }

    ngOnInit(): void {
        this.countryForm = this.formBuilder.group({
            Id: ['', null],
            CountryCode: [null, Validators.required],
            CountryName: [null, Validators.required],
            ISDCode: [null, Validators.required],

        });

        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                this.id = Number(params.get('id'));
            }
        );

        if (this.id != 0) {
            this.boolIdVisible = false;
            this.GetCountryDataById(this.id);
            this.ActionBtn = "Update";

        }
        else {
            this.boolIdVisible = true;
            this.ActionBtn = "Save";
        }
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.countryForm.controls;
    }

    countryinfForm() {
        this.submittedComp = true;
        if (this.countryForm.invalid) {
            this.toastr.error("this data is not valid");
        }
        else {
            if (this.id == 0) {
                const country = this.countryForm.getRawValue();
                this.service.Post('Country/InsertCountry', country).subscribe(
                    (res) => {
                        this.toastr.success(res);
                        this.countryForm.reset();
                        this.router.navigate(['./country'])
                    },
                    (error) => {
                        this.toastr.error(error.error);
                    });
                this.submittedComp = false;
            }
            else {
                this.UpdateCountry();
            }
        }
    }

    Close(){
        this.router.navigate(['./country'])
    }
    
    UpdateCountry() {
        const country = this.countryForm.getRawValue();
        this.service.Put('Country/UpdateCountry', country).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['./country'])
            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }

    GetCountryDataById(Id: number) {
        this.service.Get('Country/GetCountry?Id=' + Id, null).subscribe(data => {
            this.dataSource = data;
            this.countryForm.controls["Id"].setValue(this.dataSource[0].Id);
            this.countryForm.controls["CountryName"].setValue(this.dataSource[0].CountryName);
            this.countryForm.controls["CountryCode"].setValue(this.dataSource[0].CountryCode);
            this.countryForm.controls["ISDCode"].setValue(this.dataSource[0].ISDCode);

        })
    }

    ngOnDestroy(): void {
    }
}
