import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { RecruiterInfomation } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';


@Component({
    selector: 'app-userlastlogin',
    templateUrl: './userlastlogin.component.html',
    styleUrls: ['./userlastlogin.component.scss']
})
export class UserLastLoginComponent implements OnInit, OnDestroy {

    displayedColumns: string[] = ['ProfileName', 'UserName', 'LastLogin'];
    dataSource: MatTableDataSource<RecruiterInfomation>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(
        private toastr: ToastrService,
        private service: SharedService<any>
    ) { }
    
    ngOnInit(): void {
        this.getUser();
       
    }

    getUser() {
      this.service.Get('UserRegistration/GetRecruiterLastLogin?Id=0', null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
},
            (error) => {
                this.toastr.error(error.error);
            });
    }

    applyFilter(event: KeyboardEvent) {

        if(event.key === 'Escape' || event.key === 'ESC')
        {
           this. dataSource.filter = ''
        }
        else
        {
            const filterValue = (event.target as HTMLInputElement).value;
            this.dataSource.filter = filterValue.trim().toLowerCase();
        }
    } 
    
    ngOnDestroy(): void {

    }
   
}
