import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { IndustryType, Specialization } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';

import { MatDialog } from '@angular/material/dialog';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../../config-dialog/confing-dialog.component';



@Component({
    selector: 'app-specialization',
    templateUrl: './specialization.component.html',
    styleUrls: ['./specialization.component.scss']
})
export class SpecializationComponent implements OnInit, OnDestroy {

    constructor(
        private toastr: ToastrService,
        private service: SharedService<any>,
        private router: Router,
        private dialog: MatDialog,
    ) { }

    
    displayedColumnsForSP = ["Qualification","AcademicDescription","SpecializationDescription", "IsActive", "Edit"];
    message: string = '';
    result: string = '';
    
    dataSourceSP = new MatTableDataSource<Specialization>();
    @ViewChild(MatPaginator) paginatorSP!: MatPaginator;
    @ViewChild(MatSort) sortSP!: MatSort;


    ngOnInit(): void {
        this.refreshListforSP();
    }

    refreshListforSP() {
        this.service.Get('Specialization/GetAllSpecialization?Id=0' , null).subscribe(data => {
          this.dataSourceSP = new MatTableDataSource(data);
          this.dataSourceSP.paginator = this.paginatorSP;
          this.dataSourceSP.sort = this.sortSP;
          // this.Country.emit();
        })
      }

      
      applyFilterSP(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceSP.filter = filterValue.trim().toLowerCase();
      }


IsActiveSP(row: any) {
    this.confirmDialogSP(row)
  }

  IsActivespecialization(row: any) {
    this.service.Put('Specialization/UpdateActiveStatus', row).subscribe({
      next: (res) => {
        if (row.IsActive == true) {
          this.toastr.success('Specialization inactive sucessfully');
        }
        else {
          this.toastr.success('Specialization active sucessfully');
        }
        this.refreshListforSP();
      },

    })
  }

  confirmDialogSP(row: any) {
    if (row.IsActive == true) {
      this.message = 'Are you sure want to inactive Specialization ' + row.SpecializationDescription + ' ?';
    }
    else {
      this.message = 'Are you sure want to active Specialization' + row.SpecializationDescription + ' ?';
    }

    const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

    const dialogRef = this.dialog.open(ConfigDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.IsActivespecialization(row);
      }
    })
  }
  showIcon(index: boolean): string {
    if (index === true) {
      return 'toggle_on';
    } else {
      return 'toggle_off';
    }
  }

  AddSP() {
    this.router.navigate(['addspecialization', 0])
  }

  EditSP(row: any) {
    this.router.navigate(['addspecialization', row.Id]);
  }


    ngOnDestroy(): void {

    }
   
}
