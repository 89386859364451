<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div>
                <div class="title-item">
                    <!-- <h2>Recruiters
                    </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Recruiters
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div >
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul class="job-list-item align-items-center">
                    <li>
                        <form>
                            <div class="form-group">
                            </div>
                        </form>
                    </li>
                </ul>
                <input class="form-control" style="width: 269px;margin-left: 1289px;margin-top:-49px;" 
                (keyup)="applyFilter($event)" [(ngModel)]="this.dataSource.filter"  placeholder="Enter Text To Search" >

                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                
                      <ng-container matColumnDef="FirstName">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                          First Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="LastName">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                          Last Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
                      </ng-container>
              
                  
                      <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                          Email</th>
                        <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                      </ng-container>
      
                      <ng-container matColumnDef="MobileNo">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                          Mobile Number</th>
                        <td mat-cell *matCellDef="let element"> {{element.CountryISDCode}} {{element.MobileNo}} </td>
                      </ng-container>
      
                      <ng-container matColumnDef="UserTypeName">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                          Role </th>
                        <td mat-cell *matCellDef="let element"> {{element.UserTypeName}} </td>
                      </ng-container>
      
                     
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    </table>
                    <div class="col-sm-3 col-lg-3" style="margin-left: 481px; margin-top: 10px;">
                        <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [boundaryLinks]="true" [maxSize]="5"
                            (pageChange)="pageChanged(page)">
                        </ngb-pagination>
                    </div>
                  </div>

            </div>

        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->