import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { RecruiterInfomation } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { ctcinfo } from '../../job-details/job-details.component';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-userpoints',
    templateUrl: './userpoints.component.html',
    styleUrls: ['./userpoints.component.scss']
})
export class UserPointsComponent implements OnInit, OnDestroy {

    displayedColumns: string[] = ['JobCompanyName','RecruiterName','Points','CandidateName'];
    dataSource: MatTableDataSource<ctcinfo>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    allRecruiter: Observable<RecruiterInfomation>;

    constructor(
        private service: SharedService<any>
    ) { }

    ngOnInit(): void {
        this.allRecruiter = this.service.Get('Recruiter/GetRecruiterData?Id=0', null);

    }

      onchangerecruitername(event) {
        this.service.Get('Recruiter/GetRecruiterPointsData?RecruiterId=' + event, null).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
    
        })
    }

    applyFilter(event: KeyboardEvent)
     {
         if(event.key === 'Escape' || event.key == 'ESC')
         {
            this.dataSource.filter = '';
         }
         else
         {
            const filterValue = (event.target as HTMLInputElement).value;
            this.dataSource.filter = filterValue.trim().toLowerCase();
         }
       
    } 


    ngOnDestroy(): void {

    }

}