import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { Country } from '../employers-register/EditCandidate/basic-info/basic-info.types';
import { State } from 'src/app/interface';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-client-register',
    templateUrl: './client-register.component.html',
    styleUrls: ['./client-register.component.scss']
})
export class ClientCreateComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    clientForm: FormGroup;
    allIndustry: Observable<any>;
    //allCountries: Observable<any>;
    //allStates: Observable<any>;
    //allCities: Observable<any[]>;
    emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$";
    fileType: string = '';
    base64textString: string[] = [];
    allCountry: Observable<any>;


    allCountries: Country[]
    filterCountries?: Observable<Country[]>;
    formCountries = new FormControl();
    CountriesId: number;

    allStates: State[];
    filterStates?: Observable<State[]>;
    formStates = new FormControl();
    StatesId: number;

    allCities: any[];
    filterCities?: Observable<any[]>;
    formCities = new FormControl();
    CityId: number;


    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>
    ) { }

    ngOnInit(): void {
        this.clientForm = this.formBuilder.group({
            ClientName: [null, Validators.required],
            Address1: ["", [Validators.required, Validators.maxLength(150)]],
            Address2: ["", null],
            Description: ["", Validators.required],
            CountryId: ["", Validators.required],
            StateId: ["", Validators.required],
            CityId: ["", Validators.required],
            PinCode: ["", Validators.required],
            WebSite: ["", null],
            LinkedInPage: ["", null],
            firstname: [null, Validators.required],
            lastname: ["", Validators.required],
            MobileNo: ["", Validators.required],
            Logo: ['', [Validators.nullValidator]],
            CountryISDCode: ['', Validators.required],

        });
        this.Service.Get('Country/GetCountry?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allCountries = data;
                this.filterCountries = this.formCountries.valueChanges.pipe(
                    startWith<string | Country>(''),
                    map(value => typeof value === 'string' ? value : value.CountryName),
                    map(CountryName => CountryName ? this._filterCountry(CountryName) : this.allCountries.slice()),
                );
            }
        })
        //this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
        this.Service.Get('Country/GetCountryISDCode?Id=0', null).subscribe({
            next: (res) => {
              this.allCountry = res;
              this.clientForm.controls["CountryISDCode"].setValue(this.allCountry[0].ISDCode);
            }
          });
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.clientForm.controls;
    }
    private _filterCountry(CountryName: string): Country[] {
        const filterValue = CountryName.toLowerCase();
        return this.allCountries.filter(
            option => option.CountryName?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForCountry(value: any) {
        return value ? value.CountryName : undefined;
    }

    private _filterState(StateName: string): State[] {
        const filterValue = StateName.toLowerCase();
        return this.allStates.filter(
            option => option.StateName?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForState(value: any) {
        return value ? value.StateName : undefined;
    }

    private _filterCity(CityName: string): any[] {
        const filterValue = CityName.toLowerCase();
        return this.allCities.filter(
            option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForCity(value: any) {
        return value ? value.CityName : undefined;
    }



    onchangeCountry(event) {
        this.CountriesId = event

        this.Service.Get('State/GetStateByCountry?countryId=' + this.CountriesId, null).subscribe(data => {
            if (data != null) {
                this.allStates = data;
                this.filterStates = this.formStates.valueChanges.pipe(
                    startWith<string | State>(''),
                    map(value => typeof value === 'string' ? value : value.StateName),
                    map(StateName => StateName ? this._filterState(StateName) : this.allStates.slice()),
                );
            }
        })
    }
    onchangeState(event) {
        this.StatesId = event;
        this.Service.Get('City/GetCityDataByState?StateId=' + this.StatesId, null).subscribe(data => {
            if (data != null) {
                this.allCities = data;
                this.filterCities = this.formCities.valueChanges.pipe(
                    startWith<string | any>(''),
                    map(value => typeof value === 'string' ? value : value.CityName),
                    map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice()),
                );
            }
        })
    }
    onchangeCity(event) {
        this.CityId = event;
    }

    companyInfoForm() {
        this.clientForm.controls["CountryId"].setValue(this.CountriesId);
        this.clientForm.controls["StateId"].setValue(this.StatesId);
        this.clientForm.controls["CityId"].setValue(this.CityId);
        this.submittedComp = true;
        if (this.clientForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const company = this.clientForm.getRawValue();
            company.Logo = this.base64textString[0];
            this.Service.Post('Client/InsertClientData', company).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.clientForm.reset();
                },
                (error) => {
                    //if (error.status == 401) this.router.navigate(['/login']);
                    this.toastr.error(error.error);
                });
            this.submittedComp = false;
        }
    }

    UploadClientlogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }

    keyPressAlphaNumeric(event: { keyCode: number; preventDefault: () => void; }) {
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9 ]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    ngOnDestroy(): void {
    }


}
