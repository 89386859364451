<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Post A Job</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                        </li>
                        <li>Post A Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Post A Job -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="jobPostForm">
                <div class="profile-content-inner">
                    <!-- <h2>Post a Job</h2> -->
                    <div class="row">

                        <div class="col-lg-4 col-md-12" hidden=true>
                            <div class="form-group">
                                <input class="form-control" formControlName="CompanyId" placeholder="" />
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Company <label class="star">*</label></label>
                                <input type="text" class="form-control form-select"
                                    [formControl]="formControlCompanyName" [matAutocomplete]="autoCom"
                                    placeholder="Select Company">
                                <mat-autocomplete #autoCom="matAutocomplete" [displayWith]="displayFnForCompany"
                                    (optionSelected)="onchangecompany($event.option.value)">
                                    <mat-option *ngFor="let comp of filterCompanyName | async" [value]="comp">
                                        {{comp.CompanyName}}
                                    </mat-option>
                                </mat-autocomplete>

                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobCompanyName.errors">
                                    <div *ngIf="fjob.JobCompanyName.errors.required">
                                        Company Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Job Role <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formControlObj"
                                    [matAutocomplete]="auto" placeholder="Select Job Role">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                                    (optionSelected)="onchangejobrole($event.option.value.Id)" panelWidth="auto">
                                    <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                        {{jobrole.JobRole}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobRoleId.errors">
                                    <div *ngIf="fjob.JobRoleId.errors.required">
                                        Job Role is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Company Address<label class="star">*</label></label>
                                <textarea class="form-control" formControlName="CompanyAddress" placeholder=""
                                    [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.CompanyAddress.errors
                                    }"></textarea>
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.CompanyAddress.errors
                                    ">
                                    <div *ngIf="
                                            fjob.CompanyAddress.errors
                                                .required
                                        ">
                                        Company Address is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Job For <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formControlJobFor"
                                    [matAutocomplete]="autojobfor" placeholder="Select Job Role">
                                <mat-autocomplete #autojobfor="matAutocomplete" [displayWith]="displayFnForJobFor"
                                    (optionSelected)="onchangejobfor($event.option.value.Id)">
                                    <mat-option *ngFor="let comp of filterJobFor | async" [value]="comp">
                                        {{comp.ClientName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-12" style="margin-top: 36px;">
                            <div class="form-group">
                                <label>Job For Name Disclose ? <label class="star">*</label></label>
                                <input class="form-check-input" type="radio" formControlName="IsDisClose"
                                    name="inlineRadioOptions5" id="inlineRadio5"
                                    style="margin-left:2%; margin-right: 2%;" value="true" [checked]="true">

                                <label class="form-check-label" for="inlineRadio5">Yes</label>

                                <input class="form-check-input" type="radio" formControlName="IsDisClose"
                                    name="inlineRadioOptions5" id="inlineRadio6"
                                    style="margin-left: 2%; margin-right:2%" value="false">
                                <label class="form-check-label" for="inlineRadio6">No</label>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Job Description <label class="star">*</label></label>
                                <textarea class="form-control textarea" formControlName="JobDescription"
                                    placeholder="Enter Description" style="height: 90px;"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobDescription.errors }"></textarea>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobDescription.errors">
                                    <div *ngIf="fjob.JobDescription.errors.required">
                                        Description is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Job Specification <label class="star">*</label></label>
                                <textarea class="form-control textarea" formControlName="JobSpecification"
                                    placeholder="Enter Specification" style="height: 90px;"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobSpecification.errors }"></textarea>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobSpecification.errors">
                                    <div *ngIf="fjob.JobSpecification.errors.required">
                                        Specification is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <form>
                                    <label class="form-check-label" for="inlineRadio1">Single Job Location <label class="star">*</label></label>
                                    <input class="form-check-input" type="radio" formControlName="JobLocationType"
                                        value="0" name="inlineRadioOptions" id="inlineRadio1"
                                        [(ngModel)]="selectedStatus" style="margin-left: 2%; margin-right:2%">
                                    <label class="form-check-label" for="inlineRadio2">Work From Home</label>
                                    <input class="form-check-input" type="radio" formControlName="JobLocationType"
                                        name="inlineRadioOptions" id="inlineRadio2" value="1"
                                        style="margin-left: 2%; margin-right:2%" (click)="Wfh()">
                                    <label class="form-check-label" for="inlineRadio3">Field Jobs</label>
                                    <input class="form-check-input" type="radio" formControlName="JobLocationType"
                                        name="inlineRadioOptions" id="inlineRadio3" value="2"
                                        style="margin-left: 2%; margin-right:2%" (click)="FiledJob()" [checked]="true">
                                </form>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Country <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formCountries"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.Country.errors }"
                                [matAutocomplete]="autocou" placeholder="Select Country">
                                <mat-autocomplete #autocou="matAutocomplete" [displayWith]="displayFnForCountry"
                                    (optionSelected)="onchangeCountry($event.option.value.Id)">
                                    <mat-option *ngFor="let Countries of filterCountries | async" [value]="Countries">
                                        {{Countries.CountryName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Country.errors">
                                    <div *ngIf="fjob.Country.errors.required">
                                        Country is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>State <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formStates"
                                    [matAutocomplete]="autostate" placeholder="Select State"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.State.errors }">
                                <mat-autocomplete #autostate="matAutocomplete" [displayWith]="displayFnForState"
                                    (optionSelected)="onchangeState($event.option.value.Id)">
                                    <mat-option *ngFor="let states of filterStates | async" [value]="states">
                                        {{states.StateName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.State.errors">
                                    <div *ngIf="fjob.State.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>City <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formCities"
                                    [matAutocomplete]="autocity" placeholder="Select State"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobLocation.errors }">
                                <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity"
                                    (optionSelected)="onchangeCity($event.option.value.Id)">
                                    <mat-option *ngFor="let cities of filterCities | async" [value]="cities">
                                        {{cities.CityName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobLocation.errors">
                                    <div *ngIf="fjob.JobLocation.errors.required">
                                        City is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>No.Of Opening <label class="star">*</label></label>
                                <input type="number" class="form-control" formControlName="JobOpening" placeholder=""
                                    [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.JobOpening.errors
                                    }" />
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.JobOpening.errors
                                    ">
                                    <div *ngIf="
                                            fjob.JobOpening.errors
                                                .required
                                        ">
                                        No Of Opening is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Joining Period <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="NoticePeriod">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let NoticePeriod of allNoticePeriod"
                                        [value]="NoticePeriod.ListIndex">
                                        {{NoticePeriod.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.NoticePeriod.errors">
                                    <div *ngIf="fjob.NoticePeriod.errors.required">
                                        Joining Period required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Type Of Job <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="JobType"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobType.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let JobType of allJobType " [value]="JobType.ListIndex">
                                        {{JobType.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobType.errors">
                                    <div *ngIf="fjob.JobType.errors.required">
                                        Type Of Job is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Sub Type Of Job <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="Typeofjob"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Typeofjob.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Typeofjob of allTypeofjob" [value]="Typeofjob.ListIndex">
                                        {{Typeofjob.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Typeofjob.errors">
                                    <div *ngIf="fjob.Typeofjob.errors.required">
                                        Sub Type Of Job is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Shift Type <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="JobShift"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobShift.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let ShiftType of allShiftType" [value]="ShiftType.ListIndex">
                                        {{ShiftType.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="
                                    submittedJob &&
                                    fjob.JobShift.errors
                                ">
                                    <div *ngIf="
                                        fjob.JobShift.errors
                                            .required
                                    ">
                                        Shift Type is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Working Days <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="WorkingDays">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let WorkingDays of allWorkingDays" [value]="WorkingDays.ListIndex">
                                        {{WorkingDays.MessageText}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Working Hours <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="Timing"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Timing.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Timing of allworkinghpurs" [value]="Timing.ListIndex">
                                        {{Timing.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Timing.errors">
                                    <div *ngIf="fjob.Timing.errors.required">
                                        Working Hours is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Duty Type <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="DutyType"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.DutyType.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let DutyType of allDutyType" [value]="DutyType.ListIndex">
                                        {{DutyType.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.DutyType.errors">
                                    <div *ngIf="fjob.DutyType.errors.required">
                                        Duty Type required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Available For InternShip(Graduate Engineer Trainee) <label class="star">*</label></label>
                                <input class="form-check-input" type="radio" formControlName="IsInternShip"
                                    name="inlineRadioOptions4" id="inlineRadio4" value="true" style="margin-left:2%"
                                    (click)="intership1()">
                                <label class="form-check-label" for="inlineRadio4">Yes</label>

                                <input class="form-check-input" type="radio" formControlName="IsInternShip"
                                    name="inlineRadioOptions4" id="inlineRadio5" value="false"
                                    style="margin-left: 2%; margin-right:2%" (click)="intership()">
                                <label class="form-check-label" for="inlineRadio5">No</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.internshipvalue">
                            <div class="form-group">
                                <label>Experience Type<label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="ExperienceType"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.ExperienceType.errors }"
                                    (change)="expType($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let ExperienceType of allExperienceType"
                                        [value]="ExperienceType.ListIndex">
                                        {{ExperienceType.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.ExperienceType.errors">
                                    <div *ngIf="fjob.ExperienceType.errors.required">
                                        Experience Type required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Salary Type <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="Salarytype"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Salarytype.errors }"
                                    (change)="onchangesalary($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Salarytype of allSalarytype" [value]="Salarytype.ListIndex">
                                        {{Salarytype.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Salarytype.errors">
                                    <div *ngIf="fjob.Salarytype.errors.required">
                                        Salary Type is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.appearMe">
                            <div class="form-group">
                                <label>Min. year</label>
                                <input type="number" class="form-control" formControlName="MinYearExp"
                                    placeholder="Enter Min Year">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.salarytype">
                            <div class="form-group">
                                <label>Currency</label>
                                <select class="form-control form-select" formControlName="CurrencyId"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.CurrencyId.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Currency of (allCurrency | async)" [value]="Currency.Id">
                                        {{Currency.CurrencyCode}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.CurrencyId.errors">
                                    <div *ngIf="fjob.CurrencyId.errors.required">
                                        Currency is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.salarytype">
                            <div class="form-group">
                                <label *ngIf="this.stipend">Min.Stipend/Month</label>
                                <label *ngIf="this.salary">Min.Salary/Month</label>
                                <input type="number" class="form-control" formControlName="MinSalary" placeholder=""
                                    [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.MinSalary.errors
                                    }" />
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.MinSalary.errors
                                    ">
                                    <div *ngIf="
                                            fjob.MinSalary.errors
                                                .required
                                        ">
                                        Min.Stipend is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.salarytype">
                            <div class="form-group">
                                <label *ngIf="this.stipend">Max.Stipend/Month</label>
                                <label *ngIf="this.salary">Max.Salary/Month</label>
                                <input type="number" class="form-control" formControlName="MaxSalary" placeholder=""
                                    [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.MaxSalary.errors
                                    }" />
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.MaxSalary.errors
                                    ">
                                    <div *ngIf="
                                            fjob.MaxSalary.errors
                                                .required
                                        ">
                                        Max.Stipend is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Minimum Qualification <label class="star">*</label></label>
                                <input type="text" class="form-control" [formControl]="formControlQualificationine"
                                    [matAutocomplete]="autoQuali">
                                <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForQualification"
                                    (optionSelected)="onchangeQualification($event.option.value.Id)">
                                    <mat-option *ngFor="let Quli of filterQualification | async" [value]="Quli">
                                        {{Quli.Qualification}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.ReqQualification.errors">
                                    <div *ngIf="fjob.ReqQualification.errors.required">
                                        Qualification is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Academic Discipline <label class="star">*</label></label>
                                <input type="text" class="form-control" [formControl]="formControldiscipline"
                                    [matAutocomplete]="autodec" 
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.AcademicDisciplineId.errors }">
                                <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnFordiscipline"
                                    (optionSelected)="onchangeDiscipline($event.option.value.Id)">
                                    <mat-option *ngFor="let discipline of filterdiscipline | async"
                                        [value]="discipline">
                                        {{discipline.AcademicDescription}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.AcademicDisciplineId.errors">
                                    <div *ngIf="fjob.AcademicDisciplineId.errors.required">
                                        Academic Discipline is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Specialization <label class="star">*</label></label>
                                <div style="margin-top: -22px;">
                                    <mat-form-field appearance="none">
                                        <mat-select #select multiple class="form-control1 form-select1"
                                            formControlName="SpecializationId"
                                            [ngClass]="{ 'is-invalid': submittedJob && fjob.SpecializationId.errors }">
                                            <div class="select-all">
                                                <mat-checkbox [(ngModel)]="allSelected"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection()">
                                                    Select All</mat-checkbox>
                                            </div>
                                            <mat-option (onSelectionChange)="getValues($event)"
                                                *ngFor="let specialization of (allspecialization | async)"
                                                [value]="specialization.Id">
                                                {{specialization.SpecializationDescription}} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Gender <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="Gender"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Gender.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Gender of (allGender | async)" [value]="Gender.ListIndex">
                                        {{Gender.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Gender.errors">
                                    <div *ngIf="fjob.Gender.errors.required">
                                        Gender is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="usertype == 1">
                            <div class="form-group">
                                <label>Recruiter <label class="star">*</label></label>
                                <select class="form-control form-select" formControlName="RecruiterId"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.RecruiterId.errors }">
                                    <option value="" disabled selected>Choose any one Recruiter</option>
                                    <option *ngFor="let recruiter of (allrecruiter | async)" [value]="recruiter.Id">
                                        {{recruiter.FirstName}} {{recruiter.LastName}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Target Date <label class="star">*</label></label>
                                <input type="date" class="form-control" placeholder="01/01/1995"
                                    formControlName="TargetDate"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.TargetDate.errors }">
                                <div *ngIf="submittedJob && fjob.TargetDate.errors" class="invalid-feedback">
                                    <div *ngIf="fjob.TargetDate.errors.required">TargetDate is required</div>
                                </div>
                            </div>
                        </div>

                        <!-- *ngIf="saveJobBtn" -->
                        <button (click)="jobPostInfoForm()" class="btn dashboard-btn">
                            Save Your Information
                        </button></div>
                </div>


            </form>
        </div>
    </div>
</div>

<!-- End Post A Job -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->