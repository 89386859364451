<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                        </li>
                        <li>Candidate</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div>
        <div class="row" style="margin-top: 10px; margin-left: 10px;">
            <div class="col-lg-2 col-md-12 coldownloadwidth" style="margin-top: 10px;">
                <a media="print" class="cmn-btn" href="../../assets/Sample_Candidate_Data.xlsx"> Download Sample <i
                        class="bx bx-download bx-sm bx-tada-hover"></i></a>
            </div>

            <div class="col-lg-2 col-md-1 col1width">

                <label type="submit" class="filterbtn" style="border-radius: 5px;margin-top: 10px;padding-left: 26px;">
                    Upload File<i class='bx bx-upload'></i><input type="file" #myInput style="visibility:hidden"
                        (change)="onFileChange($event)" /></label>
            </div>

            <div class="col-lg-2 col-md-4" style="width: 12%;">
                <div class="form-group">
                    <label> From Date</label>
                    <input type="date" class="form-control" placeholder="01/01/1995" [formControl]="date"
                        (input)="dateChange($event)">
                </div>
            </div>

            <div class="col-lg-2 col-md-4" style="width: 12%;">
                <div class="form-group">
                    <label> To Date</label>
                    <input type="date" class="form-control" [min]="minDate" [formControl]="Todate"
                        placeholder="01/01/1995" (input)="EnddateChange($event)">
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col2width">
                <button type="submit" class="filterbtn" style="border-radius: 5px;margin-top: 11px;"
                    (click)="ImportAsXLSX()">Uploaded File Status<i class='bx bx-download'></i></button>
            </div>

            <div class="col-lg-3 widthforsearch">
                <input class="form-control" (keyup)="applyFilter($event)" placeholder="Enter Text To Search"
                    [(ngModel)]="this.Global.CandidateTableSearch" name="Search" style="margin-top: 12px; ">
                <i class='bx bx-file-find search' (click)="searchbtn()" matTooltip="Search" type="solid"></i>
                <i class='bx bx-message-alt-x Clear' (click)="clearsearch()" matTooltip="Clear search"></i>
            </div>
        </div>

        <div class="card mat-elevation-z6 m-2 w-auto">
            <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                <!-- Position Column -->
                <ng-container matColumnDef="FirstName">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Candidate Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.FirstName}} {{element.LastName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="MobileNo">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Mobile Number </th>
                    <td mat-cell *matCellDef="let element"> {{element.CountryISDCode}} {{element.MobileNo}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="UserName">
                    <th mat-header-cell *matHeaderCellDef class="M1"> EmailId </th>
                    <td mat-cell *matCellDef="let element"> {{element.UserName}} </td>
                </ng-container>

                <ng-container matColumnDef="Qualification">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Qualification</th>
                    <td mat-cell *matCellDef="let element"> {{element.Qualification}} </td>
                </ng-container>

                <ng-container matColumnDef="AcademicDescription">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Academic</th>
                    <td mat-cell *matCellDef="let element"> {{element.AcademicDescription}} </td>
                </ng-container>

                <ng-container matColumnDef="SpecializationDescription">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Specialization</th>
                    <td mat-cell *matCellDef="let element"> {{element.SpecializationDescription}} </td>
                </ng-container>

                <ng-container matColumnDef="JobRole">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Job Role</th>
                    <td mat-cell *matCellDef="let element"> {{element.JobRole}} </td>
                </ng-container>

                <ng-container matColumnDef="Resume">
                    <th mat-header-cell *matHeaderCellDef class="M1">
                        Resume</th>
                    <td mat-cell *matCellDef="let element"> {{element.Resume}}</td>
                </ng-container>

                <ng-container matColumnDef="ResumeverifiedName">
                    <th mat-header-cell *matHeaderCellDef class="M1">
                        Resume Rreview</th>
                    <td mat-cell *matCellDef="let element"> {{element.ResumeverifiedName}} </td>
                </ng-container>

                <ng-container matColumnDef="BlackList">
                    <th mat-header-cell *matHeaderCellDef class="M1"> BlackList </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button (click)="confirmDialog(row)" class="material-icons"
                            [disabled]="blacklistbtn">
                            <i class="bx bx-toggle-left bx-tada-hover"
                                [ngStyle]="{'color':row.Blacklisted== '0' ? 'DarkGreen':'red'}"></i>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Edit </th>
                    <td mat-cell *matCellDef="let row">
                        <i class="bx bx-edit bx-tada-hover" (click)="Edit(row)"
                            style="color: #062fb8;font-size:14px"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="View">
                    <th mat-header-cell *matHeaderCellDef class="M1"> View </th>
                    <td mat-cell *matCellDef="let row">
                        <i class="bi-eye" (click)="View(row)" style="color: #bb3c05;font-size:13px"></i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div class="row">
                <div class="col-lg-2 col-md-12">
                    <form [formGroup]="candidateForm">
                        <input class="form-control gototxt" (keyup)="GoToPage($event)" placeholder="Goto Page"
                            formControlName="PageSearch">
                    </form>
                </div>
                <div class="col-lg-2 col-md-12">
                    <button type="submit" (click)="GOTO()" class="gotobtn">Goto Page<i
                            class='bx bx-search'></i></button>
                </div>
                <div class="col-lg-2 col-md-12">
                    <button type="submit" (click)="Previous()" class="Previous">Previous<i
                            class='bx bx-skip-previous-circle' style="margin-left: 10px;"></i></button>
                </div>

                <div class="col-lg-2 col-md-12">
                    <button type="submit" (click)="Next()" class="Next">Next<i class='bx bx-skip-next-circle'
                            style="margin-left: 10px;"></i></button>
                </div>

                <div class="col-lg-2 col-md-12">
                    <label class="totalRecords">Total Records : {{totalItems}}</label>
                </div>

                <div class="col-lg-2 col-md-12">
                    <label>Total Pages : {{totalpages}}</label>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <app-footer-style-two></app-footer-style-two>
    <!-- End Footer -->