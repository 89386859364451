import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {  FormBuilder } from '@angular/forms';
import { Router } from "@angular/router";
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { CollegeInformation } from 'src/app/interface';

@Component({
    selector: 'app-companyhome',
    templateUrl: './companyhome.component.html',
    styleUrls: ['./companyhome.component.scss']
})

export class CompanyHomeComponent implements OnInit, OnDestroy{

    getcompanyData:CollegeInformation;
    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService
    ) { }

    ngOnInit(): void {
        this.getUser();  
    }

    getUser() {
        this.Service.Get('Company/GetCompanyData?Id=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.getcompanyData = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    ngOnDestroy(): void {
        
    }
}