import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { CollegeInformation } from 'src/app/interface';
import userToken from 'src/app/components/config/userToken';

@Component({
    selector: 'app-recruiterhome',
    templateUrl: './recruiterhome.component.html',
    styleUrls: ['./recruiterhome.component.scss']
})

export class RecruiterHomeComponent implements OnInit, OnDestroy {
    getcompanyData: CollegeInformation;
    userId: number = userToken.id;
    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService
    ) { }

    ngOnInit(): void {
        this.getUser();
    }

    getUser() {

        this.userId = this.Global.getRecruiterId();
        this.Service.Get('Recruiter/GetRecruiterData?Id=' + this.userId , null).subscribe(
            (res) => {
              
                this.getcompanyData = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    ngOnDestroy(): void {

    }
}
