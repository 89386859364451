import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { CandidateReport} from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { ExcelServicesService } from 'src/app/excel-services.service';

@Component({
    selector: 'app-candidatereport',
    templateUrl: './candidatereport.component.html',
    styleUrls: ['./candidatereport.component.scss']
})

export class CandidateReportComponent implements OnInit, OnDestroy {

    displayedColumns: string[] = ['CandidateName', 'Email', 'MobileNo','Resume', 'ResumeverifiedName','MobileNumberVerify'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(
        private toastr: ToastrService,
        private service: SharedService<any>,
        private excelService: ExcelServicesService
    ) { }

    ngOnInit(): void {
        this.getCandidate()
    }

    getCandidate() {
       
      this.service.Get('CandidateInfo/GetCandidateDataForReport', null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    } 

    
  exportAsXLSX(): void {
    let objCRColln: CandidateReport[] = [];
    this.service.Get('CandidateInfo/GetCandidateDataForReport', null).subscribe(data => {
      for (let item of data) {
        let objUR: CandidateReport = new CandidateReport();
        objUR.CandidateName = item.CandidateName;
        objUR.Email = item.Email;
        objUR.MobileNo = item.MobileNo;
        objUR.Resume = item.Resume;
        objUR.ResumeverifiedName = item.ResumeverifiedName;
        objUR.MobileNumberVerify = item.MobileNumberVerify;
       
        objCRColln.push(objUR);
      }
      this.excelService.exportAsExcelFile(objCRColln, 'Candidate_Report_Data_');
    });
  }


    
    ngOnDestroy(): void {

    }
   
}
