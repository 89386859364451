import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy{
    submitted: boolean = false;
    clgForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;
    fileType: string = '';
    base64textString: string[] = [];

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service:SharedService<any>
    ) { }

    ngOnInit(): void {
        this.clgForm = this.formBuilder.group({
            CollegeName: [null, Validators.required],
            CollegeEmail: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            ProfileName: ["", null],
            Address1: ["", Validators.required],
            Address2: ["", null],
            CollegeDescription: ["", Validators.required],
            CountryId: ["", Validators.required],
            StateId: ["", Validators.required],
            CityId: ["", Validators.required],
            PinCode: ["", Validators.required],
            WebSite: ["", null],
            LinkedInPage: ["", null],
            UserTypeId: [3],
            Logo:['',[Validators.nullValidator]],

        });
        this.allCountries = this.Service.Get('Country/GetCountry?Id=0', null);
    }
    get f(): { [key: string]: AbstractControl } {
        return this.clgForm.controls;
    }
   
    onchangeCountry(event) {
        this.allStates = this.Service.Get('State/GetStateByCountry?countryId=' + event, null);
    }
    onchangeCity(event) {
        this.allCities = this.Service.Get('City/GetCityDataByState?StateId=' + event, null);
    }
    ClgForm() {

        this.submitted = true;
        
        if (this.clgForm.invalid) {
            this.toastr.error("Enter all required data");
        }
        else {
            const clg = this.clgForm.getRawValue();
            clg.Logo = this.base64textString[0];
            var UserName = this.clgForm.value.CollegeEmail;
            clg.ProfileName = this.clgForm.value.CollegeName;
            
            this.Service.Post('College/SignupCollegeData', clg).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.clgForm.reset();
                    // this.Service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
                    //     (res) => {
                    //         this.toastr.success(res);
                    //     },
                    //   )
                      this.router.navigate(['/login']);
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submitted = false;
        }
    }

    Uploadcompanylogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }


    ngOnDestroy(): void {
    }


}
