import { Injectable } from '@angular/core';;
import { from } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';


declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private authservice: AuthService) { }

  public init() {
    return from(
        fetch('assets/data.json').then(function(response) {
          return response.json();
        })
      ).pipe(
        map((config) => {
        this.authservice.ApiUrl = config[0].webapiurl;
        this.authservice.ReportURL = config[0].ReportUrl;
        return 
      }));
  }
}
