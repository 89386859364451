<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Edit Company </h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/employer/dashboard/:id/companies/:id">Companies list</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Edit Company </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Create Company -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="companyForm">
                <div class="profile-content-inner">
                    <!-- <h2>Company</h2> -->
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Company Name</label>
                                <input type="text" class="form-control" formControlName="CompanyName"
                                    placeholder="e.g. Acme Corporation."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CompanyName.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CompanyName.errors">
                                    <div *ngIf="fcomp.CompanyName.errors.required">
                                        Company Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="text" class="form-control" formControlName="CompanyEmail" readonly
                                    placeholder="xyz@gmail.com"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CompanyEmail.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CompanyEmail.errors">
                                    <div *ngIf="fcomp.CompanyEmail.errors.required">
                                        Email is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Company Description</label>
                                <textarea type="text" class="form-control" formControlName="Description"
                                    placeholder="Enter Description"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Description.errors }"></textarea>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Description.errors">
                                    <div *ngIf="fcomp.Description.errors.required">
                                        Description is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Industry</label>
                                <select class="form-control form-select" formControlName="IndustryTypeId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.IndustryTypeId.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Industry of (allIndustry | async)" [value]="Industry.Id">
                                        {{Industry.IndustryType}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.IndustryTypeId.errors">
                                    <div *ngIf="fcomp.IndustryTypeId.errors.required">
                                        industry is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address1</label>
                                <textarea type="text" class="form-control" formControlName="Address1"
                                    placeholder="Company Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Address1.errors }">
                                    </textarea>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Address1.errors">
                                    <div *ngIf="fcomp.Address1.errors.required">
                                        Company Address is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address2</label>
                                <textarea type="text" class="form-control" formControlName="Address2"
                                    placeholder="Company Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.Address2.errors }">
                                    </textarea>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.Address2.errors">
                                    <div *ngIf="fcomp.Address2.errors.required">
                                        Company Address is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Country</label>
                                <select class="form-control form-select" formControlName="CountryId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryId.errors }"
                                    (change)="onchangeCountry($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Countries of (allCountries | async)" [value]="Countries.Id">
                                        {{Countries.CountryName}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CountryId.errors">
                                    <div *ngIf="fcomp.CountryId.errors.required">
                                        Country is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>State</label>
                                <select class="form-control form-select" formControlName="StateId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.StateId.errors }"
                                    (change)="onchangeCity($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let states of (allStates | async)" [value]="states.Id">
                                        {{states.StateName}}
                                    </option>

                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.StateId.errors">
                                    <div *ngIf="fcomp.StateId.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>City</label>
                                <select class="form-control form-select" formControlName="CityId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CityId.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let cities of (allCities | async)" [value]="cities.Id">
                                        {{cities.CityName}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CityId.errors">
                                    <div *ngIf="fcomp.CityId.errors.required">
                                        City is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Pincode</label>
                                <input class="form-control" placeholder="Enter Pincode" formControlName="PinCode"
                                    maxlength="8" [ngClass]="{ 'is-invalid': submittedComp && fcomp.PinCode.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.PinCode.errors">
                                    <div *ngIf="fcomp.PinCode.errors.required">Pincode is required</div>
                                    <div *ngIf="fcomp.PinCode.errors.PinCode">Pincode is invalid</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Website</label>
                                <input class="form-control" placeholder="Enter website" formControlName="WebSite"
                                    maxlength="100">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Linkedin Page</label>
                                <input class="form-control" placeholder="Enter LinkedInPage"
                                    formControlName="LinkedInPage">
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Logo</label>
                                <input (change)="Uploadcompanylogo($event)" type="file" class="input">
                                <!-- <button class="btn" mat-raised-button color="primary" (click)="uploadFile()">Upload</button> -->
                            </div>
                        </div>

                        <div class="form-group" style="text-align:center ;"> Contact Person </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="email" class="form-control" placeholder="Enter First Name"
                                    formControlName="firstname" maxlength="50"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.firstname.errors }">
                                <div *ngIf="submittedComp && fcomp.firstname.errors" class="invalid-feedback">
                                    <div *ngIf="fcomp.firstname.errors.required">First Name is required</div>
                                    <div *ngIf="fcomp.firstname.errors.email">First Name is invalid</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" class="form-control" formControlName="lastname"
                                    placeholder="Enter Last Name" maxlength="50"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.lastname.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.lastname.errors">
                                    <div *ngIf="fcomp.lastname.errors.required">
                                       Last Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>ISD Code</label>
                                <select class="form-control form-select" formControlName="CountryISDCode"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryISDCode.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let countries of (allCountry | async)" [value]="countries.ISDCode">
                                        {{countries.ISDCode}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CountryISDCode.errors">
                                    <div *ngIf="fcomp.CountryISDCode.errors.required">
                                        ISD Code is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <div class="form-group">
                                <label>Mobile Number</label>
                                <input type="text" class="form-control" formControlName="MobileNo"
                                    placeholder="XXXXX-XXXXX" maxlength="15"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.MobileNo.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.MobileNo.errors">
                                    <div *ngIf="fcomp.MobileNo.errors.required">
                                        Mobile number is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button (click)="companyInfoForm()" class="btn dashboard-btn">
                    Save Your Information
                </button>
            </form>
        </div>
    </div>
</div>

<!-- End create company -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->