import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { JobRole } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../config-dialog/confing-dialog.component';

@Component({
    selector: 'app-jobrole',
    templateUrl: './jobrole.component.html',
    styleUrls: ['./jobrole.component.scss']
})
export class JobRoleComponent implements OnInit, OnDestroy {

    constructor(
        private toastr: ToastrService,
        private service: SharedService<any>,
        private router: Router,
        private dialog: MatDialog,
    ) { }

    
    displayedColumns = [ "JobRole", "Description","IsActive","Edit"];
    message: string = '';
    result: string = '';
    dataSource = new MatTableDataSource<JobRole>();
    JobRoleData: any;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;


    ngOnInit(): void {
        this.refreshList();
    }

 
    refreshList() {
        this.service.Get('JobRole/GetAllJobRole?Id=0', null).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // this.Country.emit();
        })
      }

      
      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }

  AddJobRole() {
    this.router.navigate(['addjobrole', 0])
}

EditJobRole(row: any) {
    this.router.navigate(['addjobrole', row.Id]);
}


IsActive(row: any) {
    this.confirmDialog(row)
  }

  IsActiveContry(row: any) {
    this.service.Put('JobRole/UpdateActiveStatus', row).subscribe({
      next: (res) => {
        if (row.IsActive == true) {
            this.toastr.success('Jobrole inactive sucessfully');
        }
        else {
            this.toastr.success('Jobrole active sucessfully');
        }
        this.refreshList();
      },

    })
  }

  confirmDialog(row: any) {
      if (row.IsActive == true) {
        this.message = 'Are you sure want to inactive jobrole ' + row.JobRole + ' ?';
      }
      else {
        this.message = 'Are you sure want to active jobrole ' + row.JobRole + ' ?';
      }

      const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

      const dialogRef = this.dialog.open(ConfigDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == true) {
          this.IsActiveContry(row);
      }
    })
  }

  showIcon(index: boolean): string {
    if (index === true) {
      return 'toggle_on';
    } else {
      return 'toggle_off';
    }
  }

    ngOnDestroy(): void {

    }
   
}
