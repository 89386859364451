<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Manage Job
                    </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Manage Job
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div >
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul>
                </ul>

                <a media="print" class="cmn-btn" href="../../assets/JobPost.xlsx"
                    style="margin-right: 10px ; margin-bottom: 10px; margin-left: 1.5%;"> Download Sample <i
                        class="bx bx-download bx-sm bx-tada-hover"></i></a>

                <label>Upload File :</label>
                <input type="file" #myInput (change)="onFileChange($event)" />

                <input class="form-control search" 
                (keyup)="applyFilter($event)" placeholder="Enter Text To Search" [(ngModel)]="this.global.ManageJobSearch" name="Search" >

                    <div class="row">
                <div class="col-sm-2 col-lg-3">
                 
                        <!-- <button type="submit" class="filterbtn" (click)="search()">Search<i
                                class='bx bx-search'></i></button>  -->
                    </div>
                    <i class='bx bx-message-alt-x Clear' (click)="clearsearch()"></i>
                </div>

                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                        <!-- Position Column -->
                        <ng-container matColumnDef="JobRefrenceNo" >
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Job Number </th>
                          <td mat-cell *matCellDef="let element" style="text-align:left;color: blue;text-decoration: underline;" (click)="JobClick(element)"> {{element.JobNumber}} </td>
                        </ng-container>

                        <ng-container matColumnDef="JobRole">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Job Role </th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobRole}} </td>
                        </ng-container>

                        <ng-container matColumnDef="JobForName">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Job For </th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobForName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="JobPostedDate">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Job Posted Date </th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobPostedDate | date : 'dd-MM-yyyy'}} </td>
                        </ng-container>
                  
                        <ng-container matColumnDef="No.OfOpening">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">No. Of Opening</th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobOpening}} </td>
                        </ng-container>
  
                        <ng-container matColumnDef="JobStatus">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">Job Status</th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobstatusName}} </td>
                        </ng-container>
  
                        <ng-container matColumnDef="OverDue">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">Job OverDue</th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.OverDue}} </td>
                        </ng-container>
  
                        <ng-container matColumnDef="RecruiterName">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">Owner</th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.RecruiterName}} </td>
                        </ng-container>
                    
                        <ng-container matColumnDef="Action">
                          <th mat-header-cell *matHeaderCellDef class="M1">
                            Edit
                          </th>
                          <td mat-cell *matCellDef="let row">
                              <i class="bx bx-edit bx-tada-hover" (click)="Edit(row)"></i>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <div class="row">
                        <div class="col-lg-2 col-md-12">
                            <form [formGroup]="ManageJobForm">
                                <input class="form-control gototxt" (keyup)="GoToPage($event)" placeholder="Goto Page"
                                    formControlName="PageSearch">
                            </form>
                        </div>
                        <div class="col-lg-2 col-md-12">
                            <button type="submit" (click)="GOTO()" class="gotobtn" >Goto Page<i
                                class='bx bx-search'></i>
                            </button>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <button type="submit" (click)="Previous()" class="Previous">Previous<i
                                class='bx bx-skip-previous-circle' style="margin-left: 10px;"></i></button>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <button type="submit" (click)="Next()" class="Next">Next<i
                                class='bx bx-skip-next-circle' style="margin-left: 10px;"></i>
                            </button>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <label class="totalRecords">Total Records : {{totalItems}}</label>
                        </div>

                        <div class="col-lg-2 col-md-12">
                            <label>Total Pages : {{totalpages}}</label>
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Footer -->
    <app-footer-style-two></app-footer-style-two>

    <!-- End Footer -->