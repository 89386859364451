import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AcademicDiscipline, IndustryType, Specialization } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';

import { MatDialog } from '@angular/material/dialog';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../../config-dialog/confing-dialog.component';



@Component({
    selector: 'app-academicdiscipline',
    templateUrl: './academicdiscipline.component.html',
    styleUrls: ['./academicdiscipline.component.scss']
})
export class AcademicdisciplineComponent implements OnInit, OnDestroy {

    constructor(
        private toastr: ToastrService,
        private service: SharedService<any>,
        private router: Router,
        private dialog: MatDialog,
    ) { }

    
    displayedColumnsForAD = ["Qualification", "AcademicDescription", "IsActive", "Edit"];
    message: string = '';
    result: string = '';
    
    dataSourceAD = new MatTableDataSource<AcademicDiscipline>();
    @ViewChild(MatPaginator) paginatorAD!: MatPaginator;
    @ViewChild(MatSort) sortAD!: MatSort;



    ngOnInit(): void {
        this.refreshListforAD();
    }

    refreshListforAD() {
        this.service.Get('AcademicDiscipline/GetAllAcademicDisciplineData?Id=0', null).subscribe(data => {
          this.dataSourceAD = new MatTableDataSource(data);
          this.dataSourceAD.paginator = this.paginatorAD;
          this.dataSourceAD.sort = this.sortAD;
          // this.Country.emit();
        })
      }

      
      applyFilterAD(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceAD.filter = filterValue.trim().toLowerCase();
      }


      IsActiveAD(row: any) {
        this.confirmDialogAD(row)
      }
    
      IsActiveAcdamic(row: any) {
        this.service.Put('AcademicDiscipline/UpdateActiveStatus', row).subscribe({
          next: (res) => {
            if (row.IsActive == true) {
              this.toastr.success('AcademicDiscipline inactive sucessfully');
            }
            else {
              this.toastr.success('AcademicDiscipline active sucessfully');
            }
            this.refreshListforAD();
          },
    
        })
      }
    
      confirmDialogAD(row: any) {
    
        if (row.IsActive == true) {
          this.message = 'Are you sure want to inactive AcademicDiscipline ' + row.AcademicDescription + ' ?';
        }
        else {
          this.message = 'Are you sure want to active AcademicDiscipline' + row.AcademicDescription + ' ?';
        }
    
        const dialogData = new ConfirmDialogModel("Confirm Action", this.message);
    
        const dialogRef = this.dialog.open(ConfigDialogComponent, {
          maxWidth: "400px",
          data: dialogData
        });
    
        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult == true) {
            this.IsActiveAcdamic(row);
    
          }
    
        })
      }


  showIcon(index: boolean): string {
    if (index === true) {
      return 'toggle_on';
    } else {
      return 'toggle_off';
    }
  }

  AddAD() {
    this.router.navigate(['addacademicdiscipline', 0])
  }

  EditAD(row: any) {
    this.router.navigate(['addacademicdiscipline', row.Id]);
  }


    ngOnDestroy(): void {

    }
   
}
