export enum STATUSES {
  AWAY = "away",
  BUSY = "busy",
  ONLINE = "online",
  OFFLINE = "offline"
}

// export class SocialMedia {
//   facebook: string = "";
//   twitter: string = "";
//   instagram: string = "";
// }

export class Message1 {
  constructor(public type: "sent" | "replies", 
  public message: string,
  public TimeStamp: string,
  public MediaUrl: string,
  ) {}
}

export class User {
  ws: any;
  // social: SocialMedia = new SocialMedia();
  id = Math.random();
  constructor(
    public name: string = "",
    public status: STATUSES = STATUSES.OFFLINE,
    public img: string = "",
    public gender: string = "",
    public mobileno: string = "",
   
    public messages: Message1[] = []
  ) {}
}
