<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Create College</h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                            <!-- <a routerLink="/post-a-job">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Create College</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="clgForm">
                <div class="profile-content-inner">
                    <!-- <h2>College</h2> -->
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Name <label class="star">*</label></label>
                                <input type="text" id="CollegeName" class="form-control" placeholder="College Name"
                                    formControlName="CollegeName"
                                    [ngClass]="{ 'is-invalid':f.CollegeName.errors }">
                                <div *ngIf="submitted && f.CollegeName.errors" class="invalid-feedback">
                                    <div *ngIf="f.CollegeName.errors.required">College Name is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Email <label class="star">*</label></label>
                                <input type="email" id="CollegeEmail" class="form-control" placeholder="xyz@gmail.com"
                                    maxlength="150" formControlName="CollegeEmail"
                                    [ngClass]="{ 'is-invalid': submitted && f.CollegeEmail.errors }">
                                <div *ngIf="submitted && f.CollegeEmail.errors" class="invalid-feedback">
                                    <div *ngIf="f.CollegeEmail.errors.required">Email is required</div>
                                    <div *ngIf="f.CollegeEmail.errors.email">Email is invalid</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Website</label>
                                <input type="text" id="WebSite" class="form-control" placeholder="e.g. YourWebsite.com"
                                    formControlName="WebSite" maxlength="100"
                                    [ngClass]="{ 'is-invalid': submitted && f.WebSite.errors }">
                                <div *ngIf="submitted && f.WebSite.errors" class="invalid-feedback">
                                    <div *ngIf="f.WebSite.errors.required">College Website is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea type="text" id="CollegeDescription" class="form-control"
                                    placeholder="College Description" formControlName="CollegeDescription"
                                    [ngClass]="{ 'is-invalid': submitted && f.CollegeDescription.errors }"></textarea>
                                <div *ngIf="submitted && f.CollegeDescription.errors" class="invalid-feedback">
                                    <div *ngIf="f.CollegeDescription.errors.required">College Description is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address 1</label>
                                <textarea type="text" id="Address1" class="form-control" placeholder="Address1"
                                    formControlName="Address1"
                                    [ngClass]="{ 'is-invalid': submitted && f.Address1.errors }"></textarea>
                                <div *ngIf="submitted && f.Address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.Address1.errors.required">Address1 is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address2</label>
                                <textarea type="text" id="Address2" class="form-control" placeholder="Address2"
                                    formControlName="Address2"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Country</label>
                                <input type="text" class="form-control form-select" [formControl]="formCountries"
                                [matAutocomplete]="autocou" placeholder="Select Country" 
                                [ngClass]="{ 'is-invalid': submitted && f.CountryId.errors }">
                            <mat-autocomplete #autocou="matAutocomplete" [displayWith]="displayFnForCountry"
                                (optionSelected)="onchangeCountry($event.option.value.Id)">
                                <mat-option *ngFor="let Countries of filterCountries | async" [value]="Countries">
                                    {{Countries.CountryName}}
                                </mat-option>
                            </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.CountryId.errors">
                                    <div *ngIf="f.CountryId.errors.required">
                                        Country is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>State</label>
                                <input type="text" class="form-control form-select" [formControl]="formStates"
                                [matAutocomplete]="autostate" placeholder="Select State" 
                                [ngClass]="{ 'is-invalid': submitted && f.StateId.errors }">
                            <mat-autocomplete #autostate="matAutocomplete" [displayWith]="displayFnForState"
                                (optionSelected)="onchangeState($event.option.value.Id)">
                                <mat-option *ngFor="let states of filterStates | async" [value]="states">
                                    {{states.StateName}}
                                </mat-option>
                            </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted && f.StateId.errors">
                                    <div *ngIf="f.StateId.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>City</label>
                                <input type="text" class="form-control form-select" [formControl]="formCities"
                                [matAutocomplete]="autocity" placeholder="Select State" 
                                [ngClass]="{ 'is-invalid': submitted && f.CityId.errors }">
                            <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity"
                                (optionSelected)="onchangeCity($event.option.value.Id)">
                                <mat-option *ngFor="let cities of filterCities | async" [value]="cities">
                                    {{cities.CityName}}
                                </mat-option>
                            </mat-autocomplete>
                                <div class="invalid-feedback" *ngIf="submitted&& f.CityId.errors">
                                    <div *ngIf="f.CityId.errors.required">
                                        City is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Pincode</label>
                                <input type="text" id="PinCode" class="form-control" placeholder="PinCode"
                                    formControlName="PinCode" maxlength="8"
                                    [ngClass]="{ 'is-invalid': submitted && f.PinCode.errors }">
                                <div *ngIf="submitted && f.PinCode.errors" class="invalid-feedback">
                                    <div *ngIf="f.PinCode.errors.required">PinCode is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Logo</label>
                                <input (change)="Uploadcompanylogo($event)" type="file" class="input">
                                <!-- <button class="btn" mat-raised-button color="primary" (click)="uploadFile()">Upload</button> -->
                            </div>
                        </div>

                        <button (click)="ClgForm()" class="btn dashboard-btn">
                            Save Your Information
                        </button>

                    </div>
                </div>


            </form>
        </div>
    </div>
</div>

<!-- End Post A Job -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->