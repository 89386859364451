<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>State</h2>
                        <form [formGroup]="stateForm">
                            <div class="form-group">
                                <label>State Code</label>
                                <input class="form-control" formControlName="StateCode" placeholder="Enter State Code"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.StateCode.errors }" maxlength="5">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.StateCode.errors">
                                    <div *ngIf="fcomp.StateCode.errors.required">
                                        State Code is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>State Name</label>
                                <input class="form-control" formControlName="StateName" placeholder="Enter State Name"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.StateName.errors }" maxlength="100">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.StateName.errors">
                                    <div *ngIf="fcomp.StateName.errors.required">
                                        State Name is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Country</label>
                                <select class="form-control form-select" formControlName="CountryId"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.CountryId.errors }">
                                    <option value="" disabled selected>Choose any one country</option>
                                    <option *ngFor="let Countries of (allCountries | async)" [value]="Countries.Id">
                                        {{Countries.CountryName}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.CountryId.errors">
                                    <div *ngIf="fcomp.CountryId.errors.required">
                                        Country is required
                                    </div>
                                </div>
                            </div>

                            <button (click)="StateForm()" type="submit" class="btn Actionbutton">{{ActionBtn}}</button>
                            <button  (click)="Close()" type="close" class="btn Cancelbutton"> Close</button>

                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
