import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { IndustryType } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { ConfigDialogComponent, ConfirmDialogModel } from '../../config-dialog/confing-dialog.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
    selector: 'app-industrytype',
    templateUrl: './industrytype.component.html',
    styleUrls: ['./industrytype.component.scss']
})
export class IndustryTypeComponent implements OnInit, OnDestroy {

    constructor(
        private toastr: ToastrService,
        private service: SharedService<any>,
        private router: Router,
        private dialog: MatDialog,
    ) { }

    
    displayedColumns = [ "IndustryType", "Description","IsActive","Edit"];
    message: string = '';
    result: string = '';
    dataSource = new MatTableDataSource<IndustryType>();
    IndustrytypeData: any;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;


    ngOnInit(): void {
        this.refreshList();
    }

 
    refreshList() {
        this.service.Get('IndustryTypes/GetIndustryTypes?Id=0', null).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // this.Country.emit();
        })
      }

      
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  AddIndustrtype() {
    this.router.navigate(['addindustrytype', 0])
}

EditIndustrtype(row: any) {
    this.router.navigate(['addindustrytype', row.Id]);
}

IsActive(row: any) {
    this.confirmDialog(row)
  }

  IsActiveContry(row: any) {
    this.service.Put('IndustryTypes/UpdateActiveStatus', row).subscribe({
      next: (res) => {
        if (row.IsActive == true) {
          this.toastr.success('IndustryTypes inactive sucessfully');
        }
        else {
          this.toastr.success('IndustryTypes active sucessfully');
        }
        this.refreshList();
      },

    })
  }

  confirmDialog(row: any) {
      if (row.IsActive == true) {
        this.message = 'Are you sure want to inactive industryTypes ' + row.IndustryType + ' ?';
      }
      else {
        this.message = 'Are you sure want to active industryTypes ' + row.IndustryType + ' ?';
      }

      const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

      const dialogRef = this.dialog.open(ConfigDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == true) {
          this.IsActiveContry(row);
        
      }
  
    })
  }

  showIcon(index: boolean): string {
    if (index === true) {
      return 'toggle_on';
    } else {
      return 'toggle_off';
    }
  }
    ngOnDestroy(): void {

    }
   
}
