import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { Jobpost, UplodJob } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalService } from 'src/app/global.service';
import { AuthService } from 'src/app/auth.service';
import { ExcelServicesService } from 'src/app/excel-services.service';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-managejobs',
  templateUrl: './managejobs.component.html',
  styleUrls: ['./managejobs.component.scss']
})
export class ManageJobComponent implements OnInit, OnDestroy {
  @ViewChild('myInput') myInputVariable: any;
  // "CompanyName",
  displayedColumns = ["JobRefrenceNo", "JobRole", "JobForName", "JobPostedDate", "No.OfOpening", "JobStatus", "RecruiterName", "OverDue", "Action"];
  jobData: any;
  dataSource: MatTableDataSource<Jobpost[]>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  data: any;
  ManageJobForm: FormGroup;
  totalpages: number;
  datapageInfo: any;
  totalItems: number = 0;
  //  Search: string = '';
  pagesize: number = 10;


  constructor(
    private toastr: ToastrService,
    private router: Router,
    private service: SharedService<any>,
    public global: GlobalService,
    private auth: AuthService,
    private excelService: ExcelServicesService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.ManageJobForm = this.formBuilder.group({
      PageSearch: [null, Validators.nullValidator],
    });
    this.ManageJobForm.controls["PageSearch"].setValue(this.global.GoToPageForManageJob);
    this.getManagejob();
  }

  getManagejob() {
    this.service.Get('JobPost/GetManageJobTableData?pageno=' + this.global.GoToPageForManageJob + '&pagesize=' + this.pagesize + '&search=' + this.global.ManageJobSearch, null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data[0]);
      this.dataSource.paginator = this.paginator;
      this.datapageInfo = data[1];
      this.totalItems = this.datapageInfo[0].length;
      if (this.totalItems > 0)
      {
        this.totalpages = Math.ceil(this.totalItems / 10);
      }
      else
      {
        this.totalpages = 0;
      }
    },(error) => {
        this.toastr.error(error.error);
      });
  }

  GetId(row: Number) {
    this.jobData = row;
  }

  Edit(row: Number) {
    this.jobData = row;
    this.global.setjobTypeId(this.jobData.Id);
    this.router.navigate(['edit-job-post']);
  }

  JobClick(jobpost: any) {
    this.auth.setJobPostId(jobpost.Id);
    this.global.setJobroleId(jobpost.JobRoleId);
    this.router.navigate(['job-details']);
  }

  applyFilter(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.global.ManageJobSearch = ''
      this.global.GoToPageForManageJob = 1
      this.ManageJobForm.controls["PageSearch"].setValue(this.global.GoToPageForManageJob);
      this.getManagejob();
    }
    else {

      this.global.ManageJobSearch = (event.target as HTMLInputElement).value;
      this.search();
    }
  }


  GoToPage(event: Event) {

    var value = (event.target as HTMLInputElement).value;
    if (+value > this.totalpages || +value < 1) {
      this.toastr.success('Page no Should be between : 1 And ' + this.totalpages);
    }
    else 
    {
      this.global.GoToPageForManageJob = +value;
    }
  }


  GOTO() {
    this.service.Get('JobPost/GetManageJobTableData?pageno=' + this.global.GoToPageForManageJob + '&pagesize=' + this.pagesize + '&search=' + this.global.ManageJobSearch, null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data[0]);
      this.dataSource.paginator = this.paginator;
    },
      (error) => {
        this.toastr.error(error.error);
      });
  }


  Previous() {
    if (this.global.GoToPageForManageJob > 1) {
      this.global.GoToPageForManageJob = this.global.GoToPageForManageJob - 1
      this.ManageJobForm.controls["PageSearch"].setValue(this.global.GoToPageForManageJob);
      this.getManagejob();
    } else {

    }

  }

  Next() {
    if (this.totalpages > this.global.GoToPageForManageJob) {
      this.global.GoToPageForManageJob = this.global.GoToPageForManageJob + 1
      this.ManageJobForm.controls["PageSearch"].setValue(this.global.GoToPageForManageJob);
      this.getManagejob();
    }
  }

  search() {
    this.global.GoToPageForManageJob = 1;
    this.ManageJobForm.controls["PageSearch"].setValue(this.global.GoToPageForManageJob);
    this.service.Get('JobPost/GetManageJobTableData?pageno=' + this.global.GoToPageForManageJob + '&pagesize=' + this.pagesize + '&search=' + this.global.ManageJobSearch, null).subscribe(data => {

      this.dataSource = new MatTableDataSource(data[0]);
      this.datapageInfo = data[1];
      this.totalItems = this.datapageInfo[0].length;
      if (this.totalItems > 0) {
        this.totalpages = Math.ceil(this.totalItems / 10);
      }
      else {
        this.totalpages = 0;
      }
    },
      (error) => {
        this.toastr.error(error.error);
      });
  }

  clearsearch() {
    this.global.ManageJobSearch = ''
    this.global.GoToPageForManageJob = 1
    this.ManageJobForm.controls["PageSearch"].setValue(this.global.GoToPageForManageJob);
    this.getManagejob();
  }

  exportAsXLSX(): void {
    const GET: any = 0;
    let objJPColln: UplodJob[] = [];
    let objJP: UplodJob = new UplodJob();
    objJP.JobCompanyName = 'Keshav';
    objJP.JobRole = 'SoftWareDevloper';
    objJP.JobDescription = '';
    objJP.CompanyAddress = '';
    objJP.JobLocationTypeName = '';
    objJP.JobLocationCity = '';
    objJP.JobOpening = 2;
    objJP.NoticePerioddays = '';
    objJP.JobTypeName = '';
    objJP.SubJobTypeName = '';
    objJP.JobShiftName = '';
    objJP.WorkingDay = '';
    objJP.JobTiming = '';
    objJP.DutyTypeName = '';
    objJP.IsInternShip = GET;
    objJP.SalarytypeName = '';
    objJP.ExperienceTypeName = '';
    objJP.MinSalary = '';
    objJP.MaxSalary = '';
    objJP.Qualification = '';
    objJP.AcademicDescription = '';
    objJP.SpecializationDescription = '';
    objJP.GenderDesc = '';
    objJP.RecruiterName = '';
    objJP.CurrencyCode = '';
    objJPColln.push(objJP);
    this.excelService.exportAsExcelFile(objJPColln, 'JobPost');
  }

  onFileChange(event: any): void {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = XLSX.utils.sheet_to_json(ws);

      let objJPColln: UplodJob[] = [];
      for (let item of this.data) {

        let objJP: UplodJob = new UplodJob();
        objJP.JobCompanyName = item.Company;
        objJP.JobRole = item.JobRole;
        objJP.JobDescription = item.JobDesciption;
        objJP.CompanyAddress = item.CompanyAddress;
        objJP.JobLocationTypeName = item.JobLocationType;
        objJP.JobLocationCity = item.City;
        objJP.JobOpening = item.NoOfOpening;
        objJP.NoticePerioddays = item.JoiningPeriod;
        objJP.JobTypeName = item.TypeOfJob;
        objJP.SubJobTypeName = item.SubTypeOfJob;
        objJP.JobShiftName = item.ShiftType;
        objJP.WorkingDay = item.WorkingDays;
        objJP.JobTiming = item.WorkingHours;
        objJP.DutyTypeName = item.DutyType;
        objJP.IsInternShip = item.GET;
        objJP.SalarytypeName = item.SalaryType;
        objJP.ExperienceTypeName = item.ExperienceType;
        objJP.MinSalary = item.MinimumSalary;
        objJP.MaxSalary = item.MaximumSalary;
        objJP.Qualification = item.MinimumQualification;
        objJP.CurrencyCode = item.Currency;
        objJP.AcademicDescription = item.AcademicDiscipline;
        objJP.SpecializationDescription = item.Specialization;
        objJP.GenderDesc = item.Gender;
        objJP.RecruiterName = item.Recruiter;
        objJPColln.push(objJP);
      }
      this.service.Post('JobPost/ImportJobPostoDetails', objJPColln).subscribe(
        (res) => {
          this.myInputVariable.nativeElement.value = "";
          this.getManagejob();
          this.toastr.success(res);
          // for (let user of res) {
          //   this.service.Post('ResetPassword?loginName=' + user.Email, null).subscribe(
          //     (res) => {
          //     },
          //   )
          // }
        },

      )
    }
  }

  ngOnDestroy(): void {

  }

}
