import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { AcademicDiscipline, Country, Qualification, Qualificationa, Specialization, UserType } from 'src/app/interface';
import { JobRole } from 'src/app/interface';
import { GlobalService } from 'src/app/global.service';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-createcandidate',
  templateUrl: './createcandidate.component.html',
  styleUrls: ['./createcandidate.component.scss']
})
export class CreateCandidateComponent implements OnInit, OnDestroy {

  submitted: boolean = false;
  candidateform: FormGroup;
  displayMe: boolean = false;
  // allQualification?: Observable<Qualificationa[]>;
  // alldiscipline: Observable<AcademicDiscipline[]>;
  // allspecialization: Observable<Specialization[]>;
  //allJobRole: Observable<JobRole[]>;
  selectCollegeID: number;
  fileType: string = '';
  base64textString: string[] = [];
  allCountries: Country[];

  allQualification?: Qualification[];
  filterQuli?: Observable<Qualification[]>;
  formControlQuli = new FormControl();
  QulificationId: number;
  disciplineId: number;
  specializationId: number;
  alldiscipline: AcademicDiscipline[];
  filterdiscipline?: Observable<AcademicDiscipline[]>;
  formControldiscipline = new FormControl();

  allspecialization: Specialization[];
  filterspecialization?: Observable<Specialization[]>;
  formControlspecialization = new FormControl();


  allYear: any[];
  filterYear?: Observable<any[]>;
  formControlYear = new FormControl();
  Year: string
  allJobRole: JobRole[];
  filterJobRole?: Observable<JobRole[]>;
  formControlObj = new FormControl();
  JobRoleId: number;
  url: any;
  viewimg: boolean = false;


  constructor(private formBuilder: FormBuilder,

    private toastr: ToastrService,
    private router: Router,
    private service: SharedService<any>,
    public global: GlobalService
  ) {

  }

  ngOnInit(): void {
    this.candidateform = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      ProfileName: ['', null],
      Email: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      Password: ['', null],
      MobileNo: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      Photo: ['', null],
      IsGET: ['false', Validators.required],
      InternShipStartDate: ['', Validators.nullValidator],
      InternShipEndDate: ['', Validators.nullValidator],
      UserTypeId: [4],
      QualificationId: ['', Validators.required],
      AcademicDisciplineId: ['', Validators.required],
      SpecializationId: ['', Validators.required],
      JobRoleId: ['', Validators.nullValidator],
      PassOutYear: ['', Validators.required],
      CollegeId: ['', Validators.nullValidator],
      CountryISDCode: ['', Validators.required],
    }


      // {
      //     validator: this.confirmPasswordValidator("password", "confirmPassword")
    );
    this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allQualification = data;
        this.formControlQuli.setValue(this.allQualification[2]);
        this.filterQuli = this.formControlQuli.valueChanges.pipe(
          startWith<string | Qualification>(''),
          map(value => typeof value === 'string' ? value : value.Qualification),
          map(Qualification => Qualification ? this._filterQuali(Qualification) : this.allQualification.slice()),
        );
        this.onchangeQualification(this.allQualification[2].Id)
      }
    })


    this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.QulificationId, null).subscribe(data => {
      if (data != null) {
        this.alldiscipline = data;
        this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
          startWith<string | AcademicDiscipline>(''),
          map(value => typeof value === 'string' ? value : value.AcademicDescription),
          map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
        );
      }
    })


    this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.disciplineId, null).subscribe(data => {
      if (data != null) {
        this.allspecialization = data;
        this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
          startWith<string | Specialization>(''),
          map(value => typeof value === 'string' ? value : value.SpecializationDescription),
          map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
        );
      }
    })

    this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        this.filterJobRole = this.formControlObj.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
        );
      }
    })

    this.service.Get('Country/GetCountryISDCode?Id=0', null).subscribe({
      next: (res) => {
        this.allCountries = res;
        this.candidateform.controls["CountryISDCode"].setValue(this.allCountries[0].ISDCode);
      }
    });


    this.service.Get('CandidateInfo/GetYear', null).subscribe(data => {
      if (data != null) {
        this.allYear = data;
        this.formControlYear.setValue(this.allYear[0]);
        this.filterYear = this.formControlYear.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value),
          map(value => value ? this._filterYear(value) : this.allYear.slice()),
        );
        this.onchangeyear(this.allYear[0])
      }
    })
    // this.allQualification = this.service.Get('Qualification/GetQualification?Id=0', null);
    // this.allJobRole = this.service.Get('JobRole/GetJobRole?Id=0', null);
    // this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineData?Id=0', null);
    // this.allspecialization = this.service.Get('Specialization/GetSpecialization?Id=0', null);
    // this.allCountries = this.service.Get('Country/GetCountryISDCode?Id=0',null);

  }



  get f(): { [key: string]: AbstractControl } {
    return this.candidateform.controls;
  }

  private _filterRole(JobRole: string): JobRole[] {
    const filterValue = JobRole.toLowerCase();
    return this.allJobRole.filter(
      option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForRole(value: any) {
    return value ? value.JobRole : undefined;
  }

  private _filterQuali(Qualification: string): Qualification[] {
    const filterValue = Qualification.toLowerCase();
    return this.allQualification.filter(
      option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForQuli(value: any) {
    return value ? value.Qualification : undefined;
  }



  private _filterYear(any: string): any[] {
    const filterValue = any.toLowerCase();
    return this.allYear.filter(
      option => option?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForYear(value: any) {
    return value ? value : undefined;
  }

  private _filterdiscipline(AcademicDescription: string): AcademicDiscipline[] {
    const filterValue = AcademicDescription.toLowerCase();
    return this.alldiscipline.filter(
      option => option.AcademicDescription?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnFordiscipline(value: any) {
    return value ? value.
      AcademicDescription : undefined;
  }

  private _filterSpecialization(SpecializationDescription: string): Specialization[] {
    const filterValue = SpecializationDescription.toLowerCase();
    return this.allspecialization.filter(
      option => option.SpecializationDescription?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForSpecialization(value: any) {
    return value ? value.
      SpecializationDescription : undefined;
  }

  onchangejobrole(event) {
    this.JobRoleId = event
  }
  CandidateForm() {

    this.submitted = true;
    this.candidateform.controls["QualificationId"].setValue(this.QulificationId);
    this.candidateform.controls["AcademicDisciplineId"].setValue(this.disciplineId);
    this.candidateform.controls["SpecializationId"].setValue(this.specializationId);
    this.candidateform.controls["JobRoleId"].setValue(this.JobRoleId);
    this.candidateform.controls["PassOutYear"].setValue(this.Year);
    if (this.candidateform.invalid) {
      this.toastr.error("Please fill out the required fields (* fields)");
    }
    else {
      // if(this.global.UserType == UserType.College){
      //     this.candidateform.controls["CollegeId"].setValue(this.global.GetCollegeId())
      // }
      const emp = this.candidateform.getRawValue();
      emp.Photo = this.base64textString[0];
      var UserName = this.candidateform.value.Email;
      emp.ProfileName = this.candidateform.value.FirstName + ' ' + this.candidateform.value.LastName;

      this.service.Post('CandidateInfo/InsertCandidtaeInfo', emp).subscribe(
        (res) => {

          this.toastr.success(res);
          this.candidateform.reset();
          // this.service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
          //     (res) => {
          //         this.toastr.success(res);
          //     },
          // )
          // if(this.global.UserType = UserType.College){
          //     this.router.navigate(['/collegetable']);
          // }
          this.router.navigate(['/candidatetable']);
        },
        (error) => {

          this.toastr.error(error.error);
        });
      this.submitted = false;
      // }
    }
  }

  onchangeQualification(Qualification: any) {
    this.QulificationId = Qualification
    this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.QulificationId, null).subscribe(data => {
      if (data != null) {
        this.alldiscipline = data;
        this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
          startWith<string | AcademicDiscipline>(''),
          map(value => typeof value === 'string' ? value : value.AcademicDescription),
          map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
        );
      }
    })
  }

  onchangeAcademicDiscipline(event) {
    this.disciplineId = event
    this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.disciplineId, null).subscribe(data => {
      if (data != null) {
        this.allspecialization = data;
        this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
          startWith<string | Specialization>(''),
          map(value => typeof value === 'string' ? value : value.SpecializationDescription),
          map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
        );
      }
    })
  }

  onchangeSpecialization(event) {
    this.specializationId = event

  }
  onchangeyear(event) {
    this.Year = event
  }


  toggleTag() {
    this.displayMe = true;
  }
  toggleTag1() {
    this.displayMe = false;
  }

  Uploadcompanylogo(event: any) {
    this.fileType = event.target.files[0].type
    var files = event.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoadedCompany.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoadedCompany(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    this.viewimg = true
    this.url = this.base64textString[0];
  }

  keyPressAlphaNumeric(event: { keyCode: number; preventDefault: () => void; }) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9 ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlpha(event: { keyCode: number; preventDefault: () => void; }) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }


  ngOnDestroy(): void {

  }
}
