<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Recruiters
                    </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            
                        </li>
                      
                        <li>Recruiters
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
              <ul class="job-list-item align-items-center">
                <li>
                    <form>
                        <div class="form-group">
                        </div>
                    </form>
                </li>
            </ul>
                <input  class="form-control search" (keyup)="applyFilter($event)"
                placeholder="Enter Text To Search" >
            
                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                
                      <ng-container matColumnDef="ProfileName">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                            Recruiter Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.ProfileName}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="UserName">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                        Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.UserName}} </td>
                      </ng-container>
              
                      <ng-container matColumnDef="LastLogin">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                          Login Time</th>
                        <td mat-cell *matCellDef="let element"> {{element.Lastlogin | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator  color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15,25]"
                    aria-label="Select page">
                   </mat-paginator>
                  </div>

            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->