import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

const endpoint = 'https://jsonplaceholder.typicode.com/posts';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
    public myGlobal: GlobalService,) { }

  ApiUrl: string = '';
  ReportURL: string = '';

  userSignup(data: any) {
    return this.http.post(this.ApiUrl + 'userRegistrationsApi', data)
  }
  userlogin(data: any) {
    return this.http.post(this.ApiUrl + 'Login', data);
  }
  getuser() {
    return this.http.get(this.ApiUrl + 'userRegistrationsApi')
  }
  gettoken() {
    return !!this.myGlobal.getuserId();
  }

  jobPostId: number;
  EmpId :number;
  qualificationId : number
  acdamicdesplineId : number
  public getJobPostId(): number {
    return this.jobPostId;
  }


  setJobPostId(id: number) {
    this.jobPostId = id;
  }

  public getEmptId(): number {
    return this.EmpId;
  }

  cJob: boolean = false;

  setIsCurrentJob(_cJob: any) {
    this.cJob = _cJob;
  }

  public getIsCurrentJob(): boolean {
    return this.cJob;
  }


  setEmptId(userid: number) {

    this.EmpId = userid;
  }

  setqualificationId(id: number) {
    this.qualificationId = id;
  }

  getqualificationId(): number {
    return this.qualificationId;
  }

  
  setacdamicdesplineId(id: number) {
    this.acdamicdesplineId = id;
  }

  getacdamicdesplineId(): number {
    return this.acdamicdesplineId;
  }


  getData() {
    return this.http.get('https://jsonplaceholder.typicode.com/todos');
   }
}
