<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                        </li>
                        <li>Recruiters Points
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul class="job-list-item align-items-center">
                   
                    <li>
                        <form>
                            <div class="form-group">
                               
                            </div>
                        </form>
                    </li>
                </ul>
                <input  class="form-control search" (keyup)="applyFilter($event)" 
                placeholder="Enter Text To Search" >
            
                <div class="col-sm-4 col-lg-2" style="position: absolute;top: 166px; left: 22px;">
                    <div class="form-group">
                      <select class="form-control" (change)="onchangerecruitername($event.target.value)">
                        <option value="" disabled selected>Choose any one Recruiter</option>
                        <option *ngFor="let rec of (allRecruiter | async)" [value]="rec.Id">
                          {{rec.FirstName}} {{rec.LastName}}
                        </option>
                      </select>
                    </div>
                  </div>
                
                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                
                      <ng-container matColumnDef="JobCompanyName">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                            Company Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.JobCompanyName}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="RecruiterName">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                            Recruiter Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.RecruiterName}} </td>
                      </ng-container>
              
                  
                      <ng-container matColumnDef="Points">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                            Points</th>
                        <td mat-cell *matCellDef="let element"> {{element.Points}} </td>
                      </ng-container>
      
                      <ng-container matColumnDef="CandidateName">
                        <th mat-header-cell *matHeaderCellDef class="M1">
                            Candidate Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.CandidateName}} </td>
                      </ng-container>
      
                    
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    </table>
                    <mat-paginator  color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15,25]"
                    aria-label="Select page" >
                </mat-paginator>
                  </div>

            </div>

        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->